import * as types from '../actions/types';

const defaultState = {
  data: {},
};

const userProfileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_USER_PROFILE:
    case types.SET_USER_PASSWORD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
