import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 64,
  },
  selectRoot: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#06407C',
    },
    '& .MuiOutlinedInput-input': {
      color: '#06407C',
    },
    '&:hover .MuiInputLabel-root': {
      color: '#06407C',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#06407C',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#06407C',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#06407C',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#06407C',
    },
    textTransform: 'capitalize',
  },
  disabledRoot: {
    '& .MuiOutlinedInput-input': {
      color: '#000',
      opacity: 0.3,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
      opacity: 0.2,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
      opacity: 0.2,
    },
  },
}));

export default function VppSelect({
  label,
  direction,
  alignItems,
  fullWidth,
  disabled,
  name,
  options,
  control,
  size = 'small',
  onChange,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box display="flex" flexDirection={direction} alignItems={alignItems}>
      <Typography variant="subtitle2" color="primary">
        {label}
      </Typography>
      <FormControl className={classes.formControl}>
        <Controller
          render={(props) => (
            <TextField
              {...props}
              margin="dense"
              className={clsx(classes.selectRoot, {
                [classes.disabledRoot]: disabled,
              })}
              fullWidth={fullWidth}
              disabled={disabled}
              size={size}
              onChange={(e) => {
                props.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              select
            >
              {options.map((item, index) => (
                <MenuItem
                  value={item.value}
                  key={index}
                  style={{ textTransform: 'capitalize' }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          control={control}
          defaultValue={options[0].value}
          name={name}
        />
      </FormControl>
    </Box>
  );
}
