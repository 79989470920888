import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustomizedStepper from '../Stepper/Stepper';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getUserRegistrationContactInfo,
  sendFinishUserInfo,
  sendUserInfo,
} from '../../actions/user.registration.action';

const useStyles = makeStyles((theme) => ({
  loginCardMainContainer: {
    width: 560,
  },
  mainPageTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  pdfConent: {
    padding: '20px 15px',
    maxHeight: 340,
    backgroundColor: '#FBFBFB',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DFE0EB',
    overflow: 'auto',
  },
  signatureField: {
    '& input': {
      height: 95,
      backgroundColor: '#FBFBFB',
      textAlign: 'center',
      fontSize: 20,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
    },
  },
  btnWidth: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  input: {
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#fff',
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
      '&:-internal-autofill-selected': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
    },
  },
}));

const CardUserRegistration = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [canRegister, setCanRegister] = useState(true);
  const { register, setValue, getValues, reset } = useForm();
  const defaultData = useSelector((store) => store.user);
  const [activeStep, setActiveStep] = useState(0);
  const [fieldErrors, setFieldErrors] = useState({});
  const [readOnly, setReadOnly] = useState({
    readOnlyEmail: true,
    readOnlyPhoneNumber: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const activeSteps = activeStep;
  const stepperBody = getSteps();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { key, propertyName } = params;
  const history = useHistory();

  const handleBeforeunload = useCallback(() => {
    if (activeStep > 1) {
      localStorage.errorKey = key;
    }
    if (localStorage.errorKey !== key) {
      localStorage.removeItem('errorKey');
    }
  }, [activeStep]);

  useEffect(() => {
    window.addEventListener('pagehide', () => {
      handleBeforeunload();
    });
    return window.removeEventListener('pagehide', () => {
      handleBeforeunload();
    });
  }, [handleBeforeunload]);

  useEffect(() => {
    dispatch(getUserRegistrationContactInfo(key)).catch(({ response }) => {
      setCanRegister(false);
    });
  }, [dispatch, setCanRegister, getUserRegistrationContactInfo]);

  useEffect(() => {
    Object.keys(defaultData).forEach((item) => {
      setValue(item, defaultData[item]);
    });
  }, [defaultData]);



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickChangeEmail = () => {
    setReadOnly({ ...readOnly, readOnlyEmail: !readOnly.readOnlyEmail });
  };
  const handleClickChangePhoneNumber = () => {
    setReadOnly({
      ...readOnly,
      readOnlyPhoneNumber: !readOnly.readOnlyPhoneNumber,
    });
  };

  const handleRedirectUserLoginPage = () => {
    history.replace(`/${propertyName}/manager/login`);
  };

  const onSubmit = () => {
    switch (activeStep) {
      case 0:
        const { email, phone_number: phoneNumber } = getValues();
        dispatch(sendUserInfo(key, email, phoneNumber))
          .then(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            reset({});
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
          });
        break;
      case 1:
        const { password, password2 } = getValues();
        dispatch(sendFinishUserInfo(key, password, password2))
          .then(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
          });
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">
                Welcome{' '}
                <strong>
                  {defaultData.first_name} {defaultData.last_name}
                </strong>
                ,
                <br />
                Please confirm your contact information below
              </Typography>
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">First Name</Typography>
              <TextField
                autoFocus
                margin="dense"
                type="text"
                name="first_name"
                variant="outlined"
                inputRef={register}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Last Name</Typography>
              <TextField
                margin="dense"
                type="text"
                name="last_name"
                variant="outlined"
                inputRef={register}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Email</Typography>
              <TextField
                margin="dense"
                type="email"
                name="email"
                variant="outlined"
                inputRef={register}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email ? fieldErrors.email : null}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: readOnly.readOnlyEmail,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickChangeEmail}>
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Phone number</Typography>
              <TextField
                margin="dense"
                type="text"
                name="phone_number"
                variant="outlined"
                inputRef={register}
                error={!!fieldErrors.phone_number}
                helperText={
                  fieldErrors.phone_number ? fieldErrors.phone_number : null
                }
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: readOnly.readOnlyPhoneNumber,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickChangePhoneNumber}>
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">
                Please set your password for future access
              </Typography>
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Password</Typography>
              <TextField
                autoFocus
                margin="dense"
                type={showPassword ? 'text' : 'password'}
                name="password"
                inputRef={register}
                variant="outlined"
                error={!!fieldErrors.password}
                helperText={
                  fieldErrors.password
                    ? fieldErrors.password
                    : 'The password must be at least 8 symbols long, contain at least 1 number, 1 uppercase letter and 1 lowercase letter.'
                }
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Confirm Password</Typography>
              <TextField
                margin="dense"
                type={showPassword ? 'text' : 'password'}
                name="password2"
                inputRef={register}
                error={!!fieldErrors.password2}
                helperText={
                  fieldErrors.password2 ? fieldErrors.password2 : null
                }
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
            </Box>
          </Box>
        );
      default:
        return (
          <Box pt={5} pb={5}>
            <Box textAlign="center">
              <CheckIcon color="secondary" fontSize="large" />
            </Box>
            <Box mb={8}>
              <Typography color="primary" variant="h6" align="center">
                Your account was created!
              </Typography>
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleRedirectUserLoginPage()}
              >
                OK
              </Button>
            </Box>
          </Box>
        );
    }
  }
  function getSteps() {
    return ['Select campaign settings', 'Create an ad group'];
  }

  return (
    <form>
      <Box mt={6}></Box>
      <Grid container justify="center" alignContent="center" direction="column">
        <Grid item xs={12} className={classes.loginCardMainContainer}>
          <Card variant="outlined">
            <CardContent>
              {!canRegister ? (
                <Box mt={5}>
                  <Grid
                    direction="column"
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <img src="/logo.svg" className={classes.logo} />
                      </Box>
                      <Box my={3} textAlign="center">
                        <Typography variant="h5" color="primary">
                          Registration key invalid
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : localStorage.errorKey === key ? (
                <Box mt={5}>
                  <Grid
                    direction="column"
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <img src="/logo.svg" className={classes.logo} />
                      </Box>
                      <Box my={3} textAlign="center">
                        <Typography variant="h5" color="primary">
                          This user was registered already
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  {activeStep === stepperBody.length ? null : (
                    <>
                      <Box mt={3}>
                        <Typography color="primary" variant="h6" align="center">
                          Registration
                        </Typography>
                      </Box>
                      <CustomizedStepper
                        stepperBody={stepperBody}
                        activeSteps={activeSteps}
                      />
                    </>
                  )}
                  <Box>
                    <Box pr={3} pl={3} className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Box>
                    {activeStep === stepperBody.length ? null : (
                      <Box display="flex" justifyContent="center" pl={3} pr={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onSubmit}
                          size="large"
                          className={classes.btnWidth}
                        >
                          Next
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default CardUserRegistration;
