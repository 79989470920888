import * as types from '../actions/types';

const defaultState = {
  data: [],
};

const guestPermitsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_GUEST_PERMITS:
      return {
        ...state,
        data: [...action.payload.data],
        paging: {...action.payload.paging}
      };
    default:
      return state;
  }
};

export default guestPermitsReducer;
