import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0F2854',
    },
  },
  input: {
    color: '#06407C',
    background: '#F7F8FC',
    paddingTop: '6.5px!important',
    textAlign: 'center',
    paddingBottom: '6.5px!important',
    '&.Mui-disabled': {
      background: '#fff',
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '&::-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&::-moz-inner-spin-button': {
      opacity: 1,
    },
  },
}));

const NumberInput = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box width={props.width ?? 82}>
      <TextField
        type="number"
        fullWidth
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
          },
          inputProps: { min: 0, pattern: '[0-9]', step: '1' },
        }}
        {...props}
      />
    </Box>
  );
};

export default NumberInput;
