import React from 'react';
import {
  Table,
  TableContainer,
  Paper,
  Typography,
  Box,
  Tooltip,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import { useDataTable } from '../../contexts/dataTable';
import DataTableHeader from './DataTableHeader';
import DataTableBody from './DataTableBody';
import FilterListIcon from '@material-ui/icons/FilterList';

const DataTableRenderer = ({ paginable = false, handlePaging, paging, sortingChanged, sort, sortableColumns, showFilters }) => {
  const { getTableProps, title } = useDataTable();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  if (paging && (paging.page !== page || paging.page_size !== rowsPerPage)) {
    setPage(paging.page);
    setRowsPerPage(paging.page_size);
  }

  const handleChangePage = (event, newPage) => {
    handlePaging({
      page: newPage + 1,
      page_size: rowsPerPage,
    });
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    handlePaging({
      page: 1,
      page_size: event.target.value,
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  let paginator;

  if (paginable) {
    paginator = (
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        component="div"
        count={paging.total_records}
        rowsPerPage={+rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    );
  }

  return (
    <Paper>
      {title && (
        <Box p={2} display="flex" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          {showFilters && (
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>)}
        </Box>
      )}
      <TableContainer style={{ overflowX: 'initial'}}>
        <Table stickyHeader {...getTableProps()}>
          <DataTableHeader sortingChanged={sortingChanged} sort={sort} sortableColumns={sortableColumns} />
          <DataTableBody
            paginable={paginable}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>
      {paginator}
    </Paper>
  );
};

export default DataTableRenderer;
