import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { HomeIcon } from '../../Icons';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  homeIconHolder: {
    borderRadius: '50%',
    height: 32,
    width: 32,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
}));

const PropertyName = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const property = useSelector((store) => store.property);

  return property.name ? (
    <Box display="flex" alignItems="center" py={2.5} px={3}>
      <Box
        className={classes.homeIconHolder}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        <HomeIcon fontSize="small" />
      </Box>
      <Box ml={2} overflow="hidden">
        <Tooltip title={property.name} placement="bottom">
          <Typography variant="h6" noWrap>
            {property.name}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  ) : null;
};

export default PropertyName;
