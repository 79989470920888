export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';

export const SET_UNITS = 'SET_UNITS';
export const SET_UNIT = 'SET_UNIT';

export const SET_TOWS = 'SET_TOWS';
export const SET_STICKERED = 'SET_STICKERED';

export const SET_RULES = 'SET_RULES';

export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';
export const USER_REGISTRATION_INFO = 'USER_REGISTRATION_INFO';

export const SET_USERS = 'SET_USERS';
export const DELETE_USER = 'DELETE_USER';

export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const UNIT_STATUS_SUCCESS = 'UNIT_STATUS_SUCCESS';

export const SYSTEM_SET_LOADING = 'SET_LOADING';

export const TENANT_LOGIN = 'TENANT_LOGIN';
export const TENANT_SET_VEHICLES = 'TENANT_SET_VEHICLES';
export const TENANT_ADD_VEHICLE = 'TENANT_ADD_VEHICLE';
export const TENANT_UPDATE_VEHICLE = 'TENANT_UPDATE_VEHICLE';
export const REGISTRATION_CONTACT_INFO = 'REGISTRATION_CONTACT_INFO';
export const SET_PASSWORD = 'SET_PASSWORD';
export const PDF_RULES_INFO = 'PDF_RULES_INFO';

export const GUEST_SET_VEHICLES = 'GUEST_SET_VEHICLES';
export const GUEST_ADD_VEHICLE = 'GUEST_ADD_VEHICLE';
export const GUEST_UPDATE_VEHICLE = 'GUEST_UPDATE_VEHICLE';

export const SET_GUEST_PERMITS = 'SET_GUEST_PERMITS';

export const SET_ACTIVITIES = 'SET_ACTIVITIES';

export const SET_RESERVED_SPOTS = 'SET_RESERVED_SPOTS';
