import {get} from "lodash";
import {useCallback, useEffect, useRef} from "react";
import {useSelector} from "react-redux";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const timeout = process.env.REACT_APP_SESSION_TIMEOUT ?? 43200000;

export const useSessionTimeout = () => {
    const timeoutRef = useRef(null);
    const propertyName = useSelector((store) => get(store, 'property.sub_domain_name', ''));

    const handleLogoutTimer = useCallback(() => {
        if (propertyName) {
            timeoutRef.current = setTimeout(() => {
                resetTimer();

                events.forEach((item) => {
                    window.removeEventListener(item, resetTimer);
                });

                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');

                window.location.href = propertyName ? `/${propertyName}/manager/login` : '';
            }, timeout);
        }
    }, [propertyName])

    const resetTimer = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        handleLogoutTimer();
        events.forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
        return () => events.forEach((item) => {
            window.removeEventListener(item, resetTimer);
        });
    }, [handleLogoutTimer, resetTimer]);
}