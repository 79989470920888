import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Collapse,
  InputAdornment,
  useTheme,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import { useAuth } from '../../contexts/auth';
import { useRouteMatch } from 'react-router';
import CardBase from './CardBase';

const useStyles = makeStyles((theme) => ({
  input: {
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#fff',
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
      '&:-internal-autofill-selected': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
    },
  },
  div: {},
}));

export default function CardLogin() {
  const { logIn } = useAuth();
  const { register, handleSubmit} = useForm();
  const {
    params: { propertyName: property_sub_domain, loginType: portal },
  } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [hasLoginErrors, setHasLoginErrors] = useState(false);
  const [loginErrors, setLoginErrors] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = useCallback(
    async (data) => {
      let { email, password } = data;
      const phone = new RegExp(/^[\d\-\(\)\s]{2,}$/g)
      if (phone.test(email)) {
        email = email.replace(/[\-\(\)\s]/g, '');
      }
      setLoading(true);
      setHasLoginErrors(false);
      setLoginErrors('');
      setFieldErrors({});
      await logIn(email, password, portal, property_sub_domain, checked).catch(
        ({ response }) => {
          const { data } = response;
          if (data.detail) {
            setHasLoginErrors(true);
            setLoginErrors(data.detail);
          } else {
            setFieldErrors(data);
          }
          setLoading(false);
        }
      );
    },
    [logIn, checked]
  );
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <CardBase>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={3} mb={5}>
          <Typography color="primary" variant="h6" align="center">
            Log In
          </Typography>
        </Box>
        <Collapse in={hasLoginErrors}>
          <Alert severity="error">{loginErrors}</Alert>
        </Collapse>
        {portal === 'manager' ? (
          <Box mt={1} mb={3}>
          <Typography variant="body2">Email</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            type="text"
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            placeholder="Email"
            inputRef={register}
            error={!!fieldErrors.email}
            helperText={fieldErrors.email ? fieldErrors.email : null}
            fullWidth
          />
        </Box>
        ):(
        <Box mt={1} mb={3}>
          <Typography variant="body2">Email or Phone Number</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            type="text"
            variant="outlined"
            InputProps={{
              className: classes.input,
            }}
            placeholder="Email or Phone Number"
            inputRef={register}
            error={!!fieldErrors.email}
            helperText={fieldErrors.email ? fieldErrors.email : null}
            fullWidth
          />
        </Box>
        )}
        
        <Box mt={1} mb={3}>
          <Typography variant="body2">Password</Typography>
          <TextField
            margin="dense"
            id="password"
            name="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            inputRef={register}
            error={!!fieldErrors.password}
            helperText={fieldErrors.password ? fieldErrors.password : null}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputRef={register}
              name="remember_me"
              color="primary"
            />
          }
          label={
            <Typography color="primary" variant="body2">
              Remember Me
            </Typography>
          }
        />
        <Box
          mt={4}
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={loading}
          >
            Log In
          </Button>
          <Box mt={1}>
            <Typography variant="body2">
              <Link href={`/${property_sub_domain}/${portal}/forgotPassword`}>
                Forgot Password
              </Link>
            </Typography>
          </Box>
        </Box>
      </form>
    </CardBase>
  );
}
