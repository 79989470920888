import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContentContainer: {
    padding: theme.spacing(0, 7, 0, 7),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

export default function CardBase({ children }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Card variant="outlined">
      <CardContent>
        <Box className={classes.cardContentContainer}>
          <Box mt={3} mb={5}>
            {children}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
