import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import StatusChipUsers from '../StatusChip/StatusChipUsers';
import { useDispatch } from 'react-redux';
import { patchUserStatus } from '../../actions/user.actions';
import { Box, Menu } from '@material-ui/core';

const options = ['Activate', 'Block Access'];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  statusBtn: {
    position: 'relative',
    textTransform: 'capitalize',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 8,
    fontWeight: 400,
  },
  activeDropChip: {
    color: 'rgb(66, 133, 244)',
    fontSize: 12,
    '&:hover': {
      borderLeft: '3px solid transparent',
    },
  },
  blockedDropChip: {
    color: 'rgb(207, 24, 68)',
    fontSize: 12,
    '&:hover': {
      borderLeft: '3px solid transparent',
      backgroundColor: 'rgba(207, 24, 68, 0.05)',
      color: 'rgb(207, 24, 68)',
    },
  },
  dropdownBox: {
    marginTop: 18,
  },
}));

const StatusUserDropdown = ({ id, stautsChip }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const handleToggle = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChoose = (status) => {
    let statusDropdown = status === 'Activate' ? 'active' : 'blocked';
    dispatch(patchUserStatus(statusDropdown, id));
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return false;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && !open) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Box dispalay="flex">
      {stautsChip === 'pending' ? (
        <StatusChipUsers status={stautsChip} />
      ) : (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle('bottom-end')}
          className={classes.statusBtn}
        >
          <StatusChipUsers status={stautsChip} />
        </Button>
      )}
      <Menu
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal
        className={classes.dropdownBox}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
            <MenuItem
              className={classes.activeDropChip}
              onClick={() => handleChoose(options[0])}
            >
              {options[0]}
            </MenuItem>
            <MenuItem
              className={classes.blockedDropChip}
              onClick={() => handleChoose(options[1])}
            >
              {options[1]}
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Menu>
    </Box>
  );
};

export default StatusUserDropdown;
