import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setUnits = (payload) => ({ type: types.SET_UNITS, payload });
const setUnitStatus = (payload) => ({
  type: types.UNIT_STATUS_SUCCESS,
  payload,
});
const setUnit = (payload) => ({ type: types.SET_UNIT, payload });

export const getUnits =
  (search, cancelToken = null) =>
  (dispatch) =>
    vpp
      .get(VPP_API.unit, search ? { params: search, cancelToken } : null)
      .then(({ data }) => {
        dispatch(setUnits(data));
      });

export const getUnit = (id) => (dispatch) => vpp.get(VPP_API.unitId(id));

export const getAddendumPdf = (id) => (dispatch) =>
  vpp.get(VPP_API.downloadAddemdum(id));

export const updateUnit = (id, data) => (dispatch) =>
  vpp.patch(VPP_API.unitId(id), data);

export const setUnitArchive = (id) => (dispatch) =>
  vpp.patch(VPP_API.unitArchive(id), {
    archived: true,
  });

export const createUnit = (data) => (dispatch) => vpp.post(VPP_API.unit, data);

export const resendInvitationUnit = (data, id) => (dispatch) =>
  vpp.post(VPP_API.resendUnitinvitation(id), data);

export const patchUnitStatus = (status, id) => (dispatch) =>
  vpp
    .patch(VPP_API.unitStatus(id), { status })
    .then(({ data }) => {
      dispatch(setUnitStatus({ data, id }));
    })
    .catch((error) => console.log(error));
