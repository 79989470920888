import { withNavigationWatcher } from './contexts/navigation';
import UnitsPage from './pages/admin/units/Units';
import EditUnits from './pages/admin/units/edit/EditUnits';
import ManagerTows from './pages/admin/tows/ManagerTows';
import StickeredViolators from './pages/admin/stickeredViolators/StickeredViolators';
import UsersPage from './pages/admin/users/Users';
import PropertyProfile from './pages/admin/propertyProfile/PropertyProfile';
import UserProfile from './pages/admin/userProfile/UserProfile';
import ParkingRulesPage from './pages/admin/parkingRules/ParkingRules';
import GuestPermitsPage from './pages/admin/guestPermits/GuestPermits';
import ReservedSpotsPage from './pages/admin/reservedSpots/ReservedSpots';
import ReportsPage from "./pages/admin/reports/ReportsPage";

const routes = [
  {
    path: '/admin/units',
    component: UnitsPage,
  },
  {
    path: '/admin/guestPermits',
    component: GuestPermitsPage,
  },
  {
    path: '/admin/reservedSpots',
    component: ReservedSpotsPage
  },
  {
    path: '/admin/units/:unitId',
    component: EditUnits,
  },
  {
    path: '/admin/tows',
    component: ManagerTows,
  },
  {
    path: '/admin/stickered',
    component: StickeredViolators,
  },
  {
    path: '/admin/users',
    component: UsersPage,
  },
  {
    path: '/admin/propertyProfile',
    component: PropertyProfile,
  },
  {
    path: '/admin/parkingRules',
    component: ParkingRulesPage,
  },
  {
    path: '/admin/reports',
    component: ReportsPage,
  },
  {
    path: '/admin/userProfile',
    component: UserProfile,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
