import React, {
  useState,
  createContext,
  useContext,
  useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  refreshTokenFetch,
  userLoginFetch,
  userLogout,
} from '../actions/user.actions';

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props) {
  const [loading, setLoading] = useState(true);
  const [fromRegister, setFromRegister] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const logIn = useCallback(
      async (
          email,
          password,
          portal,
          property_sub_domain,
          remember_me,
          fromRegisterPage
      ) => {
        if (fromRegisterPage) {
          setFromRegister(true);
        }
        return dispatch(
            userLoginFetch({
              email: String(email).trim(),
              password,
              portal,
              property_sub_domain,
              remember_me,
            })
        );
      },
      [dispatch]
  );

  const logOut = useCallback(() => {
    dispatch(userLogout());
  }, [dispatch]);

  useEffect(() => {
    const refresh = localStorage.refresh_token;
    if (refresh) {
      dispatch(refreshTokenFetch({ refresh })).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return (
      <AuthContext.Provider
          value={{ user, logIn, logOut, loading, fromRegister }}
          {...props}
      />
  );
}

export { AuthProvider, useAuth };
