import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setActivities = (payload) => ({
  type: types.SET_ACTIVITIES,
  payload,
});

export const getActivities =
  (params, cancelToken = null) =>
  (dispatch) => {
    let config = {
      params,
      cancelToken,
    };
    return vpp.get(VPP_API.activities, config).then(({ data }) => {
      dispatch(setActivities(data));
    });
  };
