import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Box, Menu } from '@material-ui/core';
import StatusChipUnits from '../StatusChip/StatusChipUnits';
import { patchUnitStatus } from '../../actions/units.actions';

const options = ['active', 'blocked', 'suspended'];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  statusBtn: {
    position: 'relative',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 8,
    fontWeight: 400,
  },
  activeDropChip: {
    color: 'rgb(33, 190, 88)',
    fontSize: 12,
    '&:hover': {
      borderLeft: '3px solid transparent',
      backgroundColor: 'rgba(33, 190, 88, 0.05)',
      color: 'rgb(33, 190, 88)',
    },
  },
  blockedDropChip: {
    color: 'rgb(207, 24, 68)',
    fontSize: 12,
    '&:hover': {
      borderLeft: '3px solid transparent',
      backgroundColor: 'rgba(207, 24, 68, 0.05)',
      color: 'rgb(207, 24, 68)',
    },
  },
  suspendedDropChip: {
    color: 'rgb(98, 0, 238)',
    fontSize: 12,
    '&:hover': {
      borderLeft: '3px solid transparent',
      backgroundColor: 'rgba(98, 0, 238, 0.05)',
      color: 'rgb(98, 0, 238)',
    },
  },
  dropdownBox: {
    marginTop: 18,
  },
}));

const StatusUnitsDropdown = ({ id, statusChip }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const handleToggle = (newPlacement) => (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChoose = (e, status) => {
    e && e.stopPropagation();
    dispatch(patchUnitStatus(status, id));
    setOpen(false);
  };

  const handleClose = (e) => {
    e && e.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return false;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && !open) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  return (
    <Box dispalay="flex">
      {statusChip === 'pending' || statusChip === 'archived' ? (
        <StatusChipUnits status={statusChip} />
      ) : (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle('bottom-end')}
          className={classes.statusBtn}
        >
          <StatusChipUnits status={statusChip} />
        </Button>
      )}
      <Menu
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal
        className={classes.dropdownBox}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
            <MenuItem
              className={classes.activeDropChip}
              onClick={(e) => handleChoose(e, options[0])}
            >
              {options[0]}
            </MenuItem>
            <MenuItem
              className={classes.blockedDropChip}
              onClick={(e) => handleChoose(e, options[1])}
            >
              {options[1]}
            </MenuItem>
            <MenuItem
              className={classes.suspendedDropChip}
              onClick={(e) => handleChoose(e, options[2])}
            >
              {options[2]}
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Menu>
    </Box>
  );
};

export default StatusUnitsDropdown;
