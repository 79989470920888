import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { userForgotPassword } from '../../actions/user.actions';
import { useHistory, useRouteMatch } from 'react-router';
import CheckIcon from '@material-ui/icons/Check';
import CardBase from './CardBase';

export default function CardForgotPassword() {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const {
    params: { propertyName: property_sub_domain, loginType: portal_name },
  } = useRouteMatch();

  const handleLoginRedirect = () =>{
    history.replace(`/${property_sub_domain}/${portal_name}/login`)
  }

  const onSubmit = useCallback(
    async (data) => {
      let { user_login_field } = data;
      const phone = new RegExp(/^[\d\-\(\)\s]{5,}$/g)
      if (phone.test(user_login_field)) {
        user_login_field = user_login_field.replace(/[\-\(\)\s]/g, '');
      }
      setFieldErrors({});
      setLoading(true);
      dispatch(userForgotPassword(String(user_login_field).trim(), portal_name, property_sub_domain))
        .then(() => {
          setSuccess(true);
        })
        .catch(({ response: { data } }) => {
          setFieldErrors(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userForgotPassword, setLoading, setFieldErrors, dispatch]
  );

  return (
    <CardBase>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success ? (
          <Box pt={5}>
            <Box textAlign="center">
              <CheckIcon color="secondary" fontSize="large" />
            </Box>
            <Typography color="primary" variant="h6" align="center">
              Forgot Your Password?
            </Typography>
            {portal_name === 'manager' ? (
              <Box mt={1} mb={8}>
                <Typography variant="body2" align="center">
                  We just sent you an email. Please follow the link in the email
                  to continue
                </Typography>
              </Box>
            ) : (
              <Box mt={1} mb={8}>
                <Typography variant="body2" align="center">
                  We just sent you an email/sms. Please follow the link in the email/sms
                  to continue
                </Typography>
              </Box>
            )}
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={ ()=> handleLoginRedirect() }
              >
                OK
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography color="primary" variant="h6" align="center">
              Forgot Your Password?
            </Typography>
            {portal_name === 'manager' ? (
              <>
                <Box my={4}>
                  <Typography variant="body2" align="center">
                    We will send you an email with a password reset link
                  </Typography>
                </Box>
                <Box mb={8}>
                  <Typography variant="body2">Email</Typography>
                  <TextField
                    name="user_login_field"
                    placeholder="example@mail.com"
                    variant="outlined"
                    inputRef={register({
                      required: 'This field is required',})}
                    error={!!fieldErrors.user_login_field}
                    helperText={fieldErrors.user_login_field ? fieldErrors.user_login_field : null}
                    fullWidth
                  />
                </Box>
              </>
            ) : (
              <>
                <Box my={4}>
                  <Typography variant="body2" align="center">
                    We will send you an email/sms with a password reset link
                  </Typography>
                </Box>
                <Box mb={8}>
                  <Typography variant="body2">Email or Phone Number</Typography>
                  <TextField
                    name="user_login_field"
                    placeholder="example@mail.com/80*********"
                    variant="outlined"
                    inputRef={register({
                      required: 'This field is required',})}
                    error={!!fieldErrors.user_login_field}
                    helperText={fieldErrors.user_login_field ? fieldErrors.user_login_field : null}
                    fullWidth
                  />
                </Box>
              </>
            )}
            
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
              >
                Reset password
              </Button>
            </Box>
          </>
        )}
      </form>
    </CardBase>
  );
}
