import * as types from '../actions/types';

const defaultState = { loading: false };

const systemReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SYSTEM_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default systemReducer;
