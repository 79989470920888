import React, { useEffect, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter as Router,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import AdminLayout from './layouts/Admin';
import Loading from './layouts/Loading';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import Content from './Content';
import { lightTheme } from './Theme';
import { Box } from '@material-ui/core';
import TenantContent from './TenantContent';
import TenantLayout from './layouts/Tenant';
import { useDispatch } from 'react-redux';
import { userLogout } from './actions/user.actions';
import { sendSupport } from './actions/office.support.actions';

const App = () => {
  const { user, loading, fromRegister } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const tenantRegistrationMatch = useRouteMatch(
      '/:propertyName/register/tenant/:key'
  );
  const managerRegistrationMatch = useRouteMatch(
      '/:propertyName/register/manager/:key'
  );
  const adminLoginMatch = useRouteMatch('/:propertyName/office-support/:key');
  const propertyLogoutMatch = useRouteMatch('/:propertyName/logout');

  useEffect(() => {
    if (
        (tenantRegistrationMatch || managerRegistrationMatch) &&
        user.loggedIn &&
        !fromRegister
    ) {
      dispatch(userLogout());
    }
  }, [tenantRegistrationMatch, managerRegistrationMatch, dispatch, userLogout]);

  useEffect(() => {
    if (propertyLogoutMatch && user.loggedIn) {
      dispatch(userLogout());
      history.push(`/${propertyLogoutMatch.params.propertyName}`);
    } else if (propertyLogoutMatch && !user.loggedIn && !loading) {
      history.push(`/${propertyLogoutMatch.params.propertyName}`);
    }
  }, [propertyLogoutMatch, dispatch, userLogout, user, loading]);

  useEffect(() => {
    if (adminLoginMatch) {
      const {
        params: { key },
      } = adminLoginMatch;
      if (user.loggedIn) {
        dispatch(userLogout());
      }
      dispatch(sendSupport(key));
    }
  }, [dispatch, adminLoginMatch, userLogout, sendSupport]);

  if (loading) {
    return <Loading />;
  }

  if (!user.loggedIn) {
    return <NotAuthenticatedContent />;
  }

  return user.role === 'Tenant' ? (
      <TenantLayout>
        <TenantContent />
      </TenantLayout>
  ) : (
      <AdminLayout>
        <Content />
      </AdminLayout>
  );
};

export default ({ history }) => {
  const providerRef = useRef();
  return (
      <ThemeProvider theme={lightTheme}>
        <Router history={history}>
          <AuthProvider>
            <NavigationProvider>
              <SnackbarProvider
                  ref={providerRef}
                  maxSnack={4}
                  {...lightTheme.props.MuiSnackbar}
              >
                <>
                  <CssBaseline />
                  <App />
                  <Box>
                    <Loading />
                  </Box>
                </>
              </SnackbarProvider>
            </NavigationProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
  );
};