import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import routes from './tenantRoutes';

export default () => {
  return (
    <>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={'/tenant/home'} />
      </Switch>
    </>
  );
};
