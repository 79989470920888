import * as types from '../actions/types';

const defaultState = {
    data: [],
};

const activitiesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.SET_ACTIVITIES:
            return {
                ...state,
                data: [...action.payload.data],
                paging: {...action.payload.paging}
            };
        default:
            return state;
    }
};

export default activitiesReducer;