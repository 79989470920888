import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Collapse,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import {
  userChangePassword,
  userForgotPassword,
} from '../../actions/user.actions';
import { useHistory, useRouteMatch } from 'react-router';
import CheckIcon from '@material-ui/icons/Check';
import CardBase from './CardBase';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  input: {
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#fff',
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
      '&:-internal-autofill-selected': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
    },
  },
  div: {},
}));

export default function CardChangePassword() {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasResetErrors, setHasResetErrors] = useState(false);
  const [resetErrors, setResetErrors] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const {
    params: { propertyName: property_sub_domain, loginType: portal_name, key, },
  } = useRouteMatch();

  const handleLoginRedirect = () =>{
    history.replace(`/${property_sub_domain}/${portal_name}/login`)
  }

  const onSubmit = useCallback(
    async (data) => {
      const { password, password2 } = data;
      setFieldErrors({});
      setLoading(true);
      dispatch(userChangePassword(key, password, password2))
        .then(() => {
          setSuccess(true);
        })
        .catch(({ response: { data } }) => {
          if (data.detail) {
            setHasResetErrors(true);
            setResetErrors(data.detail);
          } else {
            setFieldErrors(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userForgotPassword, setLoading, setFieldErrors, dispatch]
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <CardBase>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success ? (
          <Box pt={5}>
            <Box textAlign="center">
              <CheckIcon color="secondary" fontSize="large" />
            </Box>
            <Typography color="primary" variant="h6" align="center">
              Change Password
            </Typography>
            <Box mt={1} mb={8}>
              <Typography variant="body2" align="center">
                Your password was change successfully
              </Typography>
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleLoginRedirect()}
              >
                OK
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography color="primary" variant="h6" align="center">
              Change Password
            </Typography>
            <Box mb={8} mt={6}>
              <Collapse in={hasResetErrors}>
                <Box mb={2}>
                  <Alert severity="error">{resetErrors}</Alert>
                </Box>
              </Collapse>
              <Box mb={2}>
                <Typography variant="body2">New password</Typography>
                <TextField
                  name="password"
                  placeholder="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  inputRef={register}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!fieldErrors.password}
                  helperText={
                    fieldErrors.password ? fieldErrors.password : null
                  }
                  fullWidth
                />
              </Box>
              <Typography variant="body2">Confirm password</Typography>
              <TextField
                name="password2"
                placeholder="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                inputRef={register}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!fieldErrors.password2}
                helperText={
                  fieldErrors.password2 ? fieldErrors.password2 : null
                }
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
              >
                Change password
              </Button>
            </Box>
          </>
        )}
      </form>
    </CardBase>
  );
}
