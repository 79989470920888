import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { updateProperty } from '../../../actions/properties.actions';

const useStyles = makeStyles({
  dropzone: {
    background: '#F9F9FB',
  },
  dropzoneIcon: {
    color: '#B3B8BF',
    fontSize: 54,
  },
  dropzoneLink: {
    textDecoration: 'underline',
  },
  dropzoneActive: {
    boxShadow: '0px 0px 3px 1px rgb(33, 190, 88)',
  },
  dropzoneReject: {
    boxShadow: '0px 0px 3px 1px rgb(207, 24, 68)',
  },
  dropzoneAccept: {
    boxShadow: '0px 0px 3px 1px rgb(66, 133, 244)',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative',
  },
  thumbCancel: {
    position: 'absolute',
    right: -15,
    top: -15,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  thumbImg: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
});

const PropertyProfile = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, setValue, unregister, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const data = useSelector((store) => store.property);
  const [disabled, setDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled,
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    onDropAccepted: useCallback(
      (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setValue('image', acceptedFiles[0], { shouldValidate: true });
      },
      [setValue, setFiles]
    ),
  });

  const removeFile = (file) => {
    acceptedFiles.splice(file, 1);
    setFiles(acceptedFiles);
    setValue('image', null);
  };

  const onSubmit = useCallback(
    (property) => {
      const propertyToSet = { ...property };
      const bodyFormData = new FormData();
      if (
        propertyToSet.image === undefined ||
        typeof propertyToSet.image === 'string'
      ) {
        delete propertyToSet.image;
      }
      setFieldErrors({});
      setSubmitDisabled(true);

      Object.keys(propertyToSet).forEach((item) => {
        bodyFormData.append(item, propertyToSet[item]);
      });

      dispatch(updateProperty(data.id, bodyFormData))
        .then(() => {
          enqueueSnackbar('Property profile saved successfully', {
            variant: 'success',
          });
          setDisabled(true);
        })
        .catch(({ response }) => {
          const { data } = response;
          setFieldErrors(data);
          enqueueSnackbar(
            'Error saving property profile. Please make sure all fields are filled in correctly.',
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    setFieldErrors({});
    fileRejections.length = 0;
    Object.keys(data).forEach((item) => {
      setValue(item, data[item]);
    });
    if (data.image) {
      setFiles([{ preview: data.image }]);
    } else {
      setFiles([]);
    }
  }, [data, setFiles, setValue, setFieldErrors, disabled]);

  useEffect(() => {
    register('image');
    return () => {
      unregister('image');
    };
  }, [register, unregister]);

  return (
    <Box mr={4} pb={4}>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          disabled={!disabled}
          onClick={() => {
            setDisabled(false);
          }}
        >
          Edit
        </Button>
      </Box>
      <Paper elevation={0} variant="outlined">
        <Box py={4} pl={10}>
          <Box mb={4}>
            <Typography variant="h6">Property Profile</Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body2">Property Name</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="name"
                      type="text"
                      variant="outlined"
                      placeholder="Property"
                      disabled={disabled}
                      inputRef={register}
                      error={!!fieldErrors.name}
                      helperText={fieldErrors.name ? fieldErrors.name : null}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">Property Address</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="address"
                      type="text"
                      variant="outlined"
                      placeholder="Address"
                      disabled={disabled}
                      inputRef={register}
                      error={!!fieldErrors.address}
                      helperText={
                        fieldErrors.address ? fieldErrors.address : null
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">Property Website</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="website"
                      type="text"
                      variant="outlined"
                      placeholder="Website"
                      disabled={disabled}
                      inputRef={register}
                      error={!!fieldErrors.website}
                      helperText={
                        fieldErrors.website ? fieldErrors.website : null
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">
                      Property Phone Number
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      name="phone_number"
                      type="text"
                      variant="outlined"
                      placeholder="01 234 567 89"
                      disabled={disabled}
                      inputRef={register}
                      error={!!fieldErrors.phone_number}
                      helperText={
                        fieldErrors.phone_number
                          ? fieldErrors.phone_number
                          : null
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Box mt={2} />
                <Grid container alignItems="flex-start">
                  <Grid item xs={5}>
                    <Typography variant="body2">Property Image</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {!disabled ? (
                      <>
                        <Paper
                          elevation={0}
                          className={clsx(classes.dropzone, {
                            [classes.dropzoneActive]: isDragActive,
                            [classes.dropzoneAccept]: isDragAccept,
                            [classes.dropzoneReject]: isDragReject,
                          })}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            py={3}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <CloudUploadIcon className={classes.dropzoneIcon} />
                            <Typography variant="caption">
                              Drag and drop here or
                            </Typography>
                            <Box mt={2}>
                              <Link
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                <Typography variant="caption" color="secondary">
                                  <Box
                                    component="span"
                                    fontWeight={500}
                                    className={classes.dropzoneLink}
                                  >
                                    browse
                                  </Box>
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </Paper>
                        <Box mt={1}>
                          <Typography variant="caption">
                            File upload gif, jpeg. Recommended size 400x1000
                          </Typography>
                        </Box>
                        {fieldErrors.image ? (
                          <Box mt={1}>
                            <Alert severity="error">{fieldErrors.image}</Alert>
                          </Box>
                        ) : null}
                        {fileRejections.length ? (
                          <Box mt={1}>
                            <Alert severity="error">
                              Wrong file format. Only images are accepted
                            </Alert>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                    {files.length ? (
                      <Box className={classes.thumb} mt={disabled ? 0 : 2}>
                        {!disabled ? (
                          <IconButton
                            size="small"
                            color="primary"
                            className={classes.thumbCancel}
                            onClick={() => {
                              removeFile(files[0]);
                            }}
                            disabled={disabled}
                          >
                            <CancelIcon />
                          </IconButton>
                        ) : null}
                        <Box className={classes.thumbInner}>
                          <img
                            src={files[0].preview}
                            className={classes.thumbImg}
                          />
                        </Box>
                      </Box>
                    ) : disabled ? (
                      'None'
                    ) : null}
                  </Grid>
                </Grid>
                {!disabled ? (
                  <Grid container>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={6}>
                      <Box mt={5} />
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={() => {
                              setDisabled(true);
                            }}
                            fullWidth
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            type="submit"
                            disabled={disabled || submitDisabled}
                            fullWidth
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default PropertyProfile;
