import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { DataTable } from '../../../contexts/dataTable';
import { getReservedSpots } from '../../../actions/reserved.spots.actions';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import _ from 'lodash';

const DEBOUNCE_TIME = 1500;

const defaultPagination = {
  page: 1,
  page_size: 20,
};

const ReservedSpots = () => {
  const [pagination, setPagination] = useState(defaultPagination);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit } = useForm();
  const { data, paging } = useSelector((state) => state.reservedSpots);

  const cancelSearch = useRef(null);

  const handleRowClick = (e, row) => {
    const { unit_id } = row.original;
    if (e.target.tagName === 'TD') {
      history.push(`/admin/units/${unit_id}`);
    }
  };

  const handleSearch = _.debounce((data) => {
    setSearch(data.search || null);
  }, DEBOUNCE_TIME);

  const handleClearSearch = useCallback(() => {
    setValue('search', '');
    setSearch('');
  }, []);

  const getData = useCallback(() => {
    if (cancelSearch.current) {
      cancelSearch.current.cancel();
    }
    cancelSearch.current = axios.CancelToken.source();

    const searchParams = {
      ...pagination,
      search
    };

    setIsLoading(true);

    dispatch(getReservedSpots(searchParams, cancelSearch.current.token)).catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
    }).finally(() => setIsLoading(false));
  }, [search, pagination]);

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      {
        id: 'unit_number',
        Header: 'Unit #',
        accessor: 'unit_number',
      },
      {
        id: 'spot',
        Header: 'Spot LP',
        accessor: 'spot',
      }
    ],
    []
  );

  return (
    <Box mr={4} pb={4}>
      <VppBtnGroup
        handleSearch={handleSearch}
        search={search}
        handleClearSearch={handleClearSearch}
        register={register}
        handleSubmit={handleSubmit}
        isAutocomplete
      />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
          alignItems="center"
          height="50vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <DataTable
          data={data}
          columns={columns}
          handlePaging={setPagination}
          paging={paging}
          initialState={{
            paginable: true,

          }}
          handleRowClick={handleRowClick}
          title="Reserved Spots"
        />
      )}
      {data.length <= 0 ? (
        <Typography variant="h5">No results</Typography>
      ) : null}
    </Box>
  );
};

export default ReservedSpots;
