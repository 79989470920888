import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { DataTable } from '../../../contexts/dataTable';
import StickeredAccordion from './StickeredAccordion';
import { getStickered } from '../../../actions/stickered.action';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import { setLoading } from '../../../actions/system.actions';

const StickeredViolators = () => {
  const [search, setSearch] = useState('');
  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.stickered);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getStickered()).finally(() => {
      dispatch(setLoading(false));
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'violator_datetime',
        Cell({ value }) {
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Time',
        id: 'time',
        accessor: (row) => moment(row.violator_datetime).format('h:mm A'),
        Cell({ row }) {
          return moment(row.original.violator_datetime).format('h:mm A');
        },
      },
      {
        Header: 'Licence Plate',
        accessor: 'licence_plate',
      },
      {
        Header: 'Make',
        accessor: 'make',
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Color',
        accessor: 'color',
      },
      {
        Header: 'Driver Notes',
        accessor: 'driver_notes',
        Cell({ value }) {
          return (
            <>
              {value === 'Blocked gate' ? (
                <Typography>Blocked gate</Typography>
              ) : (
                <Typography>No permit</Typography>
              )}
            </>
          );
        },
      },
      {
        Header: '',
        id: 'actions',
        Cell({ row }) {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                row.getToggleRowExpandedProps().onClick();
              }}
            >
              {row.isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const handleRowClick = (e, row) => {
    row.getToggleRowExpandedProps().onClick();
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <StickeredAccordion
        warningDate={moment(row.original.warning_datetime).format('DD/MM/YYYY')}
        warningTime={moment(row.original.warning_datetime).format('h:mm A')}
        warningNumber={
          row.original.warning_number ? row.original.warning_number : '-'
        }
        driverName={row.original.driver_name}
        driverNotes={
          row.original.driver_notes ? row.original.driver_notes : '-'
        }
      />
    ),
    []
  );

  const handleSearch = useCallback((data) => {
    dispatch(getStickered(data)).then(() => {
      setSearch(data.search);
    });
  }, []);

  const handleClearSearch = () => {
    dispatch(getStickered()).then(() => {
      setSearch('');
      setValue('search', '');
    });
  };

  return (
    <Box mr={4} pb={4}>
      <VppBtnGroup
        handleSearch={handleSearch}
        search={search}
        handleClearSearch={handleClearSearch}
        register={register}
        handleSubmit={handleSubmit}
        isAutocomplete
      />
      <DataTable
        data={data}
        columns={columns}
        title="Stickered Violations"
        handleRowClick={handleRowClick}
        renderRowSubComponent={renderRowSubComponent}
      />
      {!data.length && search ? (
        <Typography variant="h5">No results</Typography>
      ) : null}
    </Box>
  );
};

export default StickeredViolators;
