import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { getRules } from '../../../../actions/rules.actions';
import { createUnit } from '../../../../actions/units.actions';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import VppDatePicker from '../../../../components/datePicker/vppDatePicker';
import CustomModal from '../../../../components/modal/CustomModal';
import { formatValidationErrors } from '../../../../utils';
import { addUnitSchema } from '../../../../validators';

const useStyles = makeStyles((theme) => ({
  fieldBody: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
}));

const AddUnitsPage = ({ handleSuccess }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const modalRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const rules = useSelector((state) => state.rules);
  const { register, setValue, handleSubmit, control } = useForm();
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const today = new Date();

  const addUnit = useCallback(
    (data) => {
      setLoading(true);
      setFieldErrors({});

      dispatch(createUnit(data))
        .then(() => {
          enqueueSnackbar('New unit was added successfully', {
            variant: 'success',
          });
          modalRef.current.close();
          handleSuccess();
        })
        .catch(({ response }) => {
          const { data } = response;
          setFieldErrors(data);
          enqueueSnackbar(
            'Error adding new unit. Please make sure all fields are filled in correctly.',
            {
              variant: 'error',
            }
          );
        })
        .finally(() => setLoading(false));
    },
    [createUnit]
  );

  const onSubmit = useCallback(
    (data) => {
      try {
        data.email = String(data.email).trim();
        addUnitSchema.parse(data);
        addUnit(data);
      } catch (err) {
        if (err instanceof z.ZodError) {
          setFieldErrors(formatValidationErrors(err));
        }
      }
    },
    [addUnit]
  );

  const handleOpen = () => {
    setIsOpen(true);
    dispatch(getRules());
  };

  const handleClose = () => {
    if (confirm('Are you sure you want to close the window without saving?')) {
      modalRef.current.close();
      setIsOpen(false);
      setFieldErrors({});
    }
  };

  useEffect(() => {
    const { tenant_vehicles_number, guest_vehicles_per_day_number } = rules;
    setValue('tenant_vehicles_number', tenant_vehicles_number);
    setValue('daily_guest_vehicles_number', guest_vehicles_per_day_number);
  }, [rules, setValue, isOpen]);

  return (
    <CustomModal
      titleText="Add New Unit"
      renderOpen={(handleClickOpen) => (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleClickOpen}
        >
          ADD UNIT
        </Button>
      )}
      onOpen={handleOpen}
      onClose={handleClose}
      ref={modalRef}
      controlled
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Typography color="primary" noWrap>
                <Box component="span" fontWeight="bold">
                  Unit Number
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                name="unit_number"
                placeholder="101"
                inputRef={register}
                error={!!fieldErrors.unit_number}
                helperText={
                  fieldErrors.unit_number
                    ? fieldErrors.unit_number.join(',')
                    : null
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Box mb={4} />
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Typography noWrap>Issue date</Typography>
            </Grid>
            <Grid item xs={9}>
              <VppDatePicker
                name="issue_date"
                size="medium"
                control={control}
                disabled
                fullWidth
                defaultValue={today}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Box mb={2} />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mb={2}
            >
              <Typography variant="caption">First Name</Typography>
              <TextField
                name="first_name"
                placeholder="First name"
                inputRef={register}
                error={!!fieldErrors.first_name}
                helperText={
                  fieldErrors.first_name
                    ? fieldErrors.first_name.join(',')
                    : null
                }
                fullWidth
                className={classes.fieldBody}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mb={2}
            >
              <Typography variant="caption">Last Name</Typography>
              <TextField
                name="last_name"
                placeholder="Last name"
                inputRef={register}
                error={!!fieldErrors.last_name}
                helperText={
                  fieldErrors.last_name ? fieldErrors.last_name.join(',') : null
                }
                fullWidth
                className={classes.fieldBody}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mb={2}
            >
              <Typography variant="caption">Email</Typography>
              <TextField
                name="email"
                placeholder="Email"
                inputRef={register}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email ? fieldErrors.email[0] : null}
                fullWidth
                className={classes.fieldBody}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mb={2}
            >
              <Typography variant="caption">Phone number</Typography>
              <TextField
                name="phone_number"
                placeholder="Phone number"
                inputRef={register}
                error={!!fieldErrors.phone_number}
                helperText={
                  fieldErrors.phone_number
                    ? fieldErrors.phone_number.join(',')
                    : null
                }
                fullWidth
                className={classes.fieldBody}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="subtitle2" color="primary">
                Permitted Vehicles:
              </Typography>
              <NumberInput
                name="tenant_vehicles_number"
                size="small"
                inputRef={register}
                error={!!fieldErrors.tenant_vehicles_number}
                helperText={
                  fieldErrors.tenant_vehicles_number
                    ? fieldErrors.tenant_vehicles_number
                    : null
                }
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="subtitle2" color="primary">
                Permitted daily guest vehicles:
              </Typography>
              <NumberInput
                name="daily_guest_vehicles_number"
                size="small"
                inputRef={register}
                error={!!fieldErrors.daily_guest_vehicles_number}
                helperText={
                  fieldErrors.daily_guest_vehicles_number
                    ? fieldErrors.daily_guest_vehicles_number
                    : null
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
          >
            SEND PERMIT
          </Button>
        </Box>
      </form>
    </CustomModal>
  );
};

export default AddUnitsPage;
