import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, Box, DialogTitle, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
}));

const CustomModal = forwardRef(
  ({ titleText, children, renderOpen, onOpen, onClose, controlled }, ref) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [open, setOpen] = useState(false);

    const handleClickOpen = (e) => {
      e?.stopPropagation();
      setOpen(true);
      onOpen && onOpen();
    };

    const handleClose = (e) => {
      e && e.stopPropagation();
      if (!controlled) {
        setOpen(false);
      }
      onClose && onClose();
    };

    useImperativeHandle(ref, () => ({
      close: () => {
        setOpen(false);
      },
    }));

    return (
      <>
        {renderOpen && renderOpen(handleClickOpen)}
        <Dialog
          onClose={handleClose}
          open={open}
          maxWidth="sm"
          disablePortal={true}
        >
          <Box position="relative">
            {titleText ? (
              <DialogTitle className={classes.title}>{titleText}</DialogTitle>
            ) : null}
            <Box position="absolute" right={16} top={16}>
              <IconButton aria-label="close" onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box px={8} py={4}>
            {children}
          </Box>
        </Dialog>
      </>
    );
  }
);

export default CustomModal;
