import React, { useEffect, useState } from 'react';
import VehiclePermitCard from '../../components/VehiclePermitCard/VehiclePermitCard';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantVehicles } from '../../actions/tenant.actions';
import { setLoading } from '../../actions/system.actions';
import VehicleForm from '../../components/VehiclePermitCard/VehicleForm';
import Box from '@material-ui/core/Box';
import PropertyName from '../../components/PropertyName/PropertyName';

const MyPermitsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenantReducer);
  const [editVehicle, setEditVehicle] = useState(null);
  const [editVehicleIndex, setEditVehicleIndex] = useState(null);
  const {
    meta: { available_tenants_slots },
  } = tenant;

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getTenantVehicles()).finally(() => {
      dispatch(setLoading(false));
    });
  }, [dispatch, getTenantVehicles, setLoading]);

  return editVehicle ? (
    <>
      <Box mt={-5} ml={-3}>
        <PropertyName />
      </Box>
      <Paper square>
        <VehicleForm
          vehicle={editVehicle}
          vehicleIndex={editVehicleIndex}
          setEditVehicle={setEditVehicle}
          setEditVehicleIndex={setEditVehicleIndex}
        />
      </Paper>
    </>
  ) : (
    <Grid direction="row" alignItems="center" spacing={3} container>
      {[...Array(available_tenants_slots)].map((item, index) => {
        return (
          <Grid key={index} item xs={12} sm={4}>
            <VehiclePermitCard
              vehicle={tenant.tenantVehicles[index]}
              vehicleIndex={index + 1}
              setEditVehicle={setEditVehicle}
              setEditVehicleIndex={setEditVehicleIndex}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MyPermitsPage;
