import * as types from '../actions/types';

const defaultState = {
  data: {},
};

const propertiesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_PROPERTY:
      const newState = { ...state };
      const { payload } = action;
      if (state.image && !payload.image) {
        delete newState.image;
      }
      return {
        ...newState,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default propertiesReducer;
