import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBarCustom from '../components/AppBar/AppBar';
import DrawerCustom from '../components/Drawer/Drawer';
import { Box, Hidden, IconButton, Typography } from '@material-ui/core';
import {
  TenantGuestPermitsIcon,
  TenantHomeIcon,
  TenantPermitsIcon,
} from '../Icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    top: 64,
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 96px)',
  },
}));

const TenantLayout = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    user: { user_info: userInfo },
  } = useSelector((state) => state.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    {
      text: 'Home',
      icon: (props) => <TenantHomeIcon fontSize="small" {...props} />,
      path: '/tenant/home',
    },
    {
      text: 'My Permits',
      icon: (props) => <TenantPermitsIcon fontSize="small" {...props} />,
      path: '/tenant/myPermits',
    },
    {
      text: 'Guest Permits',
      icon: (props) => <TenantGuestPermitsIcon fontSize="small" {...props} />,
      path: '/tenant/guestPermits',
      divider: true,
    },
    {
      text: 'My Account',
      icon: (props) => <AccountCircleIcon fontSize="small" {...props} />,
      path: '/tenant/myAccount',
    },
  ];

  const currentPage = menuItems.find((item) => item.path === location.pathname);

  return (
    <div className={classes.root}>
      <AppBarCustom
        title={currentPage ? currentPage.text : null}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Hidden smUp implementation="css">
        <DrawerCustom
          tenantPortal
          menuItems={menuItems}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          toolbar={
            <Box
              mx={-2}
              flexGrow={1}
              bgcolor="primary.main"
              color="primary.contrastText"
              p={2}
            >
              <IconButton
                color="inherit"
                style={{ marginLeft: -12 }}
                onClick={() => null}
              >
                <AccountCircleIcon style={{ fontSize: 56 }} />
              </IconButton>
              <Typography variant="h6">
                {userInfo.first_name} {userInfo.last_name}
              </Typography>
              <Typography>{userInfo.email}</Typography>
            </Box>
          }
          mobile
        />
      </Hidden>
      <Hidden xsDown implementation="css">
        <DrawerCustom menuItems={menuItems} tenantPortal />
      </Hidden>
      <Box m={4} pb={4} className={classes.content}>
        {children}
      </Box>
    </div>
  );
};

export default TenantLayout;
