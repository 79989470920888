import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  Hidden,
  TextField,
  Typography,
  Tooltip,
  Dialog,
  IconButton,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { VehicleIcon } from '../../Icons';
import { useForm } from 'react-hook-form';
import VppDatePicker from '../datePicker/vppDatePicker';
import {
  createTenantVehicle,
  updateTenantVehicle,
} from '../../actions/tenant.actions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSnackbar } from 'notistack';
import {
  createGuestVehicle,
  updateGuestVehicle,
} from '../../actions/guest.action';
import moment from 'moment-timezone';
import US_STATES_WITH_INITIALS from '../../us_states_and_initials';
import { Close } from '@material-ui/icons';
import { getGuestVehicles } from '../../actions/guest.action';

const useStyles = makeStyles((theme) => ({
  vehicleRoot: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#F7F8FC',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 9),
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
    },
  },
  guestHours: {
    background: '#F7F8FC',
    border: '1px solid #0F2854',
    borderRadius: 2,
    padding: '4px 20px',
  },
  title: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
}));

moment.tz.setDefault('America/Chicago');

const getExpirationDate = (vehicle, guest, guest_hours_start, guest_hours_finish) => {
  if (vehicle.expiration_date) {
    return vehicle.expiration_date;
  }
  if (guest) {
    let time = guest_hours_finish?.split(':');
    if (!time.length || !guest_hours_start || !guest_hours_finish) {
      enqueueSnackbar('There are errors with your property parking rules', {
        variant: 'error',
      });
      return;
    }
    if (guest_hours_start === guest_hours_finish) {
      return moment().add('days', 1).toDate();
    } else if (guest_hours_start < guest_hours_finish) {
      if (moment().format('HH:mm') >= guest_hours_finish) {
        return moment().add('days', 1).set({ hours: time[0], minutes: time[1], seconds: time[2] }).toDate();
      } else {
        return moment().set({ hours: time[0], minutes: time[1], seconds: time[2] }).toDate();
      }
    } else if (guest_hours_start > guest_hours_finish) {
      if (moment().format('HH:mm') >= guest_hours_finish) {
        return moment().add('days', 1).set({ hours: time[0], minutes: time[1], seconds: time[2] }).toDate();
      } else if (moment().format('HH:mm') < guest_hours_finish) {
        return moment().set({ hours: time[0], minutes: time[1], seconds: time[2] }).toDate();
      }
    }
  } else {
    return moment().add('years', 1).toDate();
  }
}

const VehicleForm = ({
  vehicle,
  vehicleIndex,
  setEditVehicle,
  setEditVehicleIndex,
  setStopControlled = () => {},
  guest = false,
  modalRef = null,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [guestConfirmation, setGuestConfirmation] = useState(false);
  const [lpConfirmation, setLpConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [guestDates, setGuestDates] = useState({});
  const [lp, setLp] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [licenceState, setLicenceState] = useState('');
  const [generalErrors, setGeneralErrors] = useState([]);
  const tenant = useSelector((state) => state.tenantReducer);
  const { reset, watch, register, control, handleSubmit, setValue, errors } =
    useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
    });
  const {
    meta: { guest_hours_start, guest_hours_finish },
  } = tenant;




  const expirationDate = getExpirationDate(vehicle,guest, guest_hours_start, guest_hours_finish)
  console.log({expirationDate})

  const setErrors = ({ response: { data = {} } }) => {
    if (Array.isArray(data)) {
      setGeneralErrors(data);
    } else {
      setFieldErrors(data);
    }
  };

  const finishForm = () => {
    if (guest) {
      setGuestConfirmation(true);
      setStopControlled(true);
    } else {
      setEditVehicle && setEditVehicle(null);
      setEditVehicleIndex && setEditVehicleIndex(null);
      modalRef && modalRef.current.close();
    }
  };

  const onSubmit = useCallback((data) => {
    setFieldErrors({});
    setGeneralErrors([]);
    setLoading(true);
    setLpConfirmation(false);
    if (!guest) {
      data.expiration_date = moment(data.expiration_date).startOf('day');
    }
    if (data.id) {
      dispatch(
        guest
          ? updateGuestVehicle(data.id, data)
          : updateTenantVehicle(data.id, data)
      )
        .then(() => {
          enqueueSnackbar('Vehicle updated successfully', {
            variant: 'success',
          });
          finishForm();
        })
        .catch((err) => {
          console.log(err.response);
          setErrors(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(guest ? createGuestVehicle(data) : createTenantVehicle(data))
        .then(() => {
          enqueueSnackbar('Vehicle crated successfully', {
            variant: 'success',
          });
          finishForm();
        })
        .catch((err) => {
          console.log(err.response);
          setErrors(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const licenseAlert = () => {
    setLpConfirmation(true);
  };

  useEffect(() => {
    Object.keys(vehicle).forEach((item) => {
      vehicle[item] && setValue(item, vehicle[item]);
      if (item === 'licence_state') {
        setLicenceState(vehicle[item]);
      }
    });
  }, [setValue]);

  useEffect(() => {
    if (tenant) {
      const currentVehicle = tenant[guest ? 'guestVehicles' : 'tenantVehicles'];
      if (currentVehicle[vehicleIndex - 1]) {
        setLp(currentVehicle[vehicleIndex - 1].licence_plate);
        setIsActive(currentVehicle[vehicleIndex - 1].is_active);
        setGuestDates({start_datetime: currentVehicle[vehicleIndex - 1].start_datetime, end_datetime: currentVehicle[vehicleIndex - 1].end_datetime})
      } else if (currentVehicle[currentVehicle.length - 1]) {
        setLp(currentVehicle[currentVehicle.length - 1].licence_plate);
        setIsActive(currentVehicle[currentVehicle.length - 1].is_active);
        setGuestDates({start_datetime: currentVehicle[currentVehicle.length - 1].start_datetime, end_datetime: currentVehicle[currentVehicle.length - 1].end_datetime})
      }
    }
  }, [tenant, guest]);
  useEffect(() => {
    dispatch(getGuestVehicles());
  }, [guestConfirmation]);

  const renderAutocompleteTextField = (params) => {
    return (
      <TextField
        {...params}
        type="text"
        variant="outlined"
        error={!!fieldErrors.licence_state}
        helperText={
          fieldErrors.licence_state ? fieldErrors.licence_state.join(',') : null
        }
        inputProps={{
          ...params.inputProps,
          style: {
            padding: '4px 6px',
          },
        }}
        fullWidth
      />
    );
  };

  return (
    <>
      {guestConfirmation ? (
        <>
          <Box display="flex" justifyContent="center" py={3}>
            <Typography variant="h6" color="primary">
              Guest Vehicle {vehicleIndex}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.vehicleRoot}
          >
            <Box mr={2}>
              <VehicleIcon color="secondary" />
            </Box>
            <Typography color="primary" style={{ fontWeight: 600 }}>
              Licence Plate
            </Typography>
            <Box ml={8}>
              <Typography color="primary" style={{ fontWeight: 600 }}>
                {lp}
              </Typography>
            </Box>
          </Box>
          <Box mt={4} display="flex" justifyContent="center">
            <Typography>
              Has a permit to park between
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            mt={7.5}
            mb={6}
          >
            <AccessTimeIcon color="secondary" />
            <Box className={classes.guestHours}>
              {guestDates.start_datetime ? guestDates.start_datetime : ''}
            </Box>
            <Box>to</Box>
            <Box className={classes.guestHours}>
              {guestDates.start_datetime ? guestDates.end_datetime: ''}
            </Box>
          </Box>
          <Box mt={4} display="flex" justifyContent="center">
            <Typography>
              This permit  is only valid for designated visitor parking spots and is based on space availability.
            </Typography>
          </Box>
          <Box px={2}>
            <Box my={2}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => {
                  setEditVehicle && setEditVehicle(null);
                  setEditVehicleIndex && setEditVehicleIndex(null);
                  setStopControlled(false);
                  modalRef && modalRef.current.close();
                }}
                fullWidth
              >
                OK
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
      {!guestConfirmation ? (
        <>
          <Box display="flex" justifyContent="center" py={3}>
            <Typography variant="h6" color="primary">
              {vehicle.id
                ? `Editing ${guest ? 'Guest ' : ''} Vehicle information`
                : `${guest ? 'Guest ' : ''} Vehicle Registration`}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.vehicleRoot}
          >
            <Box mr={2}>
              <VehicleIcon color="secondary" />
            </Box>
            <Typography color="primary" style={{ fontWeight: 600 }}>
              {guest ? 'Guest ' : ''} Vehicle {vehicleIndex}
            </Typography>
          </Box>
          <Collapse in={!!generalErrors.length}>
            <Box my={2}>
              <Alert severity="error">{generalErrors.join(' ')}</Alert>
            </Box>
          </Collapse>
          <form onSubmit={handleSubmit(onSubmit)}>
            {vehicle.id ? (
              <input type="hidden" name="id" ref={register} />
            ) : null}
            <Box mt={1} mb={3} px={2}>
              <Tooltip
                title="please enter letters and number only for your license plate example: ABC123"
                placement="top"
              >
                <Typography
                  variant="body2"
                  style={{
                    cursor: 'help',
                  }}
                >
                  Licence Plate
                </Typography>
              </Tooltip>
              <TextField
                autoFocus
                name="licence_plate"
                type="text"
                variant="outlined"
                placeholder="ABC123"
                inputRef={register({
                  maxLength: {
                    value: 11,
                    message: 'License plate must have between 3 and 11 characters',
                  },
                })}
                onChange={(event, props) => {
                  setValue(
                    'licence_plate',
                    event.target.value.toUpperCase().replaceAll(/[\W_]/g, '')
                  );
                }}
                error={!!fieldErrors.licence_plate || errors?.licence_plate}
                helperText={
                  fieldErrors.licence_plate
                    ? fieldErrors.licence_plate.join(',')
                    : errors?.licence_plate
                    ? errors?.licence_plate?.message
                    : null
                }
                fullWidth
                autoComplete="off"
              />
            </Box>
            <Box mt={1} mb={3} px={2}>
              <Typography variant="body2">Licence State</Typography>
              <Autocomplete
                options={Object.keys(US_STATES_WITH_INITIALS)}
                getOptionLabel={(option) =>
                  US_STATES_WITH_INITIALS[option] || ''
                }
                getOptionSelected={() => watch('licence_state')}
                {...register('licence_state')}
                value={watch('licence_state') || ' '}
                defaultValue={' '}
                inputRef={register}
                inputValue={licenceState}
                onInputChange={(event, newInputValue) => {
                  setLicenceState(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue('licence_state', newValue);
                }}
                renderInput={renderAutocompleteTextField}
              />
            </Box>
            <Box mt={1} mb={3} px={2}>
              <Typography variant="body2">Make</Typography>
              <TextField
                name="make"
                type="text"
                variant="outlined"
                placeholder="Honda"
                inputRef={register}
                error={!!fieldErrors.make}
                helperText={
                  fieldErrors.make ? fieldErrors.make.join(',') : null
                }
                fullWidth
              />
            </Box>
            <Box mt={1} mb={3} px={2}>
              <Typography variant="body2">Model</Typography>
              <TextField
                name="model"
                type="text"
                variant="outlined"
                placeholder="Civic"
                inputRef={register}
                error={!!fieldErrors.model}
                helperText={
                  fieldErrors.model ? fieldErrors.model.join(',') : null
                }
                fullWidth
              />
            </Box>
            <Box mt={1} mb={3} px={2}>
              <Typography variant="body2">Color</Typography>
              <TextField
                name="color"
                type="text"
                variant="outlined"
                placeholder="White"
                inputRef={register}
                error={!!fieldErrors.color}
                helperText={
                  fieldErrors.color ? fieldErrors.color.join(',') : null
                }
                fullWidth
              />
            </Box>
            <Box mt={1} mb={3} px={2}>
              <Typography variant="body2">Permit Expiration Date</Typography>
              <VppDatePicker
                control={control}
                name="expiration_date"
                size="medium"
                error={!!fieldErrors.expiration_date}
                helperText={
                  fieldErrors.expiration_date
                    ? fieldErrors.expiration_date.join(',')
                    : null
                }
                defaultValue={expirationDate}
                disabled
                fullWidth
              />
            </Box>
            <Box mx={2} pb={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="button"
                onClick={() => {
                  licenseAlert();
                }}
                disabled={loading}
                fullWidth
              >
                {guest ? 'Next' : vehicle.id ? 'SAVE' : 'Register'}
              </Button>
              <Hidden smUp>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    onClick={() => {
                      setEditVehicle && setEditVehicle(null);
                      setEditVehicleIndex && setEditVehicleIndex(null);
                    }}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Box>
              </Hidden>
            </Box>
            <Hidden xsDown={!lpConfirmation}>
              <Dialog
                onClose={(e) => {
                  setLpConfirmation(false);
                }}
                open={lpConfirmation}
                maxWidth="sm"
                disablePortal={true}
              >
                <Box position="relative">
                  <Box position="absolute" right={16} top={16}>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setLpConfirmation(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box mt={4} mb={3} mx={6} px={4}>
                  <Tooltip
                    title="please enter letters and number only for your license plate example: ABC123"
                    placement="top"
                  >
                    <Typography
                      // variant="body2"
                      style={{
                        cursor: 'help',
                      }}
                    >
                      Please confirm your license plate number
                    </Typography>
                  </Tooltip>
                  <Box my={2}>
                    <TextField
                      autoFocus
                      // name="licence_plate"
                      type="text"
                      variant="outlined"
                      placeholder="ABC123"
                      value={watch('licence_plate') || ''}
                      onChange={(event, props) => {
                        setValue(
                          'licence_plate',
                          event.target.value
                            .toUpperCase()
                            .replaceAll(/[\W_]/g, '')
                        );
                      }}
                      error={
                        !!fieldErrors.licence_plate || errors?.licence_plate
                      }
                      helperText={
                        fieldErrors.licence_plate
                          ? fieldErrors.licence_plate.join(',')
                          : errors?.licence_plate
                          ? errors?.licence_plate?.message
                          : null
                      }
                      fullWidth
                      autoComplete="off"
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color="error"
                    style={{
                      marginBottom: '1rem',
                    }}
                  >
                    Note! if the licence plate is incorrect your vehicle may be
                    towed
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={loading}
                    fullWidth
                  >
                    confirm
                  </Button>
                </Box>
              </Dialog>
            </Hidden>
          </form>
        </>
      ) : null}
    </>
  );
};

export default VehicleForm;
