import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomModal from '../../../../components/modal/CustomModal';
import { deleteUser } from '../../../../actions/user.actions';
import Delete from '@material-ui/icons/Delete';

const DeleteUser = ({ userId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modalRef = useRef(null);
  const { role } = useSelector((store) => store.user);

  const disabled = role !== 'Admin';

  const handleDelete = () => {
    dispatch(deleteUser(userId)).then(() => {
      enqueueSnackbar('User was deleted', {
        variant: 'success',
      });
    });
    modalRef.current.close();
  };

  const handleClose = () => {
    modalRef.current.close();
  };

  return (
    <CustomModal
      renderOpen={(handleClickOpen) => (
        <Tooltip title="Delete">
          <IconButton onClick={handleClickOpen} disabled={disabled}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      ref={modalRef}
    >
      <Box textAlign="center">
        <Box>
          <ErrorOutlineIcon style={{ width: 67, height: 67, color: 'red' }} />
        </Box>
        <Typography variant="h6" color="primary">
          <Box fontWeight="bold">Warning!</Box>
        </Typography>
        <Box mt={2} />
        <Typography variant="body1">
          Are you sure you want to delete this contract? All customer data will
          be sent to the delete and the user will be ready for use by the next
          customer. Clear fields for entering new data?
        </Typography>
        <Box mt={7}>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Box mr={2} component="span" />
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default DeleteUser;
