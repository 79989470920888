import React, { useEffect, useState } from 'react';
import VehiclePermitCard from '../../components/VehiclePermitCard/VehiclePermitCard';
import { Divider, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../actions/system.actions';
import VehicleForm from '../../components/VehiclePermitCard/VehicleForm';
import {
  getGuestArchiveVehicles,
  getGuestVehicles,
} from '../../actions/guest.action';
import Box from '@material-ui/core/Box';
import PropertyName from '../../components/PropertyName/PropertyName';

const GuestPermitsPage = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState('guest');
  const tenant = useSelector((state) => state.tenantReducer);
  const [editVehicle, setEditVehicle] = useState(null);
  const [editVehicleIndex, setEditVehicleIndex] = useState(null);
  const {
    meta: { available_guests_slots },
  } = tenant;

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const dataArray =
    tab === 'past' ? tenant.guestVehicles : Array(available_guests_slots);

  useEffect(() => {
    console.log('this is aan updated version')
    dispatch(setLoading(true));
    dispatch(
      tab === 'past' ? getGuestArchiveVehicles() : getGuestVehicles()
    ).finally(() => {
      dispatch(setLoading(false));
    });
  }, [dispatch, getGuestVehicles, setLoading, tab]);

  return editVehicle ? (
    <>
      <Box mt={-5} ml={-3}>
        <PropertyName />
      </Box>
      <Paper square>
        <VehicleForm
          vehicle={editVehicle}
          vehicleIndex={editVehicleIndex}
          setEditVehicle={setEditVehicle}
          setEditVehicleIndex={setEditVehicleIndex}
          guest
        />
      </Paper>
    </>
  ) : (
    <>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab value="guest" label="Guest Permits" />
        <Tab value="past" label="Past Permits" />
      </Tabs>
      <Divider />
      <Box mt={4} />
      <Grid direction="row" alignItems="center" spacing={3} container>
        {tab === 'past' && !dataArray.length ? (
          <Box ml={2} mt={2}>
            <Typography variant="h5" color="primary">
              No past permits available
            </Typography>
          </Box>
        ) : null}
        {[...dataArray].map((item, index) => {
          return (
            <Grid key={index} item xs={12} sm={4}>
              <VehiclePermitCard
                vehicle={tenant.guestVehicles[index]}
                vehicleIndex={index + 1}
                setEditVehicle={setEditVehicle}
                setEditVehicleIndex={setEditVehicleIndex}
                readOnly={tab === 'past'}
                pastPermit={tab === 'past'}
                guest
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default GuestPermitsPage;
