import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { DataTable } from '../../../contexts/dataTable';
import { getUsers } from '../../../actions/user.actions';
import AddUserPage from './add/AddUsers';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import StatusUserDropdown from '../../../components/StatusDropdown/StatusUserDropdown';
import DeleteUser from './delete/DeleteUser';
import { setLoading } from '../../../actions/system.actions';

const UsersPage = () => {
  const [search, setSearch] = useState('');
  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getUsers()).finally(() => {
      dispatch(setLoading(false));
    });
  }, []);

  const listUsers = useSelector((state) => {
    return state.listUsersReducer.users;
  });

  const columns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'userName',
        Cell({ row }) {
          const { first_name, last_name } = row.original;
          return `${first_name} ${last_name}`;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone number',
        accessor: 'phone_number',
      },
      {
        Header: 'Role',
        accessor: 'user_role',
        Cell({ value }) {
          return <Typography variant="p">{value}</Typography>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell({ row }) {
          return (
            <StatusUserDropdown
              id={row.original.id}
              stautsChip={row.original.status}
            />
          );
        },
      },
      {
        Header: '',
        id: 'actions',
        Cell({ row }) {
          return <DeleteUser userId={row.original.id} />;
        },
      },
    ],
    []
  );

  const handleSearch = useCallback((data) => {
    dispatch(getUsers(data)).then(() => {
      setSearch(data.search);
    });
  }, []);

  const handleClearSearch = () => {
    dispatch(getUsers()).then(() => {
      setSearch('');
      setValue('search', '');
    });
  };
  return (
    <Box mr={4} pb={4}>
      <VppBtnGroup
        handleSearch={handleSearch}
        register={register}
        handleSubmit={handleSubmit}
        handleClearSearch={handleClearSearch}
        search={search}
        isAutocomplete
      >
        <AddUserPage handleSuccess={handleClearSearch} />
      </VppBtnGroup>
      <DataTable data={listUsers} columns={columns} title="Users" />
      {!listUsers.length && search ? (
        <Typography variant="h5">No results</Typography>
      ) : null}
    </Box>
  );
};

export default UsersPage;
