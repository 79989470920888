import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { LogOutIcon } from '../../Icons';
import { useAuth } from '../../contexts/auth';
import { useSelector } from 'react-redux';
import PropertyName from '../PropertyName/PropertyName';

const drawerWidth = 255;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    justifyContent: 'space-between',
  },
}));
const DrawerCustom = ({
  menuItems,
  mobile,
  mobileOpen,
  handleDrawerToggle,
  toolbar,
  tenantPortal,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const history = useHistory();
  const { logOut } = useAuth();
  const property = useSelector((store) => store.property);

  const handleLogOut = async () => {
    await logOut();
    let portal_name;
    if (tenantPortal) {
      portal_name = 'tenant';
    } else {
      portal_name = 'manager';
    }
    history.replace(`/${property.sub_domain_name}/${portal_name}/login`);
  };

  const handleMenuClick = (path) => {
    history.push(path);
    if (mobile && mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant={mobile ? 'temporary' : 'permanent'}
        open={mobile ? mobileOpen : null}
        onClose={mobile ? handleDrawerToggle : null}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <>
          <Toolbar>{toolbar}</Toolbar>
          {property.name ? (
            <>
              <PropertyName />
              <Box mb={-1}>
                <Divider />
              </Box>
            </>
          ) : null}
          <Box display="flex" flexGrow={1} flexDirection="column">
            <List>
              {menuItems.map((item, index) => {
                const active = location.pathname === item.path;
                return (
                  <ListItem
                    button
                    key={index}
                    selected={active}
                    divider={!!item.divider}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <ListItemIcon>
                      {item.icon({
                        color: active ? 'primary' : 'inherit',
                      })}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Badge
                          color="secondary"
                          badgeContent={item.badge}
                          invisible={!item.badge}
                          showZero
                        >
                          <Typography color={active ? 'primary' : 'inherit'}>
                            {item.text}
                          </Typography>
                        </Badge>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box mb={4}>
            <List>
              <ListItem button onClick={() => handleLogOut()}>
                <ListItemIcon>
                  <LogOutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Log Out</ListItemText>
              </ListItem>
            </List>
          </Box>
        </>
      </Drawer>
    </>
  );
};

export default DrawerCustom;
