import React from 'react';
import { Toolbar } from '@material-ui/core';
import CardLogin from '../../components/Card/CardLogin';
import PropertyLayout from '../../layouts/Property';

const Login = () => (
  <PropertyLayout>
    <Toolbar />
    <CardLogin />
    <Toolbar />
  </PropertyLayout>
);
export default Login;
