import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import {
  userReducer,
  unitsReducer,
  listUsersReducer,
  propertiesReducer,
  towsReducer,
  userProfileReducer,
  rulesReducer,
  systemReducer,
  tenantReducer,
  guestPermitsReducer,
  reservedSpotsReducer,
  activitiesReducer
} from './reducers';
import StickeredReducer from './reducers/stickered.violators.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  units: unitsReducer,
  tows: towsReducer,
  property: propertiesReducer,
  stickered: StickeredReducer,
  listUsersReducer,
  userProfileReducer,
  rules: rulesReducer,
  system: systemReducer,
  tenantReducer,
  guestPermits: guestPermitsReducer,
  reservedSpots: reservedSpotsReducer,
  activities: activitiesReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export { store };
