import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setReservedSpots = (payload) => ({
  type: types.SET_RESERVED_SPOTS,
  payload,
});

export const getReservedSpots =
  (params, cancelToken = null) =>
    async (dispatch) => {
      let config = {
        params,
        cancelToken,
      };
      const { data } = await vpp.get(VPP_API.getReservedSpots, config);
      dispatch(setReservedSpots(data));
    };
