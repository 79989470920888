import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setRegistrationContactInfo = (payload) => ({ type: types.USER_REGISTRATION_INFO, payload });


export const getUserRegistrationContactInfo = (key) => (dispatch) =>
  vpp.get(VPP_API.registrationConfirmManagerInfo(key)).then(({ data }) => {
      dispatch(setRegistrationContactInfo(data));
  });

export const sendUserInfo = (key, email, phoneNumber) => (dispatch) =>
 vpp.post(VPP_API.postUserInfo(key), {email, phone_number: phoneNumber})


export const sendFinishUserInfo = (key,  password, password2) => (dispatch) =>
 vpp.post(VPP_API.postFinishUserRegisterInfo(key), { password, password2})



