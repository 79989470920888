import React from 'react';
import {
  Box,
  Paper,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormGroup,
} from '@material-ui/core';

const UnitsContactInformationBlock = ({ inputRefs, fieldErrors, disabled }) => {
  return (
    <>
      <Paper elevation={0} variant="outlined">
        <FormGroup>
          <legend>Contact information</legend>
        </FormGroup>
        <Box pt={1} px={3} pb={3}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel>
                <Typography variant="caption" color="textPrimary">
                  Name
                </Typography>
              </InputLabel>
              <TextField
                name="first_name"
                size="small"
                inputRef={inputRefs}
                disabled={disabled}
                error={!!fieldErrors.first_name}
                helperText={
                  fieldErrors.first_name ? fieldErrors.first_name : null
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel>
                <Typography variant="caption" color="textPrimary">
                  Last Name
                </Typography>
              </InputLabel>
              <TextField
                name="last_name"
                size="small"
                inputRef={inputRefs}
                disabled={disabled}
                error={!!fieldErrors.last_name}
                helperText={
                  fieldErrors.last_name ? fieldErrors.last_name : null
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel>
                <Typography variant="caption" color="textPrimary">
                  Phone number
                </Typography>
              </InputLabel>
              <TextField
                name="phone_number"
                size="small"
                inputRef={inputRefs}
                disabled={disabled}
                error={!!fieldErrors.phone_number}
                helperText={
                  fieldErrors.phone_number ? fieldErrors.phone_number : null
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputLabel>
                <Typography variant="caption" color="textPrimary">
                  Email
                </Typography>
              </InputLabel>
              <TextField
                name="email"
                size="small"
                inputRef={inputRefs}
                disabled={disabled}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email ? fieldErrors.email[0] : null}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default UnitsContactInformationBlock;
