import * as types from '../actions/types';

const defaultState = {
  loggedIn: false,
  role: null,
  user: {},
  password: '',
  password2: '',
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.USER_LOGIN:
    case types.USER_REFRESH_TOKEN:
      const user = action.payload;
      localStorage.setItem('token', user.access);
      localStorage.setItem('refresh_token', user.refresh);
      localStorage.setItem('portal', user.portal);
      return {
        loggedIn: true,
        role: user.user_info.user_role,
        user: { ...user },
      };
    case types.USER_LOG_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      return {
        loggedIn: false,
        user: {},
      };
    case types.SET_USER_PROFILE:
      return {
        ...state,
        user: { ...state.user, user_info: { ...action.payload } },
      };
    case types.USER_REGISTRATION_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;