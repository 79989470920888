import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import VppSelect from '../../../components/select/VppSelect';
import { getRules, updateRules } from '../../../actions/rules.actions';
import NumberInput from '../../../components/NumberInput/NumberInput';

const ParkingRulesPage = () => {
  const [disabled, setDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { register, handleSubmit, control, setValue, watch } = useForm();
  const data = useSelector((state) => state.rules);
  const { role } = useSelector((store) => store.user);

  const disabledRestricted = role !== 'Admin';

  moment.locale('en');

  const towsHourSelect = [...Array(24).fill()].map((_, hour) => ({
    name: moment({ hour }).format('h:mm A'),
    value: moment({ hour }).format('HH:mm:ss'),
  }));

  const weekDaysArray = moment.weekdaysShort();
  const weekDaysLongArray = moment.weekdays();

  const onSubmit = useCallback(
    (rules) => {
      setFieldErrors({});
      setSubmitDisabled(true);
      dispatch(updateRules(rules))
        .then(() => {
          enqueueSnackbar('Parking rules saved successfully', {
            variant: 'success',
          });
          setDisabled(true);
        })
        .catch(({ response }) => {
          const { data } = response;
          setFieldErrors(data);
          enqueueSnackbar(
            'Error saving parking rules. Please make sure all fields are filled in correctly.',
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    },
    [dispatch, setSubmitDisabled]
  );

  useEffect(() => {
    Object.keys(data).forEach((item) => {
      if (item === 'towing_enforcement_days') {
        Object.keys(data[item]).forEach((dayItem) => {
          setValue(`${item}.${dayItem}`, data[item][dayItem]);
        });
      } else {
        setValue(item, data[item]);
      }
    });
  }, [data, disabled]);

  useEffect(() => {
    dispatch(getRules());
  }, [dispatch]);

  useEffect(() => {
    setFieldErrors({});
  }, [disabled, setFieldErrors]);

  return (
    <Box mb={5} mr={4} pb={4}>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          disabled={disabledRestricted}
          onClick={() => {
            setDisabled(false);
          }}
        >
          Edit
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={0} variant="outlined">
          <Box p={4}>
            <Typography variant="subtitle2">Default Values</Typography>
            <Box mt={3}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item md={3}>
                  <Typography variant="body2">
                    Number of tenant Vehicles
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  <NumberInput
                    name="tenant_vehicles_number"
                    size="small"
                    inputRef={register}
                    disabled={disabled}
                    error={!!fieldErrors.tenant_vehicles_number}
                    helperText={
                      fieldErrors.tenant_vehicles_number
                        ? fieldErrors.tenant_vehicles_number
                        : null
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant="body2">
                    Number of guest vehicles Per Day
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  <NumberInput
                    name="guest_vehicles_per_day_number"
                    size="small"
                    inputRef={register}
                    disabled={disabled}
                    error={!!fieldErrors.guest_vehicles_per_day_number}
                    helperText={
                      fieldErrors.guest_vehicles_per_day_number
                        ? fieldErrors.guest_vehicles_per_day_number
                        : null
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <Typography variant="body2">Tow Hours</Typography>
                </Grid>
                <Grid item md={9}>
                  <Box display="flex" alignItems="center">
                    <VppSelect
                      name="tow_hour_start"
                      size="medium"
                      control={control}
                      options={towsHourSelect}
                      disabled={disabled}
                    />
                    <Box mx={2}>
                      <Typography
                        variant="body2"
                        color={disabled ? 'textSecondary' : 'textPrimary'}
                      >
                        until
                      </Typography>
                    </Box>
                    <VppSelect
                      name="tow_hour_finish"
                      size="medium"
                      control={control}
                      options={towsHourSelect}
                      disabled={disabled}
                    />
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="body2">
                    Towing enforcement days
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  <Box display="flex" alignItems="center">
                    {weekDaysArray.map((day, index) => (
                      <Box key={index}>
                        <FormControlLabel
                          control={
                            <Controller
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  color="primary"
                                  disabled={disabled}
                                  checked={props.value === true}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                              name={`towing_enforcement_days.${weekDaysLongArray[
                                index
                              ].toLowerCase()}`}
                              control={control}
                              defaultValue=""
                            />
                          }
                          disabled={disabled}
                          label={day}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <FormGroup>
            <legend>
              <Box display="flex" alignItems="center">
                <Box ml={1} py={1}>
                  <Typography variant="subtitle2">Tenant Rules</Typography>
                </Box>
              </Box>
            </legend>
          </FormGroup>
          <Box p={4}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={3}>
                <Typography variant="body2">
                  Allow Tenant to change main vehicle information
                </Typography>
              </Grid>
              <Grid item md={9}>
                <Controller
                  render={(props) => (
                    <RadioGroup
                      {...props}
                      onChange={(e) => {
                        props.onChange(e.target.value === 'true');
                      }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        disabled={disabled}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        disabled={disabled}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                  name="allow_tenant_to_change_information"
                  defaultValue="true"
                  control={control}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">
                  Notify on Tenant Information change
                </Typography>
              </Grid>
              <Grid item md={9}>
                <Controller
                  render={(props) => (
                    <RadioGroup
                      {...props}
                      onChange={(e) => {
                        props.onChange(e.target.value === 'true');
                      }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        disabled={disabled}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        disabled={disabled}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                  name="notify_on_tenant_change"
                  defaultValue=""
                  control={control}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2">
                  Maximum number of changes allowed for main tenant vehicle
                </Typography>
              </Grid>
              <Grid item md={8}>
                <NumberInput
                  name="maximum_number_of_tenant_changes"
                  size="small"
                  inputRef={register}
                  disabled={disabled}
                  error={!!fieldErrors.maximum_number_of_tenant_changes}
                  helperText={
                    fieldErrors.maximum_number_of_tenant_changes
                      ? fieldErrors.maximum_number_of_tenant_changes
                      : null
                  }
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">Notification Email</Typography>
              </Grid>
              <Grid item md={9}>
                <Box mt={1} width={300}>
                  <TextField
                    name="notification_email"
                    placeholder="christianbale@mail.com"
                    inputRef={register}
                    disabled={disabled}
                    size="small"
                    error={!!fieldErrors.notification_email}
                    helperText={
                      fieldErrors.notification_email
                        ? fieldErrors.notification_email
                        : null
                    }
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">Allow reserved spots</Typography>
              </Grid>
              <Grid item md={9}>
                <Box mt={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    render={(props) => (
                      <RadioGroup
                        {...props}
                        onChange={(e) => {
                          props.onChange(e.target.value === 'true');
                        }}
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          disabled={disabled}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          disabled={disabled}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                    name="allow_tenant_to_own_reserved_spots"
                    defaultValue="false"
                    control={control}
                  />
                  <NumberInput
                    name="maximum_number_of_reserved_spots"
                    size="small"
                    width={300}
                    inputRef={register}
                    style={{ visibility: watch('allow_tenant_to_own_reserved_spots') === true ? 'visible' : 'hidden' }}
                    disabled={disabled}
                    error={!!fieldErrors.maximum_number_of_reserved_spots}
                    helperText={
                      fieldErrors.maximum_number_of_reserved_spots
                        ? fieldErrors.maximum_number_of_reserved_spots
                        : null
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <FormGroup>
            <legend>
              <Box display="flex" alignItems="center">
                <Box ml={1} py={1}>
                  <Typography variant="subtitle2">Guest Rules</Typography>
                </Box>
              </Box>
            </legend>
          </FormGroup>
          <Box p={4}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={3}>
                <Typography variant="body2">
                  Guest cannot park more than
                </Typography>
              </Grid>
              <Grid item md={9}>
                <Box display="flex" alignItems="center">
                  <NumberInput
                    name="guest_parking_count"
                    size="small"
                    inputRef={register}
                    disabled={disabled}
                    error={!!fieldErrors.guest_parking_count}
                    helperText={
                      fieldErrors.guest_parking_count
                        ? fieldErrors.guest_parking_count
                        : null
                    }
                  />
                  <Box mx={2}>
                    <Typography
                      variant="body2"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                    >
                      times within
                    </Typography>
                  </Box>
                  <NumberInput
                    name="guest_parking_period"
                    size="small"
                    inputRef={register}
                    disabled={disabled}
                    error={!!fieldErrors.guest_parking_period}
                    helperText={
                      fieldErrors.guest_parking_period
                        ? fieldErrors.guest_parking_period
                        : null
                    }
                  />
                  <Box ml={2}>
                    <Typography
                      variant="body2"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                    >
                      days
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">
                  Maximum consecutive parking days for guests
                </Typography>
              </Grid>
              <Grid item md={9}>
                <NumberInput
                  name="maximum_parking_days_for_guest"
                  size="small"
                  inputRef={register}
                  disabled={disabled}
                  error={!!fieldErrors.maximum_parking_days_for_guest}
                  helperText={
                    fieldErrors.maximum_parking_days_for_guest
                      ? fieldErrors.maximum_parking_days_for_guest
                      : null
                  }
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">Guest Permit Duration</Typography>
              </Grid>
              <Grid item md={9}>
                <Box display="flex" alignItems="center">
                  <NumberInput
                    name="guest_permit_duration"
                    size="small"
                    inputRef={register}
                    disabled={disabled}
                    error={!!fieldErrors.guest_permit_duration}
                    helperText={
                      fieldErrors.guest_permit_duration
                        ? fieldErrors.guest_permit_duration
                        : null
                    }
                  />
                  <Box mx={2}>
                    <Typography
                      variant="body2"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                    >
                      hours
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2">Guest Hours</Typography>
              </Grid>
              <Grid item md={9}>
                <Box display="flex" alignItems="center">
                  <VppSelect
                    name="guest_hours_start"
                    size="medium"
                    control={control}
                    options={towsHourSelect}
                    disabled={disabled}
                  />
                  <Box mx={2}>
                    <Typography
                      variant="body2"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                    >
                      until
                    </Typography>
                  </Box>
                  <VppSelect
                    name="guest_hours_finish"
                    size="medium"
                    control={control}
                    options={towsHourSelect}
                    disabled={disabled}
                  />
                </Box>
              </Grid>
            </Grid>
            {!disabled ? (
              <Box display="flex" justifyContent="flex-end" mt={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setDisabled(true);
                  }}
                >
                  Cancel
                </Button>
                <Box ml={3} />
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  disabled={disabled || submitDisabled}
                >
                  Save
                </Button>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default ParkingRulesPage;
