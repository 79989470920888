import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import { GuestsPermits, TenantsPermits } from '../../Icons';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: 60,
  },
  paper: {
    padding: '18px 32px',
    minHeight: 96,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graphIcon: {
    fontSize: '59px',
  },
  paperGuestsBg: {
    backgroundColor: 'rgba(66, 133, 244, 0.1)',
  },
  paperTenantsBg: {
    backgroundColor: 'rgba(31, 88, 147, 0.1)',
  },
  contentColorGuests: {
    color: '#4285F4',
  },
  contentColorTenants: {
    color: '#1358A0',
  },
});

const Graphs = ({ meta }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={clsx(classes.paper, classes.paperGuestsBg)}
          >
            <Box>
              <Typography variant="h4" className={classes.contentColorGuests}>
                {meta.guest_vehicles_count}
              </Typography>
              <Typography
                variant="body2"
                className={classes.contentColorGuests}
              >
                Guest permits issued
              </Typography>
              <Typography
                variant="body2"
                className={classes.contentColorTenants}
              >
                Last 7 days
              </Typography>
            </Box>
            <GuestsPermits className={classes.graphIcon} viewBox="0 0 59 32" />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={clsx(classes.paper, classes.paperTenantsBg)}
          >
            <Box>
              <Typography variant="h4" className={classes.contentColorTenants}>
                {meta.tenant_vehicles_count}
              </Typography>
              <Typography
                variant="body2"
                className={classes.contentColorTenants}
              >
                Tenants permits issued
              </Typography>
              <Typography
                variant="body2"
                className={classes.contentColorTenants}
              >
                Total Active
              </Typography>
            </Box>
            <TenantsPermits className={classes.graphIcon} viewBox="0 0 59 32" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Graphs;
