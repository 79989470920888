import React, {
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {Box, CircularProgress, Tab, Typography} from '@material-ui/core';
import { DataTable } from '../../../contexts/dataTable';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import { getActivities } from '../../../actions/activities.actions';
import { dateFormatter, timeFormatter } from '../../../utils';
import axios from 'axios';
import {TabContext, TabList, TabPanel} from "@material-ui/lab";

const sortableColumns = [
    'date',
    'time',
    'permit_type',
    'activity_type',
    'old_value',
    'value',
    'details',
];


const defaultPagination = {
    page: 1,
    page_size: 100
};

const ReportsPage = () => {
    const [pagination, setPagination] = useState(defaultPagination);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("1");

    const { data, paging } = useSelector((state) => state.activities);
    const { register, setValue, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const cancelSearch = useRef(null);

    const columns = useMemo(
        () => [
            {
                id: 'unit',
                Header: 'Unit #',
                accessor: 'unit_number',
                width: 50,
            },
            {
                id: 'date',
                Header: 'Date',
                accessor: 'date',
                Cell({ value }) {
                    return dateFormatter(value);
                },
            },
            {
                id: 'time',
                Header: 'Time',
                accessor: 'time',
                Cell({ value }) {
                    return timeFormatter(value);
                },
            },
            {
                Header: 'Permit type',
                id: 'permit_type',
                accessor: 'permit_type',
            },
            {
                Header: 'Permit number',
                id: 'permit_number',
                accessor: 'permit_number',
            },
            {
                Header: 'Activity type',
                id: 'activity_type',
                accessor: 'activity_type',
            },
            {
                Header: 'Old value',
                id: 'old_value',
                accessor: 'old_value',
            },
            {
                id: 'value',
                Header: 'New Value',
                accessor: 'value',
            },
            {
                Header: 'User',
                id: 'user',
                accessor: 'user',
            },
            {
                Header: 'Details',
                id: 'details',
                accessor: 'details',
                width: 700,
            },
        ],
        []
    );

    const getData = useCallback((search) => {
        if (cancelSearch.current) {
            cancelSearch.current.cancel();
        }
        cancelSearch.current = axios.CancelToken.source();

        const params = {
            ...pagination,
            ...sort
        };

        if (search) {
            params.search = search;
        }

        setIsLoading(true);
        dispatch(getActivities(params, cancelSearch.current.token))
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    throw new Error(error);
                }
            })
            .finally(() => setIsLoading(false));
    }, [sort, pagination]);

    const handleSearch = (data) => {
        setSearch(data.search || null);
    }

    useEffect(() => {
        getData();
    }, [getData]);

    const onSearch = useCallback(() => {
        getData(search);
    }, [getData, search]);

    const handleClearSearch = useCallback(() => {
        getData('');
        setValue('search', '');
        setSearch('');
    }, [getData, search]);

    const onTabChange = (event, value) => {
        setSelectedTab(value);
    }

    return (
        <Box style={{ height: 'calc(100vh - 96px)', display: 'flex', flexDirection: 'column' }}>
            <TabContext value={selectedTab}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: 20 }}>
                        <TabList onChange={onTabChange} aria-label="reports">
                            <Tab label="PERMIT HISTORY" value="1" />
                        </TabList>
                    </Box>
                    {
                        selectedTab === "1" && (
                            <Box style={{ paddingLeft: 20 }}>
                                <Box style={{ marginTop: 20, marginBottom: 20 }}>
                                    Add a licence plate number to view the history of a permit
                                </Box>
                                <VppBtnGroup
                                    handleSearch={handleSearch}
                                    search={search}
                                    handleClearSearch={handleClearSearch}
                                    register={register}
                                    handleSubmit={handleSubmit}
                                    isSearching={isLoading}
                                    isAutocomplete
                                    showButton
                                    onButtonClick={onSearch}
                                />
                            </Box>
                        )
                    }
                </Box>
                <Box style={{ flex: 1, overflow: 'auto' }}>
                    <TabPanel value="1" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Box>
                            {isLoading ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    mt={2}
                                    alignItems="center"
                                    height="50vh"
                                >
                                    <CircularProgress color="primary" />
                                </Box>
                            ) : (
                                <DataTable
                                    data={data}
                                    columns={columns}
                                    handlePaging={setPagination}
                                    paging={paging}
                                    initialState={{
                                        sortBy: [
                                            {
                                                id: 'issue_date',
                                                desc: true,
                                            },
                                        ],
                                        paginable: true,
                                    }}
                                    sortingChanged={setSort}
                                    sort={sort}
                                    sortableColumns={sortableColumns}
                                    title="Activities"
                                />
                            )}
                            {!data.length && search ? (
                                <Typography variant="h5">No results</Typography>
                            ) : null}
                        </Box>
                    </TabPanel>
                </Box>
            </TabContext>
        </Box>
    );
};

export default ReportsPage;
