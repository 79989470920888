import { Box, Typography } from '@material-ui/core';
import React from 'react';

const TowsAccordion = ({ driverName, driverNotes }) => {
  return (
    <Box>
      {driverName ? (
        <Box marginX={2} marginY={4} display="flex">
          <Typography variant="subtitle2" style={{ minWidth: 130 }}>
            Driver Name
          </Typography>
          <Typography variant="caption">{driverName}</Typography>
        </Box>
      ) : null}
      {driverNotes ? (
        <Box marginX={2} marginY={4} display="flex">
          <Typography variant="subtitle2" style={{ minWidth: 130 }}>
            Driver Notes
          </Typography>
          <Typography variant="caption">{driverNotes}</Typography>
        </Box>
      ) : null}
      {!driverName && !driverNotes ? (
        <Typography variant="subtitle2">No details available</Typography>
      ) : null}
    </Box>
  );
};

export default TowsAccordion;
