import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { DataTable } from '../../../contexts/dataTable';
import { getGuestPermits } from '../../../actions/guest.permits.actions';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import _ from 'lodash';
import axios from 'axios';

const sortableColumns = [
  'issue_date',
  'licence_plate',
  'end_time',
  'unit_number'
];

const defaultPagination = {
  page: 1,
  page_size: 20
};

const DEBOUNCE_TIME = 1500;

const GuestPermits = () => {
  const [pagination, setPagination] = useState(defaultPagination);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { data, paging } = useSelector((state) => state.guestPermits);
  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const cancelSearch = useRef(null);

  const columns = useMemo(
    () => [
      {
        id: 'unit_number',
        Header: 'Unit #',
        accessor: 'unit_number'
      },
      {
        id: 'issue_date',
        Header: 'Created Date',
        accessor: 'issue_date',
        Cell({ value }) {
          return moment(value).format('MM/DD/YYYY HH:mm:ss');
        }
      },
      {
        Header: 'Permit start time',
        id: 'start_time',
        accessor: 'start_time'
      },
      {
        Header: 'Permit end time',
        id: 'end_time',
        accessor: 'end_time'
      },
      {
        Header: 'Guest LP',
        accessor: 'licence_plate'
      },
      {
        Header: 'Tenant Name',
        accessor: 'tenant_name'
      },
      {
        Header: 'Phone number',
        accessor: 'tenant_phone'
      }
    ],
    []
  );

  const handleClearSearch = useCallback(() => {
    setValue('search', '');
    setSearch('');
  }, []);

  const getData = useCallback(() => {
    if (cancelSearch.current) {
      cancelSearch.current.cancel();
    }
    cancelSearch.current = axios.CancelToken.source();

    setIsLoading(true);

    const searchParams = {
      ...pagination,
      ...sort,
      search
    };

    dispatch(getGuestPermits(searchParams, cancelSearch.current.token)).catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
    }).finally(() => setIsLoading(false));
  }, [search, sort, pagination]);

  const handleSearch = _.debounce((data) => {
    setSearch(data.search || null);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Box mr={4} pb={4}>
      <VppBtnGroup
        handleSearch={handleSearch}
        search={search}
        handleClearSearch={handleClearSearch}
        register={register}
        handleSubmit={handleSubmit}
        isAutocomplete
      />
      {isLoading ? (
        <Box
          display='flex'
          justifyContent='center'
          mt={2}
          alignItems='center'
          height='50vh'
        >
          <CircularProgress color='primary' />
        </Box>
      ) : (
        <DataTable
          data={data}
          columns={columns}
          handlePaging={setPagination}
          paging={paging}
          initialState={{
            sortBy: [
              {
                id: 'issue_date',
                desc: true
              }
            ],
            paginable: true
          }}
          sortingChanged={setSort}
          sort={sort}
          sortableColumns={sortableColumns}
          title='Guest Permits'
        />
      )}
      {!data.length && search ? (
        <Typography variant='h5'>No results</Typography>
      ) : null}
    </Box>
  );
};

export default GuestPermits;
