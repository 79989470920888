import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBarCustom from '../components/AppBar/AppBar';
import DrawerCustom from '../components/Drawer/Drawer';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  HomeIcon,
  ParkingRulesIcon,
  ReportsIcon,
  StickeredViolatorsIcon,
  TowsIcon,
  UnitsIcon,
  UsersIcon,
} from '../Icons';
import { getRules } from '../actions/rules.actions';
import { useSessionTimeout } from '../hooks/useSessionTimeout';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    top: 64,
    position: 'relative',
    width: '100%',
  },
}));

const AdminLayout = ({ children }) => {
  const rules = useSelector((state) => state.rules);
  const { allow_tenant_to_own_reserved_spots } = rules;
  const { property_info } = useSelector((store) => store.user.user);

  const classes = useStyles();
  const dispatch = useDispatch();
  useSessionTimeout();

  useEffect(() => {
    dispatch(getRules());
  }, [dispatch]);

  const menuItems = [
    {
      text: 'Units',
      icon: (props) => <UnitsIcon fontSize="small" {...props} />,
      path: '/admin/units',
    },
    {
      text: 'Guest Permits',
      icon: (props) => <UnitsIcon fontSize="small" {...props} />,
      path: '/admin/guestPermits',
    },
    {
      text: 'Tows',
      icon: (props) => <TowsIcon fontSize="small" {...props} />,
      path: '/admin/tows',
    },
    {
      text: 'Parking Rules',
      icon: (props) => <ParkingRulesIcon fontSize="small" {...props} />,
      path: '/admin/parkingRules',
    },
    {
      text: 'Users',
      icon: (props) => <UsersIcon fontSize="small" {...props} />,
      path: '/admin/users',
    },
    {
      text: 'Reports',
      icon: (props) => <ReportsIcon fontSize="small" {...props} />,
      path: '/admin/reports',
      divider: true,
    },
    {
      text: 'Property Profile',
      icon: (props) => <HomeIcon fontSize="small" {...props} />,
      path: '/admin/propertyProfile',
    },
  ];

  if (property_info.omadi_db_id !== 0) {
    menuItems.splice(3, 0, {
      text: 'Stickered violators',
      icon: (props) => <StickeredViolatorsIcon fontSize="small" {...props} />,
      path: '/admin/stickered',
    });
  }
  if (allow_tenant_to_own_reserved_spots) {
    menuItems.splice(2, 0, {
      text: 'Reserved Spots',
      icon: (props) => <ParkingRulesIcon fontSize="small" {...props} />,
      path: '/admin/reservedSpots',
    });
  }

  return (
    <div className={classes.root}>
      <AppBarCustom />
      <DrawerCustom menuItems={menuItems} />
      <Box my={4} ml={4} className={classes.content}>
        {children}
      </Box>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AdminLayout;
