import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App.js';
import {store} from './store';
import * as Sentry from "@sentry/react";
import {createBrowserHistory} from 'history';

const appRoot = document.getElementById('root');
appRoot.setAttribute('notranslate', true);

const history = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_ENV,
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({history})
    ],
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
});

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </React.Fragment>,
    appRoot
);