import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Archive } from '@material-ui/icons';
import { DataTable } from '../../../contexts/dataTable';
import { getUnits } from '../../../actions/units.actions';
import Graphs from '../../../components/Graphs/Graphs';
import AddUnitsPage from './add/AddUnits';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import { useForm } from 'react-hook-form';
import ArchiveUnit from './archive/ArchiveUnit';
import StatusUnitsDropdown from '../../../components/StatusDropdown/StatusUnitsDropdown';
import axios from 'axios';
import _ from 'lodash';

const DEBOUNCE_TIME = 1500;

const UnitsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit } = useForm();
  const { data, meta } = useSelector((state) => state.units);

  const cancelSearch = useRef(null);

  const handleRowClick = (e, row) => {
    const { id } = row.original;
    if (e.target.tagName === 'TD') {
      history.push(`/admin/units/${id}`);
    }
  };

  const handleClearSearch = useCallback(() => {
    setValue('search', '');
    setSearch('');
  }, []);

  const handleSearch = _.debounce((data) => {
    setSearch(data.search || null);
  }, DEBOUNCE_TIME);

  const getData = useCallback(() => {
    if (cancelSearch.current) {
      cancelSearch.current.cancel();
    }
    cancelSearch.current = axios.CancelToken.source();

    const searchParams = { search };

    setIsLoading(true);
    dispatch(getUnits(searchParams, cancelSearch.current.token)).catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
    }).finally(() => setIsLoading(false))
  }, [search]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSuccess = useCallback(() => {
    handleClearSearch();
    getData();
  }, [handleClearSearch, getData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Unit #',
        accessor: 'unit_number',
      },
      {
        Header: 'Customer name',
        accessor: 'customerName',
        Cell({ row }) {
          const { first_name, last_name } = row.original;
          return `${first_name} ${last_name}`;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone number',
        accessor: 'phone_number',
      },
      {
        Header: 'LP',
        accessor: 'licence_plate',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell({ row }) {
          return (
            <StatusUnitsDropdown
              id={row.original.id}
              statusChip={row.original.status}
            />
          );
        },
      },
      {
        Header: '',
        id: 'actions',
        Cell({ row }) {
          return (
            <ArchiveUnit
              unitStatus={row.original.status}
              unitId={row.original.id}
              renderOpen={(handleClickOpen) => (
                <Tooltip title="Archive">
                  <IconButton onClick={handleClickOpen}>
                    <Archive />
                  </IconButton>
                </Tooltip>
              )}
              handleSuccess={handleClearSearch}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Box mr={4} pb={4}>
      <Graphs meta={meta} />
      <VppBtnGroup
        handleSearch={handleSearch}
        search={search}
        handleClearSearch={handleClearSearch}
        register={register}
        handleSubmit={handleSubmit}
        isAutocomplete
      >
        <AddUnitsPage handleSuccess={handleSuccess} />
      </VppBtnGroup>
      {isLoading ? (
        <Box
          display='flex'
          justifyContent='center'
          mt={2}
          alignItems='center'
          height='50vh'
        >
          <CircularProgress color='primary' />
        </Box>
      ) : (
        <DataTable
          data={data}
          columns={columns}
          initialState={{
            sortBy: [
              {
                id: 'unit_number',
                desc: false,
              }
            ]}}
          title="All units"
          handleRowClick={handleRowClick}
        />
      )}
      {!data.length && search ? (
        <Typography variant="h5">No results</Typography>
      ) : null}
    </Box>
  );
};

export default UnitsPage;
