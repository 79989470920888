import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Box } from '@material-ui/core';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#CF1844',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#CF1844',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#CF1844',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#CF1844',
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '20%',
      right: '22%',
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      color: '#CF1844',
      borderStyle: 'solid',
      borderWidth: 1,
      backgroundColor: '#CF1844',
    },
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={classes.root}>
      {active || completed ? (
        <div className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({}));

const CustomizedStepper = ({ stepperBody, activeSteps }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeSteps}
        connector={<QontoConnector />}
      >
        {stepperBody.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CustomizedStepper;
