import React  from 'react';
import {
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  AppBar,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: 64,
    backgroundColor: '#E1E8F1',
    boxShadow: '5px 4px 35px rgba(0, 0, 0, 0.1)',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      minHeight: 56,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: 230,
  },
  toolBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

const AppBarCustom = ({ title, handleDrawerToggle }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const { user } = useSelector((state) => state.user);
  const userInfo = user.user_info;

  const menuItems = {
    path: '/admin/userProfile',
  };

  const handleMenuClick = (path) => history.push(path);

  return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBarContainer}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <Box display="flex" alignItems="center">
                <img src="/logo.svg" className={classes.logo} />
              </Box>
            </Hidden>
            {title ? (
                <Hidden smUp implementation="css">
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                </Hidden>
            ) : null}
            <Hidden xsDown implementation="css">
              <Box display="flex" alignItems="center">
                <Typography>
                  {userInfo.first_name} {userInfo.last_name}
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={() => handleMenuClick(menuItems.path)}
                >
                  <AccountCircleIcon />
                </IconButton>
              </Box>
            </Hidden>
          </Toolbar>
        </AppBar>
      </>
  );
};

export default AppBarCustom;
