import {Box, Button, Grid, Hidden, TextField, Typography} from "@material-ui/core";
import CustomModal from "../../../../components/modal/CustomModal";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {setLoading} from "../../../../actions/system.actions";
import {resendInvitationUnit} from "../../../../actions/units.actions";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {resendActivationLinkSchema} from "../../../../validators";
import {z} from "zod";
import {formatValidationErrors} from "../../../../utils";

const ResendActivationLink = ({unitId, newActivationData, onSuccess}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [fieldErrors, setFieldErrors] = useState({});

    const modalRef = useRef();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [isSendingActivationLink, setIsSendingActivationLink] = useState(false);

    const resetForm = useCallback(() => {
        if (newActivationData) {
            setFirstName(newActivationData.first_name ?? '')
            setLastName(newActivationData.last_name ?? '')
            setPhoneNumber(newActivationData.phone_number ?? '')
            setEmail(newActivationData.email ?? '')
        }
    }, [newActivationData])

    useEffect(() => {
        resetForm()
    }, [resetForm])

    const onSendActivationLinkSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        setFieldErrors({});

        try {
            const data = {
                email: email?.trim(),
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                action: 'resend',
            }
            resendActivationLinkSchema.parse(data);

            dispatch(setLoading(true));
            dispatch(
                resendInvitationUnit(
                    data,
                    unitId
                )
            )
                .then(() => {
                    onSuccess();
                    modalRef.current.close();
                    setIsSendingActivationLink(false);
                    enqueueSnackbar('Activation Link resent succesfully', {
                        variant: 'success',
                    });
                })
                .catch(({response}) => {
                    const errors = Object.keys(response.data).map((key) => ({
                        [key]: response.data[key],
                    }));
                    setFieldErrors(...errors);
                    if (response.status === 404) {
                        alert(
                            'This unit seems to not have an invitation to resend or was previously accepted'
                        );
                    }
                    dispatch(setLoading(false));
                }).finally(() => dispatch(setLoading(false)))
        } catch (err) {
            if (err instanceof z.ZodError) {
                setFieldErrors(formatValidationErrors(err));
            }
        }
    }

    const handleClose = () => {
        if (isSendingActivationLink) {
            modalRef.current.close();
            setFieldErrors({});
            setIsSendingActivationLink(false);
        } else if (
            confirm('Are you sure you want to close the window without saving?')
        ) {
            resetForm();
            setFieldErrors({});
            modalRef.current.close();
        }
    };

    return <Box display="flex" alignItems="center" ml={4}>
        <Hidden xsDown>
            <CustomModal
                ref={modalRef}
                renderOpen={(handleClickOpen) =>
                    <Box ml={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={isSendingActivationLink}
                            onClick={handleClickOpen}
                        >
                            Resend Activation Link
                        </Button>
                    </Box>
                }
                onClose={handleClose}
                controlled={!isSendingActivationLink}
            >
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'center',
                                        padding: '0 1rem',
                                    }}
                                >
                                    Verify or edit the tenant contact information
                                    before sending the activation link.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="caption">
                                    First Name
                                </Typography>
                                <TextField
                                    id="firstName"
                                    autoFocus
                                    name="first_name"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    error={!!fieldErrors.first_name}
                                    helperText={fieldErrors.first_name ?? ''}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="caption">Last Name</Typography>
                                <TextField
                                    id="lastName"
                                    name="last_name"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    error={!!fieldErrors.last_name}
                                    helperText={fieldErrors.last_name ?? ''}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="caption">
                                    Phone number
                                </Typography>
                                <TextField
                                    id="phoneNumber"
                                    name="phone_number"
                                    placeholder="Phone number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    error={!!fieldErrors.phone_number}
                                    helperText={fieldErrors.phone_number ?? ''}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="caption">Email</Typography>
                                <TextField
                                    id="Email"
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!fieldErrors.email}
                                    helperText={fieldErrors.email ? fieldErrors.email[0] : ''}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        // flexDirection="column"
                        mt={5}
                    >
                        <Box>
                            <Button
                                type="buton"
                                variant="outlined"
                                color="secondary"
                                size="large"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    resetForm();
                                    setFieldErrors({});
                                    modalRef.current.close();
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={onSendActivationLinkSubmit}
                            >
                                Send Invitation
                            </Button>
                        </Box>
                    </Box>
                </div>
            </CustomModal>
        </Hidden>
    </Box>
}

export default ResendActivationLink;