import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

export const tenantLogin = (payload) => ({ type: types.TENANT_LOGIN, payload });
export const setPassword = (payload) => ({ type: types.SET_PASSWORD, payload });
const setRegistrationContactInfo = (payload) => ({
  type: types.REGISTRATION_CONTACT_INFO,
  payload,
});
const setPdfInfo = (payload) => ({
  type: types.REGISTRATION_CONTACT_INFO,
  payload,
});

const setTenantVehicles = (payload) => ({
  type: types.TENANT_SET_VEHICLES,
  payload,
});

const addTenantVehicle = (payload) => ({
  type: types.TENANT_ADD_VEHICLE,
  payload,
});

const setTenantVehicle = (payload) => ({
  type: types.TENANT_UPDATE_VEHICLE,
  payload,
});

export const getRegistrationContactInfo = (key) => (dispatch) =>
  vpp.get(VPP_API.registrationConfirmInfo(key)).then(({ data }) => {
    dispatch(setRegistrationContactInfo(data));
  });

export const pdfRulesInfo = (key) => (dispatch) =>
  vpp.get(VPP_API.getPdfRulesInfo(key)).then(({ data }) => {
    dispatch(setPdfInfo(data));
  });

export const sendTenantInfo = (key, email, phoneNumber) => (dispatch) =>
  vpp.post(VPP_API.postTenantInfo(key), { email, phone_number: phoneNumber });

export const sendFinishInfo = (key, password, password2, user_signature) => (
  dispatch
) =>
  vpp.post(VPP_API.postFinishInfo(key), {
    password,
    password2,
    user_signature,
  });

export const sendPasswordValidation = (key, password, password2) => (
  dispatch
) => vpp.post(VPP_API.postPasswordValidation(key), { password, password2 });

export const getTenantVehicles = () => (dispatch) =>
  vpp.get(VPP_API.tenantVehicles).then(({ data }) => {
    dispatch(setTenantVehicles(data));
  });

export const updateTenantVehicle = (id, data) => (dispatch) =>
  vpp.patch(VPP_API.tenantVehicle(id), { ...data }).then(({ data }) => {
    dispatch(setTenantVehicle(data));
  });

export const createTenantVehicle = (data) => (dispatch) =>
  vpp.post(VPP_API.tenantVehicles, { ...data }).then(({ data }) => {
    dispatch(addTenantVehicle(data));
  });
