import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useDataTable } from '../../contexts/dataTable';

const DataTableBody = () => {
  const {
    getTableBodyProps,
    prepareRow,
    rows,
    visibleColumns,
    renderRowSubComponent,
    handleRowClick,
  } = useDataTable();

  return (
    <TableBody {...getTableBodyProps()}>
      {rows.map((row, index) => {
        prepareRow(row);
        return (
          <React.Fragment key={index}>
            <TableRow
              {...row.getRowProps()}
              hover={!!handleRowClick}
              selected={row.isExpanded}
              onClick={(e) => handleRowClick && handleRowClick(e, row)}
            >
              {row.cells.map((cell, index) => {
                return (
                  <TableCell {...cell.getCellProps()} key={index}>
                    {cell.isAggregated
                      ? cell.render('Aggregated')
                      : cell.isPlaceholder
                      ? null
                      : cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
            {row.isExpanded && renderRowSubComponent ? (
              <TableRow>
                <TableCell colSpan={visibleColumns.length}>
                  {renderRowSubComponent({ row })}
                </TableCell>
              </TableRow>
            ) : null}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
