import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const UnitsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M5.33333 7.75H18.6667V17.25H5.33333V7.75ZM22 12.5C22 13.8117 22.8954 14.875 24 14.875V19.625C24 20.9367 23.1046 22 22 22H2C0.895417 22 0 20.9367 0 19.625V14.875C1.10458 14.875 2 13.8117 2 12.5C2 11.1883 1.10458 10.125 0 10.125V5.375C0 4.06331 0.895417 3 2 3H22C23.1046 3 24 4.06331 24 5.375V10.125C22.8954 10.125 22 11.1883 22 12.5ZM20 7.35417C20 6.69832 19.5523 6.16667 19 6.16667H5C4.44771 6.16667 4 6.69832 4 7.35417V17.6458C4 18.3017 4.44771 18.8333 5 18.8333H19C19.5523 18.8333 20 18.3017 20 17.6458V7.35417Z" />
  </SvgIcon>
);

export const ReportsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M9 17H7v-5h2zm4 0h-2v-3h2zm0-5h-2v-2h2zm4 5h-2V7h2z"/>
  </SvgIcon>
)

export const HomeIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2001 12.9438C23.909 12.9486 24.2674 12.0866 23.7661 11.5823L12.4847 0.233582C12.1751 -0.0778608 11.6731 -0.0778608 11.3635 0.233582L0.233748 11.4298C-0.263846 11.9304 0.155503 12.8628 0.859194 12.8676H2.42686V22.8206C2.42686 23.4719 2.96464 23.9999 3.62807 23.9999H8.35934V24H15.3706V23.9999H20.1021C20.7655 23.9999 21.3034 23.4719 21.3033 22.8206V12.9309L23.2001 12.9438ZM15.3706 23.9999H8.35934V17.1579C8.35934 16.2395 9.10257 15.4951 10.0195 15.4951H13.7105C14.6274 15.4951 15.3707 16.2395 15.3706 17.1579V23.9999Z"
    />
  </SvgIcon>
);

export const TenantHomeIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20.5 16.8229V16.8229C20.5001 17.2098 20.1983 17.5 19.8547 17.5H4.14544C3.80173 17.5 3.5 17.2098 3.5 16.8229V6C3.5 5.72386 3.72386 5.5 4 5.5H20C20.2761 5.5 20.5 5.72386 20.5 6V16.8229Z" />
    <rect x="4" y="6" width="16" height="2" fill="#D4D6DD" />
    <rect x="13" y="12" width="6" height="3" fill="#D4D6DD" />
    <rect x="5" y="12" width="6" height="3" fill="#D4D6DD" />
    <rect x="8" y="9" width="8" height="2" fill="#D4D6DD" />
  </SvgIcon>
);

export const VehicleIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.45182 8.64909L2.85 7.45455H2.18182C1.57909 7.45455 1.09091 6.96636 1.09091 6.36364C1.09091 5.76091 1.57909 5.27273 2.18182 5.27273C2.85 5.27273 3.57709 5.27273 3.57709 5.27273C3.57709 5.27273 4.09745 3.71164 4.41982 2.74619C4.56818 2.30055 4.98491 2 5.45455 2C8.00018 2 15.9998 2 18.5455 2C19.0151 2 19.4318 2.30055 19.5802 2.74619L20.4229 5.27273H21.8182C22.4209 5.27273 22.9091 5.76091 22.9091 6.36364C22.9091 6.96636 22.4209 7.45455 21.8182 7.45455H21.15L21.5482 8.64909C22.9582 9.01346 24 10.2942 24 11.8182V21.6364C24 22.2391 23.5118 22.7273 22.9091 22.7273C22.2425 22.7273 21.3938 22.7273 20.7273 22.7273C20.1245 22.7273 19.6364 22.2391 19.6364 21.6364V20.5455H4.36364V21.6364C4.36364 22.2391 3.87545 22.7273 3.27273 22.7273C2.60618 22.7273 1.75745 22.7273 1.09091 22.7273C0.488182 22.7273 0 22.2391 0 21.6364C0 19.5615 0 14.972 0 11.8182C0 10.2942 1.04182 9.01346 2.45182 8.64909ZM4.36364 12.9091C4.96636 12.9091 5.45455 13.3973 5.45455 14C5.45455 14.6027 4.96636 15.0909 4.36364 15.0909C3.41182 15.0909 2.18182 15.0909 2.18182 15.0909V18.3636H5.87128C5.87128 18.3636 7.15309 15.8005 7.75145 14.6033C7.93636 14.2334 8.31382 14 8.72727 14C10.2185 14 13.7815 14 15.2727 14C15.6862 14 16.0636 14.2334 16.2485 14.6033C16.8469 15.8005 18.1287 18.3636 18.1287 18.3636H21.8182V15.0909H19.6364C19.0336 15.0909 18.5455 14.6027 18.5455 14C18.5455 13.3973 19.0336 12.9091 19.6364 12.9091C20.5882 12.9091 21.8182 12.9091 21.8182 12.9091V11.8182C21.8182 11.2155 21.33 10.7273 20.7273 10.7273C17.2898 10.7273 6.71018 10.7273 3.27273 10.7273C2.67 10.7273 2.18182 11.2155 2.18182 11.8182V12.9091H4.36364ZM14.5985 16.1818H9.40145L8.31054 18.3636H15.6895L14.5985 16.1818ZM6.24109 4.18182L4.78636 8.54545H19.2136L17.7589 4.18182H6.24109Z"
    />
  </SvgIcon>
);

export const TenantPermitsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M7.55556 9.66667H16.4444V15H7.55556V9.66667ZM18.6667 12.3333C18.6667 13.0697 19.2636 13.6667 20 13.6667V16.3333C20 17.0697 19.4031 17.6667 18.6667 17.6667H5.33333C4.59694 17.6667 4 17.0697 4 16.3333V13.6667C4.73639 13.6667 5.33333 13.0697 5.33333 12.3333C5.33333 11.5969 4.73639 11 4 11V8.33333C4 7.59694 4.59694 7 5.33333 7H18.6667C19.4031 7 20 7.59694 20 8.33333V11C19.2636 11 18.6667 11.5969 18.6667 12.3333ZM17.3333 9.44444C17.3333 9.07625 17.0349 8.77778 16.6667 8.77778H7.33333C6.96514 8.77778 6.66667 9.07625 6.66667 9.44444V15.2222C6.66667 15.5904 6.96514 15.8889 7.33333 15.8889H16.6667C17.0349 15.8889 17.3333 15.5904 17.3333 15.2222V9.44444Z" />
  </SvgIcon>
);

export const TenantGuestPermitsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 12C14.2094 12 16 10.2094 16 8C16 5.79063 14.2094 4 12 4C9.79063 4 8 5.79063 8 8C8 10.2094 9.79063 12 12 12ZM14.9937 13.0188L13.5 19L12.5 14.75L13.5 13H10.5L11.5 14.75L10.5 19L9.00625 13.0188C6.77812 13.125 5 14.9469 5 17.2V18.5C5 19.3281 5.67188 20 6.5 20H17.5C18.3281 20 19 19.3281 19 18.5V17.2C19 14.9469 17.2219 13.125 14.9937 13.0188Z" />
  </SvgIcon>
);

export const TowsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M15.6619 16.6936C16.2392 18.2591 16.5 22.5 12.7206 23.8236C7 25 5.84843 19.8247 5.84843 18.6505V17.8677C5.84843 16.6936 6.61812 15.9108 7.77265 15.9108C8.92718 15.9108 9.69687 16.6936 9.69687 17.8677V18.8462C9.69687 19.4333 10.2741 20.0204 10.8514 20.0204C11.4287 20.0204 11.8135 19.8955 12.0059 19.6998C12.1984 19.5041 12.1984 18.8462 12.0059 18.2591L9.88929 12.7797C9.69687 12.584 9.69687 12.1926 9.69687 11.9969V8.08302H7.77265C7.19538 8.08302 6.61812 7.88733 6.23328 7.30024L2.38484 3.81178C2.19242 3.42039 2 3.02901 2 2.63762V1.95694C2 0.782776 2.76969 0 3.92422 0H19.318C20.4725 0 21.2422 0.782776 21.2422 1.95694V2.63762C21.2422 3.02901 21.0497 3.42039 21.0497 3.81178L17.2013 7.30024C16.8165 7.69163 16.2392 8.08302 15.6619 8.08302H13.7377V11.6055L15.6619 16.6936Z" />
  </SvgIcon>
);

export const StickeredViolatorsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M5.25281 21.2976C5.25328 21.5925 5.34047 21.8812 5.50406 22.1269L6.30516 23.3311C6.44201 23.5369 6.62762 23.7058 6.84549 23.8226C7.06335 23.9393 7.30671 24.0005 7.55391 24.0005H10.4466C10.6938 24.0005 10.9371 23.9393 11.155 23.8226C11.3728 23.7058 11.5585 23.5369 11.6953 23.3311L12.4964 22.1269C12.6599 21.8812 12.7473 21.5928 12.7477 21.2976L12.7495 19.5H5.25047L5.25281 21.2976ZM0.75 8.24999C0.75 10.3298 1.52109 12.2273 2.79188 13.6772C3.56625 14.5608 4.7775 16.4067 5.23922 17.9639C5.24109 17.9761 5.2425 17.9883 5.24437 18.0005H12.7556C12.7575 17.9883 12.7589 17.9765 12.7608 17.9639C13.2225 16.4067 14.4338 14.5608 15.2081 13.6772C16.4789 12.2273 17.25 10.3298 17.25 8.24999C17.25 3.68483 13.5427 -0.0140759 8.97422 -1.34412e-05C4.1925 0.0145178 0.75 3.88921 0.75 8.24999ZM9 4.49999C6.93234 4.49999 5.25 6.18233 5.25 8.24999C5.25 8.66436 4.91438 8.99999 4.5 8.99999C4.08562 8.99999 3.75 8.66436 3.75 8.24999C3.75 5.35499 6.105 2.99999 9 2.99999C9.41438 2.99999 9.75 3.33561 9.75 3.74999C9.75 4.16436 9.41438 4.49999 9 4.49999Z" />
  </SvgIcon>
);

export const ParkingRulesIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M14.9473 9.2095C14.9473 10.1999 14.6506 10.919 14.0572 11.3666C13.4638 11.8143 12.507 12.038 11.187 12.038H9.85181V6.5333H11.5412C12.7098 6.5333 13.5697 6.7556 14.1208 7.1999C14.6717 7.6444 14.9473 8.3143 14.9473 9.2095ZM20 0C21.1046 0 22 0.895431 22 2V22C22 23.1046 21.1046 24 20 24H4C2.89543 24 2 23.1046 2 22V2C2 0.89543 2.89543 0 4 0H20ZM16.6821 9.1333C16.6821 6.4032 15.02 5.0381 11.6957 5.0381H8.18057V18.9619H9.85181V13.5429H11.3777C13.061 13.5429 14.366 13.1636 15.2924 12.4049C16.219 11.6461 16.6821 10.5556 16.6821 9.1333Z" />
  </SvgIcon>
);

export const UsersIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M3.6 11.2C4.92375 11.2 6 10.1238 6 8.8C6 7.47625 4.92375 6.4 3.6 6.4C2.27625 6.4 1.2 7.47625 1.2 8.8C1.2 10.1238 2.27625 11.2 3.6 11.2ZM20.4 11.2C21.7238 11.2 22.8 10.1238 22.8 8.8C22.8 7.47625 21.7238 6.4 20.4 6.4C19.0763 6.4 18 7.47625 18 8.8C18 10.1238 19.0763 11.2 20.4 11.2ZM21.6 12.4H19.2C18.54 12.4 17.9438 12.6663 17.5088 13.0975C19.02 13.9263 20.0925 15.4225 20.325 17.2H22.8C23.4638 17.2 24 16.6638 24 16V14.8C24 13.4763 22.9238 12.4 21.6 12.4ZM12 12.4C14.3213 12.4 16.2 10.5213 16.2 8.2C16.2 5.87875 14.3213 4 12 4C9.67875 4 7.8 5.87875 7.8 8.2C7.8 10.5213 9.67875 12.4 12 12.4ZM14.88 13.6H14.5688C13.7888 13.975 12.9225 14.2 12 14.2C11.0775 14.2 10.215 13.975 9.43125 13.6H9.12C6.735 13.6 4.8 15.535 4.8 17.92V19C4.8 19.9938 5.60625 20.8 6.6 20.8H17.4C18.3938 20.8 19.2 19.9938 19.2 19V17.92C19.2 15.535 17.265 13.6 14.88 13.6ZM6.49125 13.0975C6.05625 12.6663 5.46 12.4 4.8 12.4H2.4C1.07625 12.4 0 13.4763 0 14.8V16C0 16.6638 0.53625 17.2 1.2 17.2H3.67125C3.9075 15.4225 4.98 13.9263 6.49125 13.0975Z" />
  </SvgIcon>
);

export const LogOutIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16.5714 18.8571C16.3441 18.8571 16.1261 18.9474 15.9653 19.1082C15.8046 19.2689 15.7143 19.487 15.7143 19.7143V22.2857H3.71429V1.71429H15.7143V4.28571C15.7143 4.51304 15.8046 4.73106 15.9653 4.89181C16.1261 5.05255 16.3441 5.14286 16.5714 5.14286C16.7988 5.14286 17.0168 5.05255 17.1775 4.89181C17.3383 4.73106 17.4286 4.51304 17.4286 4.28571V0.857143C17.4286 0.629814 17.3383 0.411797 17.1775 0.251051C17.0168 0.0903058 16.7988 0 16.5714 0H2.85714C2.62981 0 2.4118 0.0903058 2.25105 0.251051C2.09031 0.411797 2 0.629814 2 0.857143V23.1429C2 23.3702 2.09031 23.5882 2.25105 23.7489C2.4118 23.9097 2.62981 24 2.85714 24H16.5714C16.7988 24 17.0168 23.9097 17.1775 23.7489C17.3383 23.5882 17.4286 23.3702 17.4286 23.1429V19.7143C17.4286 19.487 17.3383 19.2689 17.1775 19.1082C17.0168 18.9474 16.7988 18.8571 16.5714 18.8571Z" />
    <path d="M6.28572 12.8571C6.7591 12.8571 7.14286 12.4734 7.14286 12C7.14286 11.5266 6.7591 11.1429 6.28572 11.1429C5.81233 11.1429 5.42857 11.5266 5.42857 12C5.42857 12.4734 5.81233 12.8571 6.28572 12.8571Z" />
    <path d="M22.5114 12.3087C22.588 12.1101 22.588 11.8901 22.5114 11.6915C22.4966 11.6571 22.4763 11.6253 22.4514 11.5972C22.4371 11.5503 22.4138 11.5067 22.3828 11.4687L18.9543 7.18295C18.8129 7.00633 18.6073 6.8928 18.3825 6.86711C18.1577 6.84142 17.9319 6.90565 17.7543 7.0458C17.6656 7.11592 17.5916 7.20289 17.5367 7.3017C17.4818 7.40051 17.4469 7.50921 17.4342 7.62155C17.4214 7.73388 17.431 7.84763 17.4624 7.95624C17.4938 8.06485 17.5464 8.16617 17.6171 8.25438L19.9314 11.1429H13.1428C12.9155 11.1429 12.6975 11.2333 12.5368 11.394C12.376 11.5547 12.2857 11.7728 12.2857 12.0001C12.2857 12.2274 12.376 12.4454 12.5368 12.6062C12.6975 12.7669 12.9155 12.8572 13.1428 12.8572H19.9314L17.6171 15.7544C17.477 15.932 17.4128 16.1578 17.4384 16.3826C17.4641 16.6074 17.5777 16.8129 17.7543 16.9544C17.9051 17.0754 18.0924 17.1418 18.2857 17.1429C18.4138 17.1434 18.5403 17.1151 18.656 17.0602C18.7717 17.0054 18.8736 16.9252 18.9543 16.8258L22.3828 12.5401C22.4124 12.4981 22.4355 12.4518 22.4514 12.4029C22.4763 12.3749 22.4966 12.3431 22.5114 12.3087V12.3087Z" />
  </SvgIcon>
);

export const StatusDotsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M10.5 16.5C10.5 17.325 11.175 18 12 18C12.825 18 13.5 17.325 13.5 16.5C13.5 15.675 12.825 15 12 15C11.175 15 10.5 15.675 10.5 16.5ZM10.5 7.5C10.5 8.325 11.175 9 12 9C12.825 9 13.5 8.325 13.5 7.5C13.5 6.675 12.825 6 12 6C11.175 6 10.5 6.675 10.5 7.5ZM10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5C11.175 10.5 10.5 11.175 10.5 12Z" />
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" />
  </SvgIcon>
);

export const GuestsPermits = (props) => (
  <SvgIcon {...props}>
    <rect
      width="5.46296"
      height="4.21053"
      transform="matrix(-1 0 0 1 59.0004 27.7895)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="26.1053"
      transform="matrix(-1 0 0 1 51.3523 5.89473)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="16.8421"
      transform="matrix(-1 0 0 1 43.7042 15.1579)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="22.7368"
      transform="matrix(-1 0 0 1 36.0561 9.26315)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="32"
      transform="matrix(-1 0 0 1 28.408 0)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="12.6316"
      transform="matrix(-1 0 0 1 20.7599 19.3684)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="6.73684"
      transform="matrix(-1 0 0 1 13.1118 25.2632)"
      fill="#4285F4"
    />
    <rect
      width="5.46296"
      height="22.7368"
      transform="matrix(-1 0 0 1 5.46295 9.26315)"
      fill="#4285F4"
    />
  </SvgIcon>
);
export const TenantsPermits = (props) => (
  <SvgIcon {...props}>
    <rect y="27.7891" width="5.46296" height="4.21053" fill="#1358A0" />
    <rect
      x="7.64844"
      y="5.89453"
      width="5.46296"
      height="26.1053"
      fill="#1358A0"
    />
    <rect
      x="15.2959"
      y="15.1582"
      width="5.46296"
      height="16.8421"
      fill="#1358A0"
    />
    <rect
      x="22.9443"
      y="9.2627"
      width="5.46296"
      height="22.7368"
      fill="#1358A0"
    />
    <rect x="30.5923" width="5.46296" height="32" fill="#1358A0" />
    <rect
      x="38.2402"
      y="19.3682"
      width="5.46296"
      height="12.6316"
      fill="#1358A0"
    />
    <rect
      x="45.8887"
      y="25.2627"
      width="5.46296"
      height="6.73684"
      fill="#1358A0"
    />
    <rect
      x="53.5371"
      y="9.2627"
      width="5.46296"
      height="22.7368"
      fill="#1358A0"
    />
  </SvgIcon>
);
