import React from 'react';
import PropertyLayout from '../../layouts/Property';
import Box from '@material-ui/core/Box';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: theme.spacing(3, 0),
  },
  buttonPrimaryDark: {
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: fade('#1358A0', 0.8),
    },
  },
}));

const TenantHome = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const user = useSelector((store) => store.user);
  const {
    user: { property_info },
  } = user;
  return (
    <Box position="relative" mx={-4} my={-5}>
      <PropertyLayout originalProperty={property_info}>
        <Box mt={15} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              onClick={() => {
                history.push('/tenant/myPermits');
              }}
              size="large"
              className={classes.buttonPrimaryDark}
              fullWidth
            >
              My Permits
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/tenant/guestPermits');
              }}
              size="large"
              className={classes.buttonRoot}
              fullWidth
            >
              Guest Permits
            </Button>
          </Grid>
        </Grid>
      </PropertyLayout>
    </Box>
  );
};

export default TenantHome;
