import * as types from '../actions/types';

const defaultState = {
  data: [],
};

const reservedSpotsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_RESERVED_SPOTS:
      return {
        ...state,
        data: [...action.payload.data],
        paging: { ...action.payload.paging }
      };
    default:
      return state;
  }
};

export default reservedSpotsReducer;
