import * as types from '../actions/types';

const defaultState = {
  loggedIn: false,
  password: '',
  password2: '',
  addendum_text: '',
  meta: { available_tenants_slots: 0, available_guests_slots: 0 },
  tenantVehicles: [],
  guestVehicles: [],
};

const tenantReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.PDF_RULES_INFO:
    case types.SET_PASSWORD:
    case types.REGISTRATION_CONTACT_INFO:
      return {
        ...state,
        ...payload,
      };
    case types.TENANT_SET_VEHICLES:
      return {
        ...state,
        meta: action.payload.meta ? action.payload.meta : state.meta,
        tenantVehicles: [...action.payload.result],
      };
    case types.GUEST_SET_VEHICLES:
      return {
        ...state,
        meta: action.payload.meta ? action.payload.meta : state.meta,
        guestVehicles: action.payload.result ? [...action.payload.result] : [],
      };
    case types.TENANT_ADD_VEHICLE:
      return {
        ...state,
        tenantVehicles: [...state.tenantVehicles, action.payload],
      };
    case types.GUEST_ADD_VEHICLE:
      return {
        ...state,
        guestVehicles: [...state.guestVehicles, action.payload],
      };
    case types.TENANT_UPDATE_VEHICLE:
      return {
        ...state,
        tenantVehicles: state.tenantVehicles.map((vehicle) =>
          action.payload.id === vehicle.id ? action.payload : vehicle
        ),
      };
    case types.GUEST_UPDATE_VEHICLE:
      return {
        ...state,
        guestVehicles: state.guestVehicles.map((vehicle) =>
          action.payload.id === vehicle.id ? action.payload : vehicle
        ),
      };
    default:
      return state;
  }
};

export default tenantReducer;
