import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setGuestVehicles = (payload) => ({
  type: types.GUEST_SET_VEHICLES,
  payload,
});

const addGuestVehicle = (payload) => ({
  type: types.GUEST_ADD_VEHICLE,
  payload,
});

const setGuestVehicle = (payload) => ({
  type: types.GUEST_UPDATE_VEHICLE,
  payload,
});

export const getGuestVehicles = () => (dispatch) =>
  vpp.get(VPP_API.guestVehicles).then(({ data }) => {
    dispatch(setGuestVehicles(data));
  });

export const getGuestArchiveVehicles = () => (dispatch) =>
  vpp.get(VPP_API.guestArchive).then(({ data }) => {
    dispatch(setGuestVehicles(data));
  });

export const updateGuestVehicle = (id, data) => (dispatch) =>
  vpp.patch(VPP_API.guestVehicle(id), { ...data }).then(({ data }) => {
    dispatch(setGuestVehicle(data));
  });

export const createGuestVehicle = (data) => (dispatch) =>
  vpp.post(VPP_API.guestVehicles, { ...data }).then(({ data }) => {
    dispatch(addGuestVehicle(data));
  });
