import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setGuestPermits = (payload) => ({
  type: types.SET_GUEST_PERMITS,
  payload,
});

export const getGuestPermits =
  (params, cancelToken = null) =>
  (dispatch) => {
    let config = {
      params,
      cancelToken,
    };
    return vpp.get(VPP_API.guestPermits, config).then(({ data }) => {
      dispatch(setGuestPermits(data));
    });
  };
