import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import CustomModal from '../../../../components/modal/CustomModal';
import VppSelect from '../../../../components/select/VppSelect';
import { createUser } from '../../../../actions/user.actions';
import { useSnackbar } from 'notistack';

const AddUserPage = ({ handleSuccess }) => {
  const modalRef = useRef();
  const { register, handleSubmit, control } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { role } = useSelector((store) => store.user);

  const disabled = role !== 'Admin';

  const onSubmit = useCallback((data) => {
    setLoading(true);
    setFieldErrors({});
    dispatch(createUser(data))
      .then(() => {
        enqueueSnackbar('New user was added successfully', {
          variant: 'success',
        });
        modalRef.current.close();
        handleSuccess();
      })
      .catch(({ response }) => {
        const { data } = response;
        setFieldErrors(data);
        enqueueSnackbar(
          'Error adding new user. Please make sure all fields are filled in correctly.',
          {
            variant: 'error',
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    if (confirm('Are you sure you want to close the window without saving?')) {
      setIsOpen(false);
      setFieldErrors({});
      modalRef.current.close();
    }
  };

  const roleSelect = ['Admin', 'Restricted'].map((i) => ({
    name: i,
    value: i,
  }));

  const statusSelect = ['active', 'blocked'].map((i) => ({
    name: i,
    value: i,
  }));
  return (
    <CustomModal
      titleText="Add New User"
      renderOpen={(handleClickOpen) => (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleClickOpen}
          disabled={disabled}
        >
          ADD USER
        </Button>
      )}
      ref={modalRef}
      onOpen={handleOpen}
      onClose={handleClose}
      controlled
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="caption">First Name</Typography>
              <TextField
                autoFocus
                name="first_name"
                placeholder="First name"
                inputRef={register}
                error={!!fieldErrors.first_name}
                helperText={
                  fieldErrors.first_name ? fieldErrors.first_name : null
                }
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="caption">Last Name</Typography>
              <TextField
                name="last_name"
                placeholder="Last name"
                inputRef={register}
                error={!!fieldErrors.last_name}
                helperText={
                  fieldErrors.last_name ? fieldErrors.last_name : null
                }
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="caption">Email</Typography>
              <TextField
                name="email"
                placeholder="Email"
                inputRef={register}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email ? fieldErrors.email : null}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="caption">Phone number</Typography>
              <TextField
                id="phoneNumber"
                name="phone_number"
                placeholder="Phone number"
                inputRef={register}
                error={!!fieldErrors.phone_number}
                helperText={
                  fieldErrors.phone_number ? fieldErrors.phone_number : null
                }
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="caption">Role</Typography>
              <VppSelect
                name="user_role"
                direction="column"
                size="medium"
                control={control}
                options={roleSelect}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid container>
            <Box mb={4} />
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography variant="subtitle1" color="primary" noWrap>
                Status
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <VppSelect
                name="status"
                size="medium"
                control={control}
                options={statusSelect}
                direction="column"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
          >
            Send Invitation
          </Button>
        </Box>
      </form>
    </CustomModal>
  );
};

export default AddUserPage;
