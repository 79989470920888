import * as types from '../actions/types';

const defaultState = {
  data: [],
};

const StickeredReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_STICKERED:
      return {
        ...state,
        data: [...action.payload],
      };
    default:
      return state;
  }
};

export default StickeredReducer;
