import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Fab,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getPropertyBySubdomain } from '../actions/properties.actions';
import { useRouteMatch } from 'react-router';
import { HomeIcon } from '../Icons';
import { useTheme } from '@material-ui/core/styles';
import { setLoading } from '../actions/system.actions';

const useStyles = ({ property }) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    mainPageTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      '&::after': {
        content: '""',
        backgroundImage:
          property && property.image
            ? `url(${property.image})`
            : `url(/img/manager-back.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundColor: '#021426',
        opacity: 0.5,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'fixed',
        zIndex: -1,
      },
    },
    logo: {
      marginTop: theme.spacing(5),
      width: 300,
    },
    notFoundTitle: {
      fontSize: 56,
      fontWeight: 600,
    },
  }));

const PropertyLayout = ({ children, originalProperty = null }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [property, setProperty] = useState(originalProperty);
  const [loaded, setLoaded] = useState(!!originalProperty);
  const classes = useStyles({ property })(theme);
  const { params } = useRouteMatch();

  useEffect(() => {
    if (!originalProperty) {
      dispatch(setLoading(true));
      dispatch(getPropertyBySubdomain(params.propertyName))
        .then(({ data }) => {
          setProperty(data);
        })
        .finally(() => {
          dispatch(setLoading(false));
          setLoaded(true);
        });
    }
  }, [dispatch, getPropertyBySubdomain, params, originalProperty]);

  return property ? (
    <Grid container justify="center" className={classes.content}>
      {/*<Grid item>*/}
      {/*  <img src="/logo_text.svg" alt="Virtual Parking Permits" />*/}
      {/*</Grid>*/}
      <Grid item xs={12} className={classes.mainPageTitle}>
        <Fab color="secondary" size="small">
          <HomeIcon fontSize="small" />
        </Fab>
        <Box pl={2}>
          <Typography variant="h4">{property.name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {children}
      </Grid>
    </Grid>
  ) : loaded ? (
    <Box mt={5}>
      <Grid direction="row" justify="center" alignItems="center" container>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <Box display="flex" alignItems="center" flexDirection="column">
              <img src="/logo.svg" className={classes.logo} />
              <Box mt={5} mb={3}>
                <Typography color="secondary" className={classes.notFoundTitle}>
                  404
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="h5" color="primary">
                  Property not found
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  ) : (
    'Loading...'
  );
};

export default PropertyLayout;
