import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../utils';

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: theme.spacing(5),
    width: 300,
  },
}));

const ErrorPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { error } = useParams();
  const search = useQuery();
  return (
    <Box mt={5}>
      <Grid direction="row" justify="center" alignItems="center" container>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <Box display="flex" alignItems="center" flexDirection="column">
              <img src="/logo.svg" className={classes.logo} />
              <Box mt={5} mb={3}>
                <Typography color="secondary" variant="h5">
                  Something wrong
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="h6" color="primary" align='center'>
                  {Boolean(search.get('is_password_recovery_error')) && (
                    <>
                      Please, request a new link <a href={search.get('link')}>here</a> <br />
                    </>
                  )}
                  {error}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ErrorPage;
