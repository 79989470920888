import * as types from '../actions/types';

const defaultState = {
  users: [],
  status: [],
};

const listUsersReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        ...payload,
      };
    case types.DELETE_USER:
      const leftUsers = state.users.reduce((acc, item) => {
        if(item.id !== payload) {
          acc.push(item)
        }
        return acc;
      }, []);
      return{
        ...state,
        users: [...leftUsers],
      }
    case types.STATUS_SUCCESS:
      const users = state.users.reduce((acc, item) => {
        if (item.id === payload.id) {
          acc.push(payload);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      return {
        ...state,
        users,
      };
    default:
      return state;
  }
};

export default listUsersReducer;
