import React from 'react';
import PropertyLayout from '../../layouts/Property';
import { Toolbar } from '@material-ui/core';
import CardChangePassword from '../../components/Card/CardChangePassword';

const ChangePasswordPage = () => {
  return (
    <PropertyLayout>
      <Toolbar />
      <CardChangePassword />
      <Toolbar />
    </PropertyLayout>
  );
};

export default ChangePasswordPage;
