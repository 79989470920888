import React from 'react';
import PropertyLayout from '../../layouts/Property';
import { useRouteMatch } from 'react-router';
import { Button, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: theme.spacing(3, 0),
  },
}));

const PropertyLanding = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { params } = useRouteMatch();
  const history = useHistory();

  return (
    <PropertyLayout>
      <Box mt={15} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/${params.propertyName}/tenant/login`);
            }}
            size="large"
            className={classes.buttonRoot}
            fullWidth
          >
            Tenant Login
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(`/${params.propertyName}/manager/login`);
            }}
            size="large"
            className={classes.buttonRoot}
            fullWidth
          >
            Manager Login
          </Button>
        </Grid>
      </Grid>
    </PropertyLayout>
  );
};
export default PropertyLanding;
