import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';
import { refreshToken } from './user.actions';

export const sendSupport = (key) => (dispatch) =>
  vpp.post(VPP_API.officeSupport(key))
  .then(({ data }) => {
    dispatch(refreshToken(data));
    dispatch({ type: types.SET_PROPERTY, payload: data.property_info });
    dispatch({ type: types.SET_USER_PROFILE, payload: data.user_info });
  });