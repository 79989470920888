import React, { useState } from 'react';
import CardTenantRegistration from '../../../components/Card/CardTenantRegistration';
import PropertyLayout from '../../../layouts/Property';

const Register = () => {
  return (
    <>
      <PropertyLayout>
        <CardTenantRegistration />
      </PropertyLayout>
    </>
  );
};

export default Register;
