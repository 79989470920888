import React, { useState } from 'react';
import CardUserRegistration from '../../../components/Card/CardUserRegistration';
import PropertyLayout from '../../../layouts/Property';

const RegisterManager = () => {
  return (
    <>
      <PropertyLayout>
        <CardUserRegistration />
      </PropertyLayout>
    </>
  );
};

export default RegisterManager;