import { vpp, VPP_API } from '../api/vpp';
import * as types from './types';

const setRules = (payload) => ({ type: types.SET_RULES, payload });

export const getRules = () => (dispatch) =>
  vpp.get(VPP_API.parkingRules).then(({ data }) => {
    dispatch(setRules(data));
  });

export const updateRules = (data) => (dispatch) =>
  vpp.patch(VPP_API.updateParkingRules, data).then(({ data }) => {
    dispatch(setRules(data));
  });
