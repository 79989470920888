import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  updateUserProfile,
  updateUserProfilePassword,
} from '../../../actions/user.profile.action';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  statusField: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'capitalize',
    },
  },
  disabledRoot: {
    '& .MuiOutlinedInput-input': {
      color: '#06407C',
      backgroundColor: '#F7F8FC',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#06407C',
    },
  },
}));

const UserProfile = () => {
  const { register, setValue, handleSubmit, control } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const defaultData = useSelector((store) => store.userProfileReducer);
  const [disabled, setDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const theme = useTheme();
  const classes = useStyles(theme);

  const onSubmit = useCallback(
    (data) => {
      const { email, first_name, last_name, phone_number, status } = data;
      dispatch(
        updateUserProfile({
          email,
          first_name,
          last_name,
          phone_number,
          status,
        })
      )
        .then(() => {
          enqueueSnackbar('User profile saved successfully', {
            variant: 'success',
          });
          setDisabled(true);
          setValue('old_password', '');
          setValue('password', '');
          setValue('password2', '');
        })
        .catch(({ response }) => {
          const { data } = response;
          setFieldErrors(data);
          enqueueSnackbar(
            'Error saving User profile. Please make sure all fields are filled in correctly.',
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    },
    [dispatch]
  );
  const onSubmitPassword = useCallback(
    (data) => {
      const { password, password2, old_password } = data;
      dispatch(updateUserProfilePassword({ password, password2, old_password }))
        .then(() => {
          setValue('old_password', '');
          setValue('password', '');
          setValue('password2', '');
          enqueueSnackbar('User password saved successfully', {
            variant: 'success',
          });
          setDisabled(true);
        })
        .catch(({ response }) => {
          const { data } = response;
          setFieldErrors(data);
          enqueueSnackbar(
            'Error saving User password. Please make sure all fields are filled in correctly.',
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    },
    [dispatch]
  );

  const onCancel = () => {
    setDisabled(true);
    setValue('old_password', '');
    setValue('password', '');
    setValue('password2', '');
  };

  useEffect(() => {
    Object.keys(defaultData).forEach((item) => {
      setValue(item, defaultData[item]);
    });
    setFieldErrors({});
  }, [defaultData, setValue, setFieldErrors, disabled]);

  return (
    <>
      <Box mr={4} pb={6}>
        <Paper elevation={0} variant="outlined">
          <Box py={4} pl={10} pr={3}>
            <Box display="flex" justifyContent="space-between" mb={4}>
              <Box>
                <Typography variant="h6">User Profile</Typography>
              </Box>
              <Box>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={!disabled}
                  onClick={() => {
                    setDisabled(false);
                  }}
                >
                  Edit
                </Button>
              </Box>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant="body2">First name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="first_name"
                        type="text"
                        variant="outlined"
                        placeholder="First name"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.first_name}
                        helperText={
                          fieldErrors.first_name ? fieldErrors.first_name : null
                        }
                        autoFocus
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">Last name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="last_name"
                        type="text"
                        variant="outlined"
                        placeholder="Last name"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.last_name}
                        helperText={
                          fieldErrors.last_name ? fieldErrors.last_name : null
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">User Email</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="email"
                        type="text"
                        variant="outlined"
                        placeholder="example@mail.com"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.email}
                        helperText={
                          fieldErrors.email ? fieldErrors.email : null
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">Phone Number</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="phone_number"
                        type="text"
                        variant="outlined"
                        placeholder="01 234 567 89"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.phone_number}
                        helperText={
                          fieldErrors.phone_number
                            ? fieldErrors.phone_number
                            : null
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">Status</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="status"
                        type="text"
                        disabled={disabled}
                        className={clsx(classes.statusField, {
                          [classes.disabledRoot]: !disabled,
                        })}
                        inputRef={register}
                        error={!!fieldErrors.status}
                        helperText={
                          fieldErrors.status ? fieldErrors.status : null
                        }
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} />
                  {!disabled ? (
                    <Grid container>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={6}>
                        <Box mt={5} />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="large"
                              onClick={() => {
                                onCancel();
                              }}
                              fullWidth
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              type="submit"
                              disabled={disabled || submitDisabled}
                              fullWidth
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
      <Box pb={2}>
        <Paper elevation={0} variant="outlined">
          <Box py={4} pl={10} pr={4}>
            <Box mb={4}>
              <Typography variant="h6">Change Password</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmitPassword)}>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant="body2">
                        Enter previous password
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="old_password"
                        type="text"
                        variant="outlined"
                        placeholder="Password"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.old_password}
                        helperText={
                          fieldErrors.old_password
                            ? fieldErrors.old_password
                            : null
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">New Password</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="password"
                        type="text"
                        variant="outlined"
                        placeholder="Password"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.password}
                        helperText={
                          fieldErrors.password ? fieldErrors.password : null
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2">Confirm Password</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="password2"
                        type="text"
                        variant="outlined"
                        placeholder="Password"
                        disabled={disabled}
                        inputRef={register}
                        error={!!fieldErrors.password2}
                        helperText={
                          fieldErrors.password2 ? fieldErrors.password2 : null
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2} />
                  {!disabled ? (
                    <Grid container>
                      <Grid item xs={5}></Grid>
                      <Grid item xs={6}>
                        <Box mt={5} />
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              type="submit"
                              disabled={disabled || submitDisabled}
                              fullWidth
                            >
                              change password
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default UserProfile;
