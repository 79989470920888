import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { DataTable } from '../../../contexts/dataTable';
import TowsAccordion from './TowsAccordion';
import { getTows } from '../../../actions/tows.actions';
import VppBtnGroup from '../../../components/BtnGroup/VppBtnGroup';
import { setLoading } from '../../../actions/system.actions';

const ManagerTows = () => {
  const [search, setSearch] = useState('');
  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const propertyData = useSelector((store) => store.property);
  const { data } = useSelector((state) => state.tows);
  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getTows()).finally(() => {
      dispatch(setLoading(false));
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'tow_datetime',
        Cell({ value }) {
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Time',
        id: 'time',
        accessor: (row) => moment(row.tow_datetime).format('h:mm A'),
        Cell({ row }) {
          return moment(row.original.tow_datetime).format('h:mm A');
        },
      },
      {
        Header: 'Licence Plate',
        accessor: 'licence_plate',
      },
      {
        Header: 'Make',
        accessor: 'make',
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Color',
        accessor: 'color',
      },
      {
        Header: 'Driver Notes',
        accessor: 'driver_notes',
        Cell({ value }) {
          return (
            <>
              {value === 'Blocked gate' ? (
                <Typography>Blocked gate</Typography>
              ) : (
                <Typography>No permit</Typography>
              )}
            </>
          );
        },
      },
      {
        Header: '',
        id: 'actions',
        Cell({ row }) {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                row.getToggleRowExpandedProps().onClick();
              }}
            >
              {row.isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const handleRowClick = (e, row) => {
    row.getToggleRowExpandedProps().onClick();
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <TowsAccordion
        driverName={row.original.driver_name}
        driverNotes={row.original.driver_notes}
      />
    ),
    []
  );

  const handleSearch = useCallback((data) => {
    dispatch(getTows(data)).then(() => {
      setSearch(data.search);
    });
  }, []);

  const handleClearSearch = () => {
    dispatch(getTows()).then(() => {
      setSearch('');
      setValue('search', '');
    });
  };
  if (propertyData.omadi_db_id !== 0) {
    return (
      <Box mr={4} pb={4}>
        <VppBtnGroup
          handleSearch={handleSearch}
          search={search}
          handleClearSearch={handleClearSearch}
          register={register}
          handleSubmit={handleSubmit}
          isAutocomplete
        />
        <DataTable
          data={data}
          columns={columns}
          initialState={{
            sortBy: [
              { id: 'tow_datetime', desc: true },
              { id: 'time', desc: true },
            ],
          }}
          title="Tows"
          renderRowSubComponent={renderRowSubComponent}
          handleRowClick={handleRowClick}
        />
        {!data.length && search ? (
          <Typography variant="h5">No results</Typography>
        ) : null}
      </Box>
    );
  }
  return (
    <Box mr={4} pb={4}>
      <Typography variant="h5" align="center">
        {' '}
        Log in to your TowBook account to View towed vehicle information <br />
        <a
          href="https://app.towbook.com/Security/Login.aspx?small=1"
          target="_blank"
          rel="noreferrer"
        >
          Towbook app login
        </a>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          height: '100%',
          padding: '20px 0px 0px 0px',
          'tet-align': 'center',
        }}
      >
        <a
          href="https://app.towbook.com/Security/Login.aspx?small=1"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/towbook-login.png" alt="Towbook login page image" />
        </a>
      </Box>
    </Box>
  );
};

export default ManagerTows;
