import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    makeStyles,
    useTheme,
    InputAdornment,
    IconButton,
    Hidden,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import {
    updateUserProfile,
    updateUserProfilePassword,
} from '../../actions/user.profile.action';
import PropertyName from '../../components/PropertyName/PropertyName';
import {editTenantSchema} from "../../validators";
import {z} from "zod";
import {formatValidationErrors} from "../../utils";

const useStyles = makeStyles((theme) => ({
    statusField: {
        '& .MuiOutlinedInput-input': {
            textTransform: 'capitalize',
        },
    },
    disabledRoot: {
        '& .MuiOutlinedInput-input': {
            color: '#06407C',
            backgroundColor: '#F7F8FC',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#06407C',
        },
    },
    cardSize: {
        [theme.breakpoints.up('sm')]: {
            minHeight: 590,
        },
    },
}));

const TenantProfile = () => {
    const {register, setValue, handleSubmit} = useForm();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const defaultData = useSelector((store) => store.userProfileReducer);
    const [disabled, setDisabled] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({});
    const theme = useTheme();
    const classes = useStyles(theme);
    const [readOnly, setReadOnly] = useState({
        readOnlyEmail: true,
        readOnlyPhoneNumber: true,
    });

    const handleClickChangeEmail = () => {
        setReadOnly({...readOnly, readOnlyEmail: !readOnly.readOnlyEmail});
    };
    const handleClickChangePhoneNumber = () => {
        setReadOnly({
            ...readOnly,
            readOnlyPhoneNumber: !readOnly.readOnlyPhoneNumber,
        });
    };

    const onSubmit = useCallback(
        (data) => {
            try {
                const {email, first_name, last_name, phone_number, unit_number} = data;

                editTenantSchema.parse({email, phone_number});

                dispatch(
                    updateUserProfile({
                        email,
                        first_name,
                        last_name,
                        phone_number,
                        unit_number,
                    })
                )
                    .then(() => {
                        enqueueSnackbar('Tenant profile saved successfully', {
                            variant: 'success',
                        });
                        setDisabled(true);
                        setValue('old_password', '');
                        setValue('password', '');
                        setValue('password2', '');
                    })
                    .catch(({response}) => {
                        const {data} = response;
                        setFieldErrors(data);
                        enqueueSnackbar(
                            'Error saving Tenant profile. Please make sure all fields are filled in correctly.',
                            {
                                variant: 'error',
                            }
                        );
                    });
            } catch (err) {
                if (err instanceof z.ZodError) {
                    setFieldErrors(formatValidationErrors(err));
                }
            }
        },
        [dispatch]
    );
    const onSubmitPassword = useCallback(
        (data) => {
            const {password, password2, old_password} = data;
            dispatch(updateUserProfilePassword({password, password2, old_password}))
                .then(() => {
                    setValue('old_password', '');
                    setValue('password', '');
                    setValue('password2', '');
                    enqueueSnackbar('Tenant password saved successfully', {
                        variant: 'success',
                    });
                    setDisabled(true);
                })
                .catch(({response}) => {
                    const {data} = response;
                    setFieldErrors(data);
                    enqueueSnackbar(
                        'Error saving Tenant password. Please make sure all fields are filled in correctly.',
                        {
                            variant: 'error',
                        }
                    );
                });
        },
        [dispatch]
    );

    useEffect(() => {
        Object.keys(defaultData).forEach((item) => {
            setValue(item, defaultData[item]);
        });
        setFieldErrors({});
    }, [defaultData, setValue, setFieldErrors, disabled]);

    return (
        <>
            <Hidden smUp>
                <Box mt={-3} ml={-1}>
                    <PropertyName/>
                </Box>
            </Hidden>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box p={2}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            className={classes.cardSize}
                        >
                            <Box py={6} px={4}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={10}>
                                            <Box mb={5}>
                                                <Typography color="primary" variant="h6">
                                                    My Contact Information
                                                </Typography>
                                            </Box>
                                            <Box mt={1} mb={3}>
                                                <Typography variant="body2">Unit Number</Typography>
                                                <TextField
                                                    name="unit_number"
                                                    type="text"
                                                    variant="outlined"
                                                    inputRef={register}
                                                    error={!!fieldErrors.unit_number}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={
                                                        fieldErrors.unit_number
                                                            ? fieldErrors.unit_number
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={1} mb={3}>
                                                <Typography variant="body2">First name</Typography>
                                                <TextField
                                                    name="first_name"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="First name"
                                                    inputRef={register}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    error={!!fieldErrors.first_name}
                                                    helperText={
                                                        fieldErrors.first_name
                                                            ? fieldErrors.first_name
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={1} mb={3}>
                                                <Typography variant="body2">Last name</Typography>
                                                <TextField
                                                    name="last_name"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Last name"
                                                    inputRef={register}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    error={!!fieldErrors.last_name}
                                                    helperText={
                                                        fieldErrors.last_name ? fieldErrors.last_name : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={1} mb={3}>
                                                <Typography variant="body2"> Email</Typography>
                                                <TextField
                                                    name="email"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="example@mail.com"
                                                    inputRef={register}
                                                    error={!!fieldErrors.email}
                                                    helperText={
                                                        fieldErrors.email ? fieldErrors.email[0] : null
                                                    }
                                                    InputProps={{
                                                        readOnly: readOnly.readOnlyEmail,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleClickChangeEmail}>
                                                                    <CreateIcon fontSize="small"/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={1} mb={3}>
                                                <Typography variant="body2">Phone Number</Typography>
                                                <TextField
                                                    name="phone_number"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="01 234 567 89"
                                                    inputRef={register}
                                                    error={!!fieldErrors.phone_number}
                                                    helperText={
                                                        fieldErrors.phone_number
                                                            ? fieldErrors.phone_number
                                                            : null
                                                    }
                                                    InputProps={{
                                                        readOnly: readOnly.readOnlyPhoneNumber,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={handleClickChangePhoneNumber}
                                                                >
                                                                    <CreateIcon fontSize="small"/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={2}>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="large"
                                                        type="submit"
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box p={2}>
                        <Paper
                            elevation={0}
                            variant="outlined"
                            className={classes.cardSize}
                        >
                            <Box py={6} px={4}>
                                <form onSubmit={handleSubmit(onSubmitPassword)}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={10}>
                                            <Box mb={5}>
                                                <Typography color="primary" variant="h6">
                                                    Change Password
                                                </Typography>
                                            </Box>
                                            <Box mt={3}>
                                                <Typography variant="body2">
                                                    Enter previous password
                                                </Typography>
                                                <TextField
                                                    name="old_password"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Password"
                                                    inputRef={register}
                                                    error={!!fieldErrors.old_password}
                                                    helperText={
                                                        fieldErrors.old_password
                                                            ? fieldErrors.old_password
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={3}>
                                                <Typography variant="body2">New Password</Typography>
                                                <TextField
                                                    name="password"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Password"
                                                    inputRef={register}
                                                    error={!!fieldErrors.password}
                                                    helperText={
                                                        fieldErrors.password ? fieldErrors.password : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={3}>
                                                <Typography variant="body2">
                                                    Confirm Password
                                                </Typography>
                                                <TextField
                                                    name="password2"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder="Password"
                                                    inputRef={register}
                                                    error={!!fieldErrors.password2}
                                                    helperText={
                                                        fieldErrors.password2 ? fieldErrors.password2 : null
                                                    }
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box mt={3}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="large"
                                                    type="submit"
                                                    fullWidth
                                                >
                                                    change password
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TenantProfile;
