import React, { createContext, useContext } from 'react';
import { useSortBy, useTable, useExpanded } from 'react-table';
import { DataTableRenderer } from '../components';
import PropTypes from 'prop-types';

const DataTableContext = createContext(null);
const useDataTable = () => useContext(DataTableContext);

const DataTable = ({
  data,
  columns,
  title,
  renderRowSubComponent,
  handleRowClick,
  children,
  initialState,
  handlePaging,
  paging,
  sortingChanged,
  sort,
  sortableColumns,
  showFilters,
}) => {
  let instance;
  instance = !!sortingChanged
    ? useTable(
      {
        columns,
        data,
        initialState,
      },
      useExpanded
    )
    : useTable(
      {
        columns,
        data,
        initialState,
      },
      useSortBy,
      useExpanded
    );

  return data.length ? (
    <DataTableContext.Provider
      value={{
        ...instance,
        title,
        renderRowSubComponent,
        handleRowClick,
      }}
    >
      <DataTableRenderer
        paginable={initialState?.paginable}
        handlePaging={handlePaging}
        paging={paging}
        sortingChanged={sortingChanged}
        sort={sort}
        sortableColumns={sortableColumns}
        showFilters={showFilters}
      />
      {children}
    </DataTableContext.Provider>
  ) : null;
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  renderRowSubComponent: PropTypes.func,
  children: PropTypes.object,
};

export { DataTable, useDataTable };
