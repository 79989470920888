import React from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import UnitsVehiclesBlock from './VehiclesBlock';

const VehiclesSection = ({
  title,
  vehicleTitle,
  type,
  addButtonText,
  addButtonHandler,
  vehicles,
  disabled,
  control,
  register,
  fieldErrors,
  deleteButtonHandler,
  watch,
  formErrors,
  setValue,
}) => {
  let vehicleNumber = 0;
  return (
    <>
      <Paper elevation={0} variant="outlined">
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={addButtonHandler}
            disabled={disabled}
          >
            {addButtonText}
          </Button>
        </Box>
        {vehicles.length
          ? vehicles.map((item, index) => {
              return item !== undefined ? (
                <UnitsVehiclesBlock
                  key={index}
                  vehicleIndex={index}
                  vehicleNumber={++vehicleNumber}
                  vehicleTitle={vehicleTitle}
                  disabled={disabled}
                  type={type}
                  control={control}
                  register={register}
                  fieldErrors={fieldErrors}
                  deleteButtonHandler={deleteButtonHandler}
                  watch={watch}
                  formErrors={formErrors}
                  setValue={setValue}
                />
              ) : null;
            })
          : null}
      </Paper>
      <Box mb={3} />
    </>
  );
};

export default VehiclesSection;
