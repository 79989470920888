import React, { useCallback, useState } from 'react';
import {
  Box,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import VppDatePicker from '../../../../components/datePicker/vppDatePicker';
import VppSelect from '../../../../components/select/VppSelect';
import US_STATES_WITH_INITIALS from '../../../../us_states_and_initials';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getRules } from '../../../../actions/rules.actions';
const licencePlateValidator = {
  pattern: {
    value: /^[A-Z0-9]*$/,
    message:
      'License plate  must be only capital numbers and letters without especial chars',
  },
  maxLength: {
    value: 11,
    message: 'License plate must be 11 or less characters',
  },
};

moment.tz.setDefault('America/Chicago');

const getExpirationDate = (guest_hours_start, guest_hours_finish) => {
  if (!guest_hours_start || !guest_hours_finish) { 
    console.log({ guest_hours_start, guest_hours_finish });
  }

  const currentTime = moment();
  let time = guest_hours_finish?.split(':');

  if (guest_hours_start === guest_hours_finish) {
    return currentTime.add(1, 'days').toDate();
  } else {
    const targetTime = moment().set({
      hours: time[0],
      minutes: time[1],
      seconds: time[2],
    });
    
    return currentTime.format('HH:mm') >= guest_hours_finish
      ? targetTime.add(1, 'days').toDate()
      : targetTime.toDate();
  } 
}

const UnitsVehiclesBlock = ({
  vehicleNumber,
  vehicleIndex,
  vehicleTitle,
  type,
  disabled,
  control,
  register,
  fieldErrors,
  deleteButtonHandler,
  watch,
  formErrors,
  setValue,
}) => {
  const rules = useSelector((state) => state.rules);
  const dispatch = useDispatch();
  const [licencePlate, setLicencePlate] = useState('');
  const { guest_hours_finish, guest_hours_start } = rules;
  const statusSelect = ['pending', 'active', 'blocked', 'suspended'].map(
    (i) => ({
      name: i,
      value: i,
    })
  );
  const inputNamePrefix =
    type === 'tenant' ? 'tenant_vehicles' : 'guest_vehicles';

  const inputName = (name) => `${inputNamePrefix}[${vehicleIndex}].${name}`;
  const today = new Date();
  const errorCheck = (name) => {
    return (
      (fieldErrors[inputNamePrefix] &&
        fieldErrors[inputNamePrefix][vehicleIndex] &&
        fieldErrors[inputNamePrefix][vehicleIndex][name]) ||
      (formErrors[inputNamePrefix] &&
        formErrors[inputNamePrefix][vehicleIndex] &&
        formErrors[inputNamePrefix][vehicleIndex][name])
    );
  };

  const getGuestDate = useCallback(() => {
    return getExpirationDate(guest_hours_start, guest_hours_finish)
  }, [guest_hours_start, guest_hours_finish]);

  React.useEffect(() => {
    dispatch(getRules());
  }, []);

  const errorText = (name) => {
    return errorCheck(name)
      ? fieldErrors?.[inputNamePrefix]?.[vehicleIndex]?.[name]?.join?.(',') ||
          formErrors?.[inputNamePrefix]?.[vehicleIndex]?.[name]?.message
      : null;
  };

  const renderAutocompleteTextField = (params) => {
    return (
      <TextField
        {...params}
        type="text"
        variant="outlined"
        error={errorCheck('licence_state')}
        helperText={errorText('licence_state')}
        fullWidth
      />
    );
  };

  const defaulteExpirationDateValue = type === 'tenant' ?
    moment().add(1, 'years').endOf('day').toDate() : getGuestDate()

  return (
    <>
      <FormGroup>
        <legend>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {vehicleTitle} {vehicleNumber}:
            </Box>
            <Box my={-1.5}>
              <IconButton
                aria-label="delete"
                disabled={disabled}
                onClick={() => {
                  control.setValue(inputName('licence_state'), '');
                  deleteButtonHandler(vehicleIndex, type);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </legend>
      </FormGroup>
      <input name={inputName('id')} type="hidden" ref={register} />
      <Box pt={1} px={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Status
              </Typography>
            </InputLabel>
            <Box mt={-1}>
              <VppSelect
                name={inputName('status')}
                control={control}
                options={statusSelect}
                direction="column"
                disabled={disabled}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Issue Date
              </Typography>
            </InputLabel>
            <VppDatePicker
              name={inputName('issue_date')}
              size="small"
              disabled
              control={control}
              fullWidth
              defaultValue={today}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Expiration Date
              </Typography>
            </InputLabel>
            <VppDatePicker
              name={inputName('expiration_date')}
              size="small"
              control={control}
              disabled={disabled}
              error={errorCheck('expiration_date')}
              helperText={errorText('expiration_date')}
              defaultValue={
                defaulteExpirationDateValue
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="body2">Licence State</Typography>
            </InputLabel>
            <Autocomplete
              options={Object.keys(US_STATES_WITH_INITIALS)}
              getOptionLabel={(option) => US_STATES_WITH_INITIALS[option] || ''}
              getOptionSelected={() => watch(inputName('licence_state'))}
              {...register(inputName('licence_state'))}
              value={watch(inputName('licence_state')) || ''}
              inputRef={register}
              defaultValue={' '}
              onChange={(event, newValue) => {
                setValue(inputName('licence_state'), newValue);
              }}
              disabled={disabled}
              renderInput={renderAutocompleteTextField}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Tooltip
              title="please enter letters and number only for your license plate example: ABC123"
              placement="top"
            >
              <InputLabel>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{
                    cursor: 'help',
                  }}
                >
                  License Plate
                </Typography>
              </InputLabel>
            </Tooltip>
            <TextField
              {...register(inputName('licence_plate'), licencePlateValidator)}
              name={inputName('licence_plate')}
              size="small"
              disabled={disabled}
              inputRef={register}
              onChange={(event, props) => {
                control.fieldsRef.current[
                  inputName('licence_plate')
                ].ref.value = event.target.value
                  .toUpperCase()
                  .replaceAll(/[\W]/g, '');
              }}
              autoComplete="off"
              error={errorCheck('licence_plate')}
              helperText={errorText('licence_plate')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Make
              </Typography>
            </InputLabel>
            <TextField
              name={inputName('make')}
              size="small"
              disabled={disabled}
              inputRef={register}
              error={errorCheck('make')}
              helperText={errorText('make')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Model
              </Typography>
            </InputLabel>
            <TextField
              name={inputName('model')}
              size="small"
              disabled={disabled}
              inputRef={register}
              error={errorCheck('model')}
              helperText={errorText('model')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel>
              <Typography variant="caption" color="textPrimary">
                Color
              </Typography>
            </InputLabel>
            <TextField
              name={inputName('color')}
              size="small"
              disabled={disabled}
              inputRef={register}
              error={errorCheck('color')}
              helperText={errorText('color')}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UnitsVehiclesBlock;
