import React from 'react';
import moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Controller } from 'react-hook-form';

export default function VppDatePicker({
  control,
  name,
  disabled,
  fullWidth,
  defaultValue = null,
  error,
  size = 'small',
  helperText,
}) {
  moment.tz.setDefault('America/Chicago');
  const placeholder = moment().format('YYYY-MM-DD');
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        render={(props) => (
          <KeyboardDatePicker
            {...props}
            fullWidth={fullWidth}
            autoOk
            variant="inline"
            disablePast
            disableToolbar
            size={size}
            disabled={disabled}
            error={!!error}
            value={moment(props.value).utc(false)}
            helperText={helperText ? helperText : null}
            placeholder={placeholder}
            format="yyyy-MM-dd"
            onChange={(val, dateSet) => {
              const date = val ? moment(dateSet ? dateSet : val).endOf('day') : null;
              const newDate = new Date(date);
              const year = newDate.getFullYear();
              const month = newDate.getMonth();
              const day = newDate.getDate();
              const fullDate = `${year}-${month + 1}-${day}`;
              props.onChange(newDate);
            }}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </MuiPickersUtilsProvider>
  );
}
