import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function formatValidationErrors(err) {
  const fieldErrors = {};
  err.errors.forEach((validationError) => {
    const path = validationError.path.join('.');
    if (!fieldErrors[path]) {
      fieldErrors[path] = [];
    }
    fieldErrors[path].push(validationError.message);
  });
  return fieldErrors;
}

export const calculateRemainingTime = (unixTimestamp) => {
  const expirationMoment = moment.unix(unixTimestamp);
  const now = moment();
  return expirationMoment.diff(now);
};

export const dateFormatter = (date) => moment(date).format('MM/DD/YYYY');
export const timeFormatter = (time) =>
  moment(time, 'HH:mm:ss.SSSSSS').format('HH:mm:ss');

