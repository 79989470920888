import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    marginTop: theme.spacing(5),
    width: 300,
  },
}));

const HomePage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box mt={5}>
      <Grid direction="row" justify="center" alignItems="center" container>
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <Box display="flex" alignItems="center" flexDirection="column">
              <img src="/logo.svg" className={classes.logo} />
              <Box mt={7} mb={3}>
                <Typography variant="h5" color="primary">
                  Welcome
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default HomePage;
