import React from 'react';
import PropertyLayout from '../../layouts/Property';
import { Toolbar } from '@material-ui/core';
import CardForgotPassword from '../../components/Card/CardForgotPassword';

const ForgotPasswordPage = () => {
  return (
    <PropertyLayout>
      <Toolbar />
      <CardForgotPassword />
      <Toolbar />
    </PropertyLayout>
  );
};

export default ForgotPasswordPage;
