import * as types from './types';
import { vpp, VPP_API } from '../api/vpp';

const setUsers = (payload) => ({ type: types.SET_USERS, payload });
const setStatus = (payload) => ({ type: types.STATUS_SUCCESS, payload });
export const userLogin = (payload) => ({ type: types.USER_LOGIN, payload });

export const userLogout = () => {
  return (dispatch) => {
    dispatch({ type: types.USER_LOG_OUT });
  };
};

export const refreshToken = (payload) => ({
  type: types.USER_REFRESH_TOKEN,
  payload,
});

export const userLoginFetch = (user) => (dispatch) =>
  vpp.post(VPP_API.getToken, user).then(({ data }) => {
    dispatch(userLogin({ ...data, portal: user.portal }));
    dispatch({ type: types.SET_PROPERTY, payload: data.property_info });
    dispatch({ type: types.SET_USER_PROFILE, payload: data.user_info });
  });

export const refreshTokenFetch = (refresh) => (dispatch) =>
  vpp.post(VPP_API.refreshToken, refresh).then(({ data }) => {
    const portal = localStorage.getItem("portal") ?? ''
    dispatch(refreshToken({ ...data, portal }));
    dispatch({ type: types.SET_PROPERTY, payload: data.property_info });
    dispatch({ type: types.SET_USER_PROFILE, payload: data.user_info });
  });

export const getUsers = (search) => (dispatch) =>
  vpp
    .get(VPP_API.getUsers, search ? { params: search } : null)
    .then(({ data }) => {
      dispatch(setUsers({ users: data }));
    });

export const createUser = (data) => (dispatch) =>
  vpp.post(VPP_API.createUser, data);

export const patchUserStatus = (status, userId) => (dispatch) =>
  vpp
    .patch(VPP_API.patchUser(userId), { status })
    .then(({ data }) => {
      dispatch(setStatus(data));
    })
    .catch((error) => console.log(error));

export const deleteUser = (id) => (dispatch) =>
  vpp.delete(VPP_API.patchUser(id)).then(() => {
    dispatch({ type: types.DELETE_USER, payload: id });
  });

export const userForgotPassword = (user_login_field, portal_name, property_sub_domain) => (dispatch) =>
  vpp.post(VPP_API.forgotPassword, { 
    user_login_field: String(user_login_field).trim(), 
    portal_name: String(portal_name).trim(), 
    property_sub_domain: String(property_sub_domain).trim()
  
  });

export const userChangePassword = (key, password, password2) => (dispatch) =>
  vpp.post(VPP_API.forgotPasswordChange, { key, password, password2 });
