import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
  },
  activeStatus: {
    backgroundColor: 'rgba(33, 190, 88, 0.05)',
    color: 'rgb(33, 190, 88)',
  },
  blockedStatus: {
    backgroundColor: 'rgba(207, 24, 68, 0.05)',
    color: 'rgb(207, 24, 68)',
  },
  pendingStatus: {
    backgroundColor: 'rgba(66, 133, 244, 0.05)',
    color: 'rgb(66, 133, 244)',
  },
}));

const StatusChipUsers = ({ status }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  let userStatus;
  if (status === 'active') {
    userStatus = 'Active';
  } else if (status === 'blocked') {
    userStatus = 'Not Active';
  } else {
    userStatus = 'Pending';
  }
  return (
    <Chip
      label={userStatus}
      className={clsx(classes.root, classes[`${status}Status`])}
    />
  );
};

export default StatusChipUsers;
