import React from 'react';
import { makeStyles, Grid, Typography, Button, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import VppSearch from '../Search/Search';

const useStyles = makeStyles({
  btnGroup: {
    marginBottom: 37,
  },
  searchDuplicate: {
    width: 300,
    overflow: 'auto',
  },
});

const VppBtnGroup = ({
  children,
  search,
  handleSearch,
  handleClearSearch,
  handleSubmit,
  register,
  isAutocomplete,
  isSearching,
  showButton = false,
  onButtonClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container justify="space-between" className={classes.btnGroup}>
      <Grid item>
        <Box display="flex" alignItems="center">
          <VppSearch
            search={search}
            handleSearch={handleSearch}
            register={register}
            handleSubmit={handleSubmit}
            isAutocomplete={isAutocomplete}
            handleCancelSearch={handleClearSearch}
          />
          {
            showButton && (
              <Button
                variant='contained'
                size='large'
                color='primary'
                onClick={onButtonClick}
                style={{ marginLeft: 20 }}
                disabled={!search}
              >
                Search
              </Button>
            )
          }
          {search && !showButton ? (
            <>
              <Box ml={1}>
                <Typography variant="body1">
                  Showing search results for:
                </Typography>
              </Box>
              <Box ml={1} className={classes.searchDuplicate}>
                <Typography variant="subtitle2">{search}</Typography>
              </Box>
            </>
          ) : null}
        </Box>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default VppBtnGroup;
