import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAuth } from '../contexts/auth';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: { zIndex: theme.zIndex.tooltip + 1 },
}));

export default () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { loading } = useAuth();
  const system = useSelector((state) => state.system);
  return (
    <Backdrop open={loading || system.loading} className={classes.root}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
