import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router';
import Login from './pages/login/Login';
import PropertyLanding from './pages/landing/PropertyLanding';
import HomePage from './pages/landing/Home';
import Register from './pages/register/tenant/Register';
import RegisterManager from './pages/register/manager/RegisterManager';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ChangePasswordPage from './pages/forgotPassword/ChangePassword';
import ErrorPage from './pages/landing/ErrorPage';

export default function () {
  const { params } = useRouteMatch();
  const { propertyName } = params;

  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/:propertyName">
        <PropertyLanding />
      </Route>
      <Route exact path="/:propertyName/:loginType(tenant|manager)/login">
        <Login />
      </Route>
      <Route exact path="/:propertyName/register/tenant/:key">
        <Register />
      </Route>
      <Route exact path="/:propertyName/register/manager/:key">
        <RegisterManager />
      </Route>
      <Route
        exact
        path="/:propertyName/:loginType(tenant|manager)/forgotPassword"
      >
        <ForgotPassword />
      </Route>
      <Route
        exact
        path="/:propertyName/:loginType(tenant|manager)/forgotPassword/:key"
      >
        <ChangePasswordPage />
      </Route>
      <Route exact path="/page-error/:error">
        <ErrorPage />
      </Route>
      <Redirect to={propertyName ? `/${propertyName}` : '/'} />
    </Switch>
  );
}
