import * as types from './types';
import { vpp, VPP_API } from '../api/vpp';

const setUserProfile = (payload) => ({ type: types.SET_USER_PROFILE, payload });
const setUserProfilePassword = (payload) => ({
  type: types.SET_USER_PASSWORD,
  payload,
});

export const updateUserProfile = (data) => (dispatch) =>
  vpp.patch(VPP_API.updateUserProfile, data).then(({ data }) => {
    dispatch(setUserProfile(data));
  });

export const updateUserProfilePassword = (data) => (dispatch) =>
  vpp.post(VPP_API.updateUserProfilePassword, data).then(({ data }) => {
    dispatch(setUserProfilePassword(data));
  });
