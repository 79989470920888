const { z } = require('zod');

const addUnitSchema = z.object({
  first_name: z.string().min(1, { message: 'First name is required.' }),
  last_name: z.string().min(1, { message: 'Last name is required.' }),
  email: z
    .string()
    .min(1, { message: 'Email address is required.' })
    .email({ message: 'Email address is not in the right format.' }),
  phone_number: z.string().min(1, { message: 'Phone number is required.' }),
  unit_number: z.string().min(1, { message: 'Unit number is required.' }),
});

const editTenantSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email address is required.' })
    .email({ message: 'Email address is not in the right format.' }),
  phone_number: z.string().min(1, { message: 'Phone number is required.' }),
});

const resendActivationLinkSchema = z.object({
  first_name: z.string().min(1, { message: 'First name is required.' }),
  last_name: z.string().min(1, { message: 'Last name is required.' }),
  email: z
    .string()
    .min(1, { message: 'Email address is required.' })
    .email({ message: 'Email address is not in the right format.' }),
  phone_number: z.string().min(1, { message: 'Phone number is required.' }),
});

export { addUnitSchema, editTenantSchema, resendActivationLinkSchema };
