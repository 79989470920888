import React, { useRef, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Divider,
  Hidden,
} from '@material-ui/core';
import { VehicleIcon } from '../../Icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VehicleForm from './VehicleForm';
import CreateIcon from '@material-ui/icons/Create';
import CustomModal from '../modal/CustomModal';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    position: 'relative',
  },
  contentRoot: {
    padding: 0,
  },
  actionsRoot: {
    padding: 0,
  },
  licensePlate: {
    padding: theme.spacing(2, 4),
    backgroundColor: 'rgb(247,248,249)',
  },
  overlayRoot: {
    backgroundColor: 'rgba(125, 138, 153, 0.2)',
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 42px)',
    top: 0,
    left: 0,
  },
  overlayWithoutActions: {
    height: '100%',
  },
}));

const VehiclePermitCard = ({
  vehicle = {},
  vehicleIndex = 0,
  setEditVehicle = null,
  setEditVehicleIndex = null,
  guest = false,
  readOnly = false,
  pastPermit = false,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const modalRef = useRef();
  const [stopControlled, setStopControlled] = useState(false);

  const renderButton = (onClick, vehicle) =>
    readOnly ? null : (
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={onClick}
        startIcon={vehicle.id ? <CreateIcon fontSize="small" /> : null}
        fullWidth
        disabled={vehicle ? vehicle?.is_active : false}
      >
        {!vehicle.id ? (guest ? 'ISSUE PERMIT' : 'REGISTER VEHICLE') : 'EDIT'}
      </Button>
    );

  const handleClose = () => {
    if (stopControlled) {
      modalRef.current.close();
      setStopControlled(false);
    } else if (
      confirm('Are you sure you want to close the window without saving?')
    ) {
      modalRef.current.close();
    }
  };

  return (
    <>
      <Card className={classes.root} square>
        <CardHeader
          avatar={
            <Box ml={3} mr={2}>
              <VehicleIcon color="secondary" />
            </Box>
          }
          title={
            <Typography color="primary" style={{ fontWeight: 600 }}>
              {guest ? 'Guest ' : null} Vehicle {vehicleIndex}
            </Typography>
          }
        />
        <CardContent className={classes.contentRoot}>
          <Grid className={classes.licensePlate} container>
            <Grid item xs={6}>
              <Typography color="primary" variant="body2">
                License Plate
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" variant="button">
                <strong>{vehicle.licence_plate}</strong>
                {vehicle.license_state && (
                  <strong> - {vehicle.licence_state}</strong>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Box pt={2} pb={1} px={5.5}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="caption">Make:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">{vehicle.make}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">Model:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">{vehicle.model}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">Color:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">{vehicle.color}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          {readOnly ? (
            <Box
              display="flex"
              pt={3}
              mb={-2}
              px={5.5}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption">Issue Date</Typography>
              <Typography color="primary" variant="button">
                <strong>{vehicle.issue_date}</strong>
              </Typography>
            </Box>
          ) : null}
          <Box
            display="flex"
            py={3}
            px={5.5}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption">Permit Expiration Date</Typography>
            <Typography color="primary" variant="button">
              <strong>{vehicle?.expiration_date?.substr(0, 10)}</strong>
              {pastPermit && vehicle.expiration_time ? (
                <strong>
                  {` ${moment(vehicle.expiration_time, 'HH:mm:ss').format(
                    'h:mm A'
                  )}`}
                </strong>
              ) : null}
            </Typography>
          </Box>
        </CardContent>
        <CardActions className={classes.actionsRoot} disableSpacing>
          <Hidden xsDown>
            <CustomModal
              renderOpen={(handleClickOpen) =>
                renderButton(handleClickOpen, vehicle)
              }
              ref={modalRef}
              onClose={handleClose}
              controlled={!stopControlled}
            >
              <Box width={430} mt={-4}>
                <VehicleForm
                  vehicle={vehicle}
                  vehicleIndex={vehicleIndex}
                  modalRef={modalRef}
                  guest={guest}
                  setStopControlled={setStopControlled}
                />
              </Box>
            </CustomModal>
          </Hidden>
          <Hidden smUp>
            {renderButton(() => {
              setEditVehicle(vehicle);
              setEditVehicleIndex(vehicleIndex);
            }, vehicle)}
          </Hidden>
        </CardActions>
        {!vehicle.status || vehicle.status !== 'active' ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={clsx(classes.overlayRoot, {
              [classes.overlayWithoutActions]: readOnly || pastPermit,
            })}
          >
            <ErrorOutlineIcon
              style={{ width: 108, height: 108, color: '#fff' }}
            />
          </Box>
        ) : null}
      </Card>
    </>
  );
};

export default VehiclePermitCard;
