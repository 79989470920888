import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomModal from '../../../../components/modal/CustomModal';
import { setUnitArchive } from '../../../../actions/units.actions';

const ArchiveUnit = ({ unitId, renderOpen, handleSuccess, unitStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const modalRef = useRef(null);

  const handleArchive = (e) => {
    e && e.stopPropagation();
    dispatch(setUnitArchive(unitId)).then(() => {
      enqueueSnackbar('Unit was archived', {
        variant: 'success',
      });
      modalRef.current.close();
      history.push('/admin/units/');
      handleSuccess && handleSuccess();
    });
  };

  const handleClose = (e) => {
    e && e.stopPropagation();
    modalRef.current.close();
  };

  return (
    <>
      {unitStatus === 'archived' ? (
        <Box style={{ height: 48 }}></Box>
      ) : (
        <CustomModal renderOpen={renderOpen} ref={modalRef}>
          <Box textAlign="center">
            <Box>
              <ErrorOutlineIcon
                style={{ width: 67, height: 67, color: 'red' }}
              />
            </Box>
            <Typography variant="h6" color="primary">
              <Box fontWeight="bold">Warning!</Box>
            </Typography>
            <Box mt={2} />
            <Typography variant="body1">
              Are you sure you want to archive this Unit along with Tenant
              Information? All information will be sent to the archive and you
              will no longer have access to it. The unit number will be
              available for use by the next tenant.
            </Typography>
            <Box mt={7}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={(e) => handleClose(e)}
              >
                Cancel
              </Button>
              <Box mr={2} component="span" />
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={(e) => handleArchive(e)}
              >
                Archive
              </Button>
            </Box>
          </Box>
        </CustomModal>
      )}
    </>
  );
};

export default ArchiveUnit;
