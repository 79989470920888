import { withNavigationWatcher } from './contexts/navigation';
import TenantHome from './pages/tenant/Home';
import MyPermitsPage from './pages/tenant/MyPermits';
import TenantProfile from './pages/tenant/TenantProfile';
import GuestPermitsPage from './pages/tenant/GuestPermits';

const routes = [
  {
    path: '/tenant/home',
    component: TenantHome,
  },
  {
    path: '/tenant/myPermits',
    component: MyPermitsPage,
  },
  {
    path: '/tenant/guestPermits',
    component: GuestPermitsPage,
  },
  {
    path: '/tenant/myAccount',
    component: TenantProfile,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
