import axios from 'axios';
import {store} from "../store";
import {get} from 'lodash';

const env = process.env.REACT_APP_ENV || 'staging'; // process.env.NODE_ENV

const API_URLS = {
    local: 'http://localhost:8000',
    development: 'http://ec2-18-224-70-179.us-east-2.compute.amazonaws.com',
    staging: 'https://api.staging.vppermits.com',
    prod: 'https://api.vppermits.com',
};

const API_BASE_URL = `${API_URLS[env]}/api/`;

const VPP_API = {
    getToken: 'token/access/',
    refreshToken: 'token/refresh/',
    unit: 'units/',
    unitId: (id) => `units/${id}/`,
    resendUnitinvitation: (id) => `units/resend/${id}`,
    unitStatus: (id) => `units/change-status/${id}/`,
    unitArchive: (id) => `units/archive/${id}/`,
    getUsers: 'user/',
    patchUser: (userId) => `user/${userId}/`,
    createUser: 'user/invite/',
    parkingRules: 'parking-rules/',
    updateParkingRules: 'parking-rules/update/',
    updateProperty: (id) => `property/${id}/`,
    updateUserProfile: 'user/me/update/',
    updateUserProfilePassword: 'user/me/change-password/',
    tows: 'tows/',
    stickered: 'stickered-violators/',
    registrationConfirmInfo: (key) => `registration/tenant/${key}/`,
    postTenantInfo: (key) => `registration/tenant/${key}/validate-credential/`,
    postPasswordValidation: (key) =>
        `registration/tenant/${key}/validate-password/`,
    getPdfRulesInfo: (key) => `registration/tenant/${key}/fetch-addendum/`,
    postFinishInfo: (key) => `registration/tenant/${key}/finish-registration/`,
    registrationConfirmManagerInfo: (key) => `registration/manager/${key}/`,
    postUserInfo: (key) => `registration/manager/${key}/validate-credential/`,
    postFinishUserRegisterInfo: (key) =>
        `registration/manager/${key}/finish-registration/`,
    checkSubdomain: (name) => `property/check-subdomain/?sub_domain_name=${name}`,
    forgotPassword: 'forgot-password/',
    forgotPasswordChange: 'forgot-password/change-password/',
    tenantVehicles: 'tenant/vehicles/',
    tenantVehicle: (id) => `tenant/vehicles/${id}/`,
    guestVehicles: 'guest/vehicles/',
    guestVehicle: (id) => `guest/vehicles/${id}/`,
    guestArchive: '/guest/archive/',
    guestPermits: '/guest-permits/',
    officeSupport: (key) => `office-support/${key}/`,
    downloadAddemdum: (id) => `/units/addendum/${id}/`,
    getReservedSpots: 'reserved-spots/',
    activities: '/activities/',
};
const vpp = axios.create({baseURL: API_BASE_URL});

const refreshClient = axios.create({baseURL: API_BASE_URL})

vpp.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        config.headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

vpp.interceptors.response.use(
    (response) => response,
    (error) => {
        const {config, response} = error;
        const originalRequest = config;

        if (
            response &&
            response.status === 401 &&
            config &&
            !config.__isRetryRequest &&
            config.url !== VPP_API.getToken
        ) {
            originalRequest._retry = true;

            const state = store.getState()

            const refresh = localStorage.getItem('refresh_token');
            const portal = localStorage.getItem('portal');
            const propertySubDomain = get(state, 'property.sub_domain_name', '');

            const redirectUrl = propertySubDomain && portal ? `/${propertySubDomain}/${portal}/login` : '';

            return refreshClient
                .post(VPP_API.refreshToken, {refresh}, {
                    headers: {
                        'Authorization': `Bearer ${refresh}`
                    }
                }).then((response) => {
                    const {access, refresh} = response.data;
                    localStorage.setItem('token', access);
                    localStorage.setItem('refresh_token', refresh);
                    return vpp(originalRequest);
                })
                .catch((error) => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = redirectUrl;
                    return Promise.reject(error);
                });
        }
        return Promise.reject(error);
    }
);

export {vpp, VPP_API, API_BASE_URL};
