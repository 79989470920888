import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CustomizedStepper from '../Stepper/Stepper';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRegistrationContactInfo,
  pdfRulesInfo,
  sendFinishInfo,
  sendPasswordValidation,
  sendTenantInfo,
  setPassword,
} from '../../actions/tenant.actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles((theme) => ({
  loginCardMainContainer: {
    width: 560,
    overflow: 'auto',
  },
  mainPageTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  pdfConent: {
    padding: '20px 15px',
    maxHeight: 340,
    backgroundColor: '#FBFBFB',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DFE0EB',
    overflow: 'auto',
  },
  signatureField: {
    '& input': {
      height: 95,
      backgroundColor: '#FBFBFB',
      textAlign: 'center',
      fontSize: 20,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
    },
  },
  btnWidth: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  input: {
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: '#fff',
    '& input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
      '&:-internal-autofill-selected': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'white!important',
      },
    },
  },
}));

const CardTenantRegistration = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { register, setValue, getValues, reset } = useForm();
  const defaultData = useSelector((store) => store.tenantReducer);
  const { password, password2, addendum_text } = useSelector(
    (store) => store.tenantReducer
  );
  const { enqueueSnackbar } = useSnackbar();
  const [tenantEmail, setTenantEmail] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [fieldErrors, setFieldErrors] = useState({});
  const [guestHours, setGuestHours] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState({
    checkedB: false,
    disabled: true,
  });
  const rulesCheckScroll = useRef();
  const [readOnly, setReadOnly] = useState({
    readOnlyEmail: true,
    readOnlyPhoneNumber: true,
  });
  const activeSteps = activeStep;
  const stepperBody = getSteps();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const history = useHistory();
  const { key, propertyName } = params;
  const { logIn } = useAuth();

  moment.locale('en');

  const handleBeforeunload = useCallback(() => {
    if (activeStep > 2) {
      localStorage.errorKey = key;
    }
    if (localStorage.errorKey !== key) {
      localStorage.removeItem('errorKey');
    }
  }, [activeStep]);

  useEffect(() => {
    window.addEventListener('pagehide', () => {
      handleBeforeunload();
    });
    return window.removeEventListener('pagehide', () => {
      handleBeforeunload();
    });
  }, [handleBeforeunload]);

  useEffect(() => {
    dispatch(getRegistrationContactInfo(key));
  }, []);

  useEffect(() => {
    Object.keys(defaultData).forEach((item) => {
      setValue(item, defaultData[item]);
    });
  }, [defaultData]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickChangeEmail = () => {
    setReadOnly({ ...readOnly, readOnlyEmail: !readOnly.readOnlyEmail });
  };
  const handleClickChangePhoneNumber = () => {
    setReadOnly({
      ...readOnly,
      readOnlyPhoneNumber: !readOnly.readOnlyPhoneNumber,
    });
  };

  const handleChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRedirectTenantHomePage = async () => {
    await logIn(tenantEmail, password, 'tenant', propertyName, false, true);
    history.replace('/tenant/myPermits');
  };

  const onSubmit = () => {
    switch (activeStep) {
      case 0:
        const { email, phone_number: phoneNumber } = getValues();
        setTenantEmail(phoneNumber);
        dispatch(sendTenantInfo(key, email, phoneNumber))
          .then(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            reset({});
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
          });
        break;
      case 1:
        dispatch(sendPasswordValidation(key, password, password2))
          .then(() => {
            dispatch(pdfRulesInfo(key));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
          });
        break;
      case 2:
        const { user_signature } = getValues();
        dispatch(sendFinishInfo(key, password, password2, user_signature))
          .then(({ data }) => {
            const { meta } = data;
            setGuestHours(meta);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
          });
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const scrollChangeHeight = (e) => {
    let element = e.target;
    if (
      element.clientHeight >=
      element.scrollHeight - Math.ceil(element.scrollTop)
    ) {
      setChecked({
        disabled: false,
      });
    } else {
      setChecked({
        disabled: true,
      });
    }
  };

  const handleChangeField = (fieldName, e) => {
    const value = e.target.value;
    dispatch(setPassword({ [fieldName]: value }));
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">
                Welcome{' '}
                <strong>
                  {defaultData.first_name} {defaultData.last_name}
                </strong>
                ,
                <br />
                Please confirm your contact information below
              </Typography>
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">First Name</Typography>
              <TextField
                type="text"
                name="first_name"
                variant="outlined"
                inputRef={register}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Last Name</Typography>
              <TextField
                type="text"
                name="last_name"
                variant="outlined"
                inputRef={register}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Email</Typography>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                inputRef={register}
                error={!!fieldErrors.email}
                helperText={fieldErrors.email ? fieldErrors.email : null}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: readOnly.readOnlyEmail,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickChangeEmail}>
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Phone number</Typography>
              <TextField
                type="text"
                name="phone_number"
                variant="outlined"
                inputRef={register}
                error={!!fieldErrors.phone_number}
                helperText={
                  fieldErrors.phone_number ? fieldErrors.phone_number : null
                }
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: readOnly.readOnlyPhoneNumber,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickChangePhoneNumber}>
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Unit Number</Typography>
              <TextField
                type="text"
                name="unit_number"
                variant="outlined"
                inputRef={register}
                fullWidth
                InputProps={{
                  className: classes.input,
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">
                Please set your password for future access
              </Typography>
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Password</Typography>
              <TextField
                autoFocus
                type={showPassword ? 'text' : 'password'}
                name="password"
                inputRef={register}
                variant="outlined"
                onChange={(e) => handleChangeField('password', e)}
                error={!!fieldErrors.password}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  fieldErrors.password
                    ? fieldErrors.password
                    : 'The password must be at least 8 symbols long, contain at least 1 number, 1 uppercase letter and 1 lowercase letter.'
                }
                fullWidth
              />
            </Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">Confirm Password</Typography>
              <TextField
                type={showPassword ? 'text' : 'password'}
                name="password2"
                inputRef={register}
                error={!!fieldErrors.password2}
                helperText={
                  fieldErrors.password2 ? fieldErrors.password2 : null
                }
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e) => handleChangeField('password2', e)}
                fullWidth
              />
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Box mt={1} mb={3}>
              <Typography variant="body2">
                Please review and sign your parking rules addendum. For any
                question please contact your lease office
              </Typography>
            </Box>
            <Box>
              <Card>
                <CardContent
                  className={classes.pdfConent}
                  ref={rulesCheckScroll}
                  onScroll={scrollChangeHeight}
                >
                  <Typography>{addendum_text}</Typography>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3} mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.checkedB}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                    disabled={checked.disabled}
                  />
                }
                label={
                  <Typography color="primary" variant="body2">
                    I have review the parking rules addendum
                  </Typography>
                }
              />
            </Box>
            <Box mt={1} mb={3}>
              <TextField
                margin="dense"
                type="text"
                name="user_signature"
                inputRef={register}
                variant="outlined"
                placeholder="Sign your name here"
                fullWidth
                disabled={!checked.checkedB}
                error={!!fieldErrors.user_signature}
                helperText={
                  fieldErrors.user_signature ? fieldErrors.user_signature : null
                }
                inputProps={{
                  maxLength: 35,
                }}
                className={classes.signatureField}
              />
            </Box>
          </Box>
        );
      default:
        return (
          <Box pt={5} pb={5}>
            <Box textAlign="center">
              <CheckIcon color="secondary" fontSize="large" />
            </Box>
            <Box mb={8}>
              <Typography color="primary" variant="h6" align="center">
                Your account was created!
              </Typography>
            </Box>
            <Box mt={1} mb={13}>
              <Typography variant="body2">
                As a next step, you must register your vehicle’s license plate.
                If the License plate is not registered, your vehicle may be
                towed.
                {/* <strong>
                  {moment(guestHours.tow_hours_start, 'HH:mm:ss').format(
                    'h:mm A'
                  )}{' '}
                </strong> */}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleRedirectTenantHomePage()}
              >
                OK
              </Button>
            </Box>
          </Box>
        );
    }
  }
  function getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  }

  return (
    <form>
      <Box mt={6} />
      <Grid container justify="center" alignContent="center" direction="column">
        <Grid item xs={12} className={classes.loginCardMainContainer}>
          <Card variant="outlined">
            <CardContent>
              {localStorage.errorKey === key ? (
                <Box mt={5}>
                  <Grid
                    direction="column"
                    justify="center"
                    alignItems="center"
                    container
                  >
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <img src="/logo.svg" className={classes.logo} />
                      </Box>
                      <Box my={3} textAlign="center">
                        <Typography variant="h5" color="primary">
                          This user was registered already
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  {activeStep === stepperBody.length ? null : (
                    <>
                      <Box mt={3}>
                        <Typography color="primary" variant="h6" align="center">
                          Registration
                        </Typography>
                      </Box>
                      <CustomizedStepper
                        stepperBody={stepperBody}
                        activeSteps={activeSteps}
                      />
                    </>
                  )}
                  <Box>
                    <Box pr={3} pl={3} className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Box>
                    {activeStep === stepperBody.length ? null : (
                      <Box display="flex" justifyContent="center" pl={3} pr={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onSubmit}
                          size="large"
                          className={classes.btnWidth}
                        >
                          Next
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default CardTenantRegistration;
