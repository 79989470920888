import { Box, Typography } from '@material-ui/core';
import React from 'react';

const StickeredAccordion = ({
  warningDate,
  warningTime,
  warningNumber,
  driverName,
  driverNotes,
}) => {
  return (
    <Box>
      <Box marginY={2} display={'flex'}>
        <Box marginX={2} display={'flex'}>
          <Typography variant="subtitle2" style={{ minWidth: 130 }}>
            Warning Date
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 14 }}>
            {warningDate}
          </Typography>
        </Box>
        <Box marginX={2} display={'flex'}>
          <Typography variant="subtitle2" style={{ minWidth: 130 }}>
            Warning Time
          </Typography>
          <Typography variant="body2" style={{ marginLeft: 14 }}>
            {warningTime}
          </Typography>
        </Box>
      </Box>
      <Box marginX={2} marginY={2} display={'flex'}>
        <Typography variant="subtitle2" style={{ minWidth: 130 }}>
          Driver Name
        </Typography>
        <Typography variant="caption" style={{ marginLeft: 14 }}>
          {driverName}
        </Typography>
      </Box>
      <Box marginX={2} marginY={2} display={'flex'}>
        <Typography variant="subtitle2" style={{ minWidth: 130 }}>
          Driver Notes
        </Typography>
        <Typography variant="caption" style={{ marginLeft: 14 }}>
          {driverNotes}
        </Typography>
      </Box>
      <Box marginX={2} marginY={4} display={'flex'}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{
            minWidth: 130,
          }}
        >
          Warning Number
        </Typography>
        <Typography variant="body1" color="primary" style={{ marginLeft: 14 }}>
          {warningNumber}
        </Typography>
      </Box>
    </Box>
  );
};

export default StickeredAccordion;
