import React from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { useDataTable } from '../../contexts/dataTable';

const DataTableHeader = ({ sortingChanged, sort, sortableColumns }) => {
  const { headerGroups } = useDataTable();

  const changeSort = (column) => {
    let sortDirection = 'asc';
    if (
      !!sort?.sortDirection &&
      column.id === sort.sort &&
      sortableColumns.includes(column.id)
    ) {
      sortDirection = sort.sortDirection === 'asc' ? 'desc' : undefined;
    }
    const sorting = {
      sort: sortDirection ? column?.id : undefined,
      sortDirection: sortDirection,
    };
    if (sortingChanged && sortableColumns.includes(column.id)) {
      sortingChanged(sorting);
    }
  };

  return (
    <TableHead>
      {headerGroups.map((headerGroup, index) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column, index) => {
            return sortableColumns && sortableColumns.includes(column.id) ? (
              <TableCell
                {...(sortingChanged && !column.getSortByToggleProps
                  ? { ...column }
                  : {
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                  })}
                key={index}
                onClick={() => changeSort(column)}
              >
                <Box display="flex">
                  {column.render('Header')}
                  <TableSortLabel
                    active={column.id === sort?.sort && !!sort?.sortDirection}
                    direction={sort?.sortDirection}
                  />
                </Box>
              </TableCell>
            ) : (
              <TableCell
                {...(sortingChanged && !column.getSortByToggleProps
                  ? { ...column }
                  : {
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                  })}
                onClick={() => null}
                key={index}
              >
                <Box display="flex">
                  {column.render('Header')}
                </Box>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default DataTableHeader;
