import * as types from '../actions/types';

const defaultState = {
  unitStatus: [],
  data: [],
  meta: { guest_vehicles_count: 0, tenant_vehicles_count: 0},
};

const unitsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_UNITS:
      return {
        ...state,
        data: [...action.payload.result],
        meta: { ...action.payload.meta },
      };
    case types.UNIT_STATUS_SUCCESS:
      const newState = { ...state };
      const unit = newState.data.find((item) => item.id === action.payload.id);
      unit.status = action.payload.data.status;
      return {
        ...newState,
      };
    default:
      return state;
  }
};

export default unitsReducer;
