import * as types from './types';
import { vpp, VPP_API } from '../api/vpp';

const setProperty = (payload) => ({ type: types.SET_PROPERTY, payload });

export const getPropertyBySubdomain = (name) => (dispatch) =>
  vpp.get(VPP_API.checkSubdomain(name), {
    data: {
      sub_domain_name: name,
    },
  });

export const updateProperty = (id, data) => (dispatch) =>
  vpp
    .patch(VPP_API.updateProperty(id), data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => {
      dispatch(setProperty(data));
    });
