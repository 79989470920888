import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CancelOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #DFE0EB',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 300,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const VppSearch = ({
  search,
  handleSearch = () => { },
  register,
  handleSubmit,
  handleCancelSearch,
  isAutocomplete,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Search"
          name="search"
          inputProps={{ 'aria-label': 'Search' }}
          onKeyUp={isAutocomplete ? handleSubmit(handleSearch) : null}
          inputRef={register}
        />
        <Tooltip title="Clear Search">
          <IconButton
            type="button"
            className={classes.iconButton}
            aria-label="cancel"
            onClick={handleCancelSearch}
          >
            { search && <CancelOutlined /> }
          </IconButton>
        </Tooltip>
      </form>
    </Paper>
  );
};

export default VppSearch;
