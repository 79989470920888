import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { createMuiTheme } from '@material-ui/core/styles';
import createSpacing from '@material-ui/core/styles/createSpacing';

export const lightTheme = createMuiTheme({
  spacing: createSpacing(8),
  shape: {
    borderRadius: 4,
  },
  typography: {
    subtitle2: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 500,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1358A0',
      dark: '#4696FC'
    },
    secondary: {
      main: '#CF1844',
    },
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: '6px' 
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: 0
        }
      }
    },
    MuiBadge: {
      badge: {
        marginRight: -16,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 8,
        height: 22,
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 16px',
      },
      head: {
        background: '#F7F8FC',
        padding: 16,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover': {
          '&:hover': {
            cursor: 'pointer',
            boxShadow: '5px 4px 35px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
          },
          '&$selected:hover': {
            backgroundColor: fade('#4285F4', 0.2),
          },
        },
        '&$selected': {
          backgroundColor: fade('#4285F4', 0.1),
          '&:hover': {
            backgroundColor: fade('#4285F4', 0.2),
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 2,
      },
      sizeLarge: {
        minWidth: 192,
        '&$fullWidth': {
          minWidth: 50,
        },
      },
      containedSecondary: {
        backgroundColor: '#1358A0',
        '&:hover': {
          backgroundColor: fade('#1358A0', 0.8),
        },
        '&:focus': {
          backgroundColor: fade('#1358A0', 0.8),
        }
      },
      containedPrimary: {
        backgroundColor: '#4696FC',
        '&:hover': {
          backgroundColor: fade('#4696FC', 0.8),
        },
      },
      outlinedPrimary: {
        borderColor: '#4696FC',
        color: '#4696FC',
        '&:hover': {
          borderColor: fade('#4696FC', 0.8),
        },
      },
      outlinedSecondary: {
        borderColor: '#1358A0',
        color: '#1358A0',
        '&:hover': {
          borderColor: fade('#1358A0', 0.8),
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
        '&:hover $notchedOutline': {
          borderColor: '#4285f4',
        },
        '&.Mui-focused $notchedOutline': {
          border: '1px solid #4285f4',
          boxShadow: '0 0 10px 3px rgba(66, 133, 244, 0.1)',
        },
        '& .MuiSelect-outlined': {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
        '& & .MuiSelect-outlined.MuiOutlinedInput-inputMarginDense"': {
          paddingTop: '5.5px',
          paddingBottom: '5.5px',
        },
      },
      notchedOutline: {
        borderColor: '#d5d5d5',
      },
      input: {
        paddingTop: '10px',
        paddingBottom: '10px',
        '&[readonly]': {
          background: '#F7F8FC',
          cursor: 'default',
        },
        '&[readonly]+$notchedOutline': {
          borderColor: '#4285f4',
        },
      },
      inputMarginDense: {
        paddingTop: '5.5px!important',
        paddingBottom: '5.5px!important',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
        textAlign: 'left',
      },
    },
    MuiListItem: {
      button: {
        borderLeftWidth: 3,
        borderLeftStyle: 'solid',
        borderLeftColor: 'transparent',
        '&:hover, &$selected': {
          backgroundColor: 'rgba(66, 133, 244,0.08)',
          borderLeft: '3px solid rgb(66, 133, 244)',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 16,
      },
    },
    MuiSelect: {
      outlined: {
        padding: '4.5px 12px',
        background: '#F7F8FC',
        border: '1px solid #0F2854',
        borderRadius: 2,

        '& fieldset': {
          border: 0,
        },
      },
    },
    MuiFormGroup: {
      root: {
        '& > legend': {
          backgroundColor: '#ECF3FE',
          color: '#252733',
          fontSize: '14px',
          fontWeight: 700,
          padding: '8px 24px',
          borderRadius: 3,
          width: '100%',
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 560,
      },
    },
    MuiFormHelperText:{
      contained: {
        marginRight: 0,
        marginLeft: 0,
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSnackbar: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
  },
});
